import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  profilePhoto: {
    width: 250,
    aspectRatio: '1/1',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.secondary.main}`,
    '&.upload': {
      width: '100%',
      maxWidth: 250,
      aspectRatio: '1/1',
      margin: theme.spacing(2, 0),
      '&:hover': {},
    },
    [theme.breakpoints.down('xs')]: {
      '&:not(.upload)': {
        width: '100%',
      },
    },
  },
  profileBio: {
    whiteSpace: 'pre-wrap',
  },
  tutorName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: '500',
    paddingTop: theme.spacing(2),
  },
  perHour: {
    color: theme.palette.text.hint,
  },
  deletePhoto: {
    position: 'absolute',
    bottom: 20,
    right: 48,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
    [theme.breakpoints.down('xs')]: {
      bottom: theme.spacing(1),
      right: theme.spacing(1),
    },
  },
}));
