import React, { useState } from 'react';
import PageLayout from 'components/PageLayout';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db } from 'config/firebaseConfig';
import { useConfirmation, useQualifications } from 'providers';
import { Skeleton } from '@material-ui/lab';
import { deleteFile } from 'services/firebase/storage';
import LoadingButton from 'components/LoadingButton';

function Qualifications() {
  const confirmation = useConfirmation();
  const { qualifications, loadingQualifications } = useQualifications();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [selectedQualification, setSelectedQualification] = useState(null);

  const handleSaveQualification = async (e) => {
    e.preventDefault();
    const title = e.target.title.value?.trim();
    const fileUploadRequired = e.target.fileUploadRequired.checked;

    const isEditing = !!selectedQualification;

    if (!title) {
      return setError('You need to provide a title for this qualification!');
    }

    if (
      qualifications
        .filter((qualification) => qualification.id !== selectedQualification?.id)
        .some((qualification) => qualification.title === title)
    ) {
      return setError('This qualification already exists!');
    }

    setError(null);
    setSubmitting(true);
    try {
      if (isEditing) {
        await updateDoc(doc(db, 'qualifications', selectedQualification.id), {
          title,
          fileUploadRequired,
        });
      } else {
        await addDoc(collection(db, 'qualifications'), { title, fileUploadRequired });
      }
    } catch (error) {
      setError(error.message);
    }
    setSubmitting(false);
    setOpen(false);
  };

  const handleDelete = async (qualification) => {
    const confirmed = await confirmation(
      <>
        <strong>{qualification.title}</strong> will be deleted!
      </>
    );

    if (confirmed) {
      try {
        await deleteDoc(doc(db, 'qualifications', qualification.id));
        (
          await getDocs(
            query(collection(db, 'filesToApprove'), where('tag', '==', qualification.id))
          )
        ).forEach((doc) => {
          if (doc.exists()) {
            deleteFile(doc.data()?.downloadUrl);
            deleteDoc(doc.ref);
          }
        });
      } catch (error) {}
    }
  };

  const handleCancel = () => {
    setOpen(false);

    if (error) {
      setError(null);
    }

    if (selectedQualification) {
      setSelectedQualification(null);
    }
  };

  return (
    <PageLayout>
      <Box textAlign='center' pb={10}>
        <Typography variant='h2'>Qualifications</Typography>
      </Box>

      <Box textAlign='right' pb={4}>
        <Button
          color='primary'
          variant='contained'
          startIcon={<Icon>add_icon</Icon>}
          onClick={() => setOpen(true)}
        >
          Add Qualification
        </Button>
      </Box>

      {loadingQualifications && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton style={{ borderRadius: 4 }} variant='rect' width='100%' height={50} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton style={{ borderRadius: 4 }} variant='rect' width='100%' height={50} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton style={{ borderRadius: 4 }} variant='rect' width='100%' height={50} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton style={{ borderRadius: 4 }} variant='rect' width='100%' height={50} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton style={{ borderRadius: 4 }} variant='rect' width='100%' height={50} />
          </Grid>
        </Grid>
      )}

      {qualifications.map((qualification) => (
        <Box key={qualification.id} py={2}>
          <Grid container alignItems='center'>
            <Grid item xs>
              <Typography variant='h6'>
                {qualification.title}{' '}
                {qualification.fileUploadRequired ? (
                  <>
                    <Tooltip title='This qualification requires a file to be uploaded.'>
                      <Icon color='error' style={{ verticalAlign: 'text-bottom' }}>
                        upload_file_icon
                      </Icon>
                    </Tooltip>
                  </>
                ) : (
                  ''
                )}
              </Typography>
            </Grid>

            <Grid item>
              <Tooltip title={`Edit ${qualification.title}`}>
                <IconButton
                  onClick={() => {
                    setSelectedQualification(qualification);
                    setOpen(true);
                  }}
                >
                  <Icon>edit_icon</Icon>
                </IconButton>
              </Tooltip>
              <Tooltip title={`Delete ${qualification.title}`}>
                <IconButton onClick={() => handleDelete(qualification)}>
                  <Icon>delete_icon</Icon>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Divider />
        </Box>
      ))}

      {!loadingQualifications && !qualifications.length && (
        <Box textAlign='center' py={10}>
          <Typography color='textSecondary'>No Results</Typography>
          <Divider />
        </Box>
      )}
      {open && (
        <Dialog open={open} fullWidth maxWidth='sm'>
          <DialogTitle style={{ textAlign: 'center' }}>
            {selectedQualification
              ? `Edit ${selectedQualification.title}`
              : 'Add new qualification'}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Box py={4}>
              <form onSubmit={handleSaveQualification} id='edit-qualification'>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      required
                      name='title'
                      variant='outlined'
                      fullWidth
                      label='Title'
                      defaultValue={selectedQualification?.title || ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      label='File Upload Required'
                      control={
                        <Checkbox
                          name='fileUploadRequired'
                          defaultChecked={selectedQualification?.fileUploadRequired || false}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </form>
            </Box>
          </DialogContent>
          <Divider />
          {error && (
            <Box py={2} textAlign='center'>
              <Typography color='error'>{error}</Typography>
            </Box>
          )}
          <DialogActions>
            <Button variant='outlined' color='primary' onClick={handleCancel}>
              Cancel
            </Button>
            <LoadingButton loading={submitting} type='submit' form='edit-qualification'>
              Save
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </PageLayout>
  );
}

export default Qualifications;
