import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyAeOi4Y4aXjHLV-5vAuJjH3laPhRddenHQ',
  authDomain: 'booksmart-tutors.firebaseapp.com',
  projectId: 'booksmart-tutors',
  storageBucket: 'booksmart-tutors.appspot.com',
  messagingSenderId: '959785549675',
  appId: '1:959785549675:web:4cf83c535c9b66d13bd62e',
  measurementId: 'G-8BCJ0KGS3X',
};

initializeApp(firebaseConfig);

const storage = getStorage();
const db = getFirestore();

export { storage, db };
