import React, { useMemo } from 'react';
import { Dialog } from '@material-ui/core';
import { requestTypes } from 'enums';
import CheckoutForm from 'components/CheckoutForm';
import RequestConsultation from 'components/RequestConsultation';

export default function BookingDialog({
  requestType,
  onClose,
  tutor,
  bookings,
  selectedTimeSlot,
  hoursTaught,
}) {
  const dialogContent = useMemo(
    () => ({
      [requestTypes.lesson]: (
        <CheckoutForm
          tutor={tutor}
          onClose={onClose}
          bookings={bookings}
          selectedTimeSlot={selectedTimeSlot}
          hoursTaught={hoursTaught}
        />
      ),
      [requestTypes.consultation]: <RequestConsultation onClose={onClose} tutor={tutor} />,
    }),
    [bookings, hoursTaught, onClose, selectedTimeSlot, tutor]
  );

  return (
    <Dialog fullWidth maxWidth='md' open={Boolean(requestType)}>
      {dialogContent[requestType]}
    </Dialog>
  );
}
