import React, { useCallback, useMemo } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';

function SubjectWithLevels({ subject, selectedLevels, onChange }) {
  const handleSelectSubject = useCallback(
    (e, checked) =>
      onChange((prevState) =>
        checked
          ? [...prevState.filter((s) => s.id !== subject.id), subject]
          : prevState.filter((s) => s.id !== subject.id)
      ),
    [onChange, subject]
  );

  const isSubjectSelected = useMemo(
    () => Boolean(selectedLevels && subject.levels.every((l) => selectedLevels.includes(l))),
    [selectedLevels, subject.levels]
  );

  const isSubjectIndeterminate = useMemo(
    () => !isSubjectSelected && Boolean(subject.levels.some((l) => selectedLevels?.includes(l))),
    [isSubjectSelected, selectedLevels, subject.levels]
  );

  const handleSelectLevel = useCallback(
    (e, checked) => {
      const level = e.target.name;
      if (checked) {
        onChange((prevState) =>
          selectedLevels?.length
            ? [
                ...prevState.filter((s) => s.id !== subject.id),
                { ...subject, levels: [...selectedLevels, level] },
              ]
            : [...prevState, { ...subject, levels: [level] }]
        );
      } else {
        if (selectedLevels?.length === 1 && selectedLevels?.includes(level)) {
          return onChange((prevState) => prevState.filter((s) => s.id !== subject.id));
        }

        onChange((prevState) => [
          ...prevState.filter((s) => s.id !== subject.id),
          { ...subject, levels: selectedLevels?.filter((l) => l !== level) },
        ]);
      }
    },
    [onChange, selectedLevels, subject]
  );

  return (
    <Grid item xs={12}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isSubjectSelected}
            indeterminate={isSubjectIndeterminate}
            name={subject.name}
            onChange={handleSelectSubject}
          />
        }
        label={<Typography variant='h6'>{subject.name}</Typography>}
      />
      {subject.levels.map((l) => (
        <Box width='100%' pl={4} key={l}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isSubjectSelected || Boolean(selectedLevels?.includes(l))}
                onChange={handleSelectLevel}
                name={l}
              />
            }
            label={l}
          />
        </Box>
      ))}
    </Grid>
  );
}

export default React.memo(SubjectWithLevels);
