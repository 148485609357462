import { prettifyDateTime } from 'utils/date';
import { Grid, Icon, IconButton, Tooltip } from '@material-ui/core';
import { theme } from 'themes';
import CustomLink from 'components/CustomLink';
import LoadingButton from 'components/LoadingButton';

const statusColor = {
  new: theme.palette.warning.main,
  awaiting_approval: theme.palette.text.primary,
  approved: theme.palette.success.main,
  temporary_disabled: theme.palette.error.main,
};

export const getTutorColumns = (loadingRowId, handleDeleteUser, handleUpdateTutorStatus) => [
  {
    field: 'id',
    headerName: 'UID',
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: true,
    minWidth: 200,
  },
  {
    field: 'email',
    headerName: 'Email',
    renderCell: ({ value }) => <CustomLink href={`mailto:${value}`}>{value}</CustomLink>,
    flex: true,
    minWidth: 300,
  },
  {
    field: 'phone_number',
    headerName: 'Phone',
    valueGetter: ({ row }) => row.meta.phone_number,
    flex: true,
    minWidth: 150,
    sortable: false,
  },
  {
    field: 'profileStatus',
    headerName: 'Profile Status',
    valueGetter: ({ row }) => row.meta?.profile_status?.[0] || 'new',
    renderCell: ({ value }) => (
      <span
        style={{
          textTransform: 'capitalize',
          fontStyle: 'italic',
          color: statusColor[value],
        }}
      >
        {value?.split('_').join(' ')}
      </span>
    ),
    flex: true,
    minWidth: 200,
  },
  {
    field: 'registered_date',
    headerName: 'Registered Date',
    type: 'dateTime',
    valueGetter: ({ value }) => new Date(value),
    renderCell: ({ value }) => prettifyDateTime(new Date(value)),
    minWidth: 200,
  },
  {
    field: 'account_number',
    headerName: 'Account Number',
    valueGetter: ({ row }) => row.meta.account_number,
    minWidth: 200,
  },
  {
    field: 'sort_code',
    headerName: 'Sort Code',
    valueGetter: ({ row }) => row.meta.sort_code,
    minWidth: 150,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    flex: true,
    minWidth: 350,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <Grid container wrap='nowrap' spacing={2}>
        {['awaiting_approval', 'temporary_disabled'].includes(row.meta.profile_status?.[0]) && (
          <Grid item>
            <LoadingButton
              loading={loadingRowId === row.id}
              startIcon={<Icon>check_icon</Icon>}
              onClick={(e) => {
                e.stopPropagation();
                handleUpdateTutorStatus(row, 'approved');
              }}
            >
              Approve
            </LoadingButton>
          </Grid>
        )}
        {row.meta.profile_status?.[0] === 'awaiting_approval' && (
          <Grid item>
            <LoadingButton
              loading={loadingRowId === row.id}
              variant='outlined'
              startIcon={<Icon color='error'>clear_icon</Icon>}
              onClick={(e) => {
                e.stopPropagation();
                handleUpdateTutorStatus(row, 'temporary_disabled');
              }}
            >
              Reject
            </LoadingButton>
          </Grid>
        )}

        {['approved'].includes(row.meta.profile_status?.[0]) && (
          <Grid item>
            <LoadingButton
              loading={loadingRowId === row.id}
              variant='outlined'
              startIcon={<Icon color='error'>clear_icon</Icon>}
              onClick={(e) => {
                e.stopPropagation();
                handleUpdateTutorStatus(row, 'temporary_disabled');
              }}
            >
              Unapprove
            </LoadingButton>
          </Grid>
        )}

        <Grid item>
          <Tooltip title={`Delete ${row.name}`}>
            <IconButton
              disabled={row.id === loadingRowId}
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteUser(row);
              }}
            >
              <Icon>delete_icon</Icon>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    ),
  },
];
