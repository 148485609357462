export const routes = {
  home: '/',
  login: '/login',
  forgotPassword: '/forgot-password',
  registerAsStudent: '/register-as-student',
  profile: '/profile',
  tutors: '/tutors',
  bookings: '/bookings',
  bookingsHistory: '/bookings-history',
  messages: '/messages',
  subjects: '/subjects',
  handbook: '/handbook',
  pendingReviews: '/pending-reviews',
  requestTutorMentor: '/request-tutor-mentor',
  requests: '/requests',
  account: '/account',
  references: '/references',
  chats: '/chats',
  users: '/users',
  qualifications: '/qualifications',
  tutorCertificates: '/tutor-certificates',
  consultations: '/consultations',
  consultationHistory: '/consultation-history',
  myGroupClasses: '/my-group-classes',
};
