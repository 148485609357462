import { Divider, Grid, Icon, Typography } from '@material-ui/core';
import { POUND_SYMBOL } from 'enums';
import React from 'react';

function BookingDetails({
  subject,
  level,
  type,
  date,
  startTime,
  duration,
  total,
  discount,
  pricePerPerson,
}) {
  return (
    <>
      <Typography gutterBottom variant='h6'>
        Booking Details
      </Typography>
      <Grid container direction='column'>
        <Grid item container justifyContent='space-between'>
          <Typography>Subject</Typography>
          <Typography>{subject}</Typography>
          <Divider style={{ width: '100%', marginBottom: 16 }} />
        </Grid>
        <Grid item container justifyContent='space-between'>
          <Typography>Level</Typography>
          <Typography>{level}</Typography>
          <Divider style={{ width: '100%', marginBottom: 16 }} />
        </Grid>
        <Grid item container justifyContent='space-between'>
          <Typography>Type</Typography>
          <Typography>{type}</Typography>
          <Divider style={{ width: '100%', marginBottom: 16 }} />
        </Grid>
        <Grid item container justifyContent='space-between'>
          <Typography>Date</Typography>
          <Typography>{date}</Typography>
          <Divider style={{ width: '100%', marginBottom: 16 }} />
        </Grid>
        <Grid item container justifyContent='space-between'>
          <Typography>Start time</Typography>
          <Typography>{startTime}</Typography>
          <Divider style={{ width: '100%', marginBottom: 16 }} />
        </Grid>
        <Grid item container justifyContent='space-between'>
          <Typography>Duration</Typography>
          <Typography>{duration}</Typography>
          <Divider style={{ width: '100%', marginBottom: 16 }} />
        </Grid>
        {discount && (
          <Grid item container justifyContent='space-between'>
            <Typography>
              Discount per person
              <Icon color='action' style={{ verticalAlign: 'middle', marginLeft: 10 }}>
                groups
              </Icon>
            </Typography>
            <Typography>{discount}</Typography>
            <Divider style={{ width: '100%', marginBottom: 16 }} />
          </Grid>
        )}
        {discount && (
          <Grid item container justifyContent='space-between'>
            <Typography>
              Price per person
              <Icon color='action' style={{ verticalAlign: 'middle', marginLeft: 10 }}>
                groups
              </Icon>
            </Typography>
            <Typography>{pricePerPerson}</Typography>
            <Divider style={{ width: '100%', marginBottom: 16 }} />
          </Grid>
        )}
        <Grid item container justifyContent='space-between'>
          <Typography>
            <strong>Total cost</strong>
          </Typography>
          <Typography>
            <strong>
              {POUND_SYMBOL}
              {total}
            </strong>
          </Typography>
          <Divider style={{ width: '100%' }} />
        </Grid>
      </Grid>
    </>
  );
}

export default BookingDetails;
