import { LAST_SEARCH } from 'enums';

const { createContext, useContext, useState, useMemo, useEffect } = require('react');

const FiltersContext = createContext();
export const useFilters = () => useContext(FiltersContext);

export const FiltersProvider = ({ children }) => {
  const defaultFilters = useMemo(() => {
    const lastSearch = sessionStorage.getItem(LAST_SEARCH);
    if (!lastSearch) {
      return;
    }

    try {
      return JSON.parse(lastSearch);
    } catch (error) {}
  }, []);

  const [filters, setFilters] = useState(
    defaultFilters || {
      searchFor: 'tutor',
      subject: null,
      level: null,
      address: null,
      travelDistance: 1,
    }
  );

  useEffect(() => {
    try {
      const oldFilters = sessionStorage.getItem(LAST_SEARCH);
      const newFilters = filters ? JSON.stringify(filters) : null;

      if (oldFilters !== newFilters) {
        return newFilters
          ? sessionStorage.setItem(LAST_SEARCH, newFilters)
          : sessionStorage.removeItem(LAST_SEARCH);
      }
    } catch (error) {}
  }, [filters]);

  return (
    <FiltersContext.Provider value={{ filters, setFilters }}>{children}</FiltersContext.Provider>
  );
};
