import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  earningsTable: {
    width: '100%',
    borderCollapse: 'collapse',
    border: `1px solid ${theme.palette.divider}`,
    '& th': {
      padding: theme.spacing(1),
      border: `1px solid ${theme.palette.divider}`,
      whiteSpace: 'nowrap',
    },
    '& td': {
      textAlign: 'center',
      border: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1),
    },
  },
}));
