import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  studentSelect: {
    '& .MuiSelect-root': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));
