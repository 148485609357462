import React, { useEffect } from 'react';
import { Box, Container, Divider, Grid, Icon, Typography } from '@material-ui/core';
import { useStyles } from './style';
import { APP_TITLE } from 'enums';
import CustomLink from 'components/CustomLink';

function Handbook() {
  const classes = useStyles();

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'smooth';

    return () => {
      document.querySelector('html').style.scrollBehavior = 'unset';
    };
  }, []);

  return (
    <Box pb={10} pt={18}>
      <Container>
        <Box textAlign='center' pb={10}>
          <Typography variant='h2'>The {APP_TITLE} Handbook</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <ul className={classes.chapterList}>
              <li>
                <CustomLink color='secondary' variant='body1' href='#tuition-process'>
                  The Tuition Process
                </CustomLink>
              </li>
              <li>
                <CustomLink color='secondary' variant='body1' href='#planning-for-progress'>
                  Planning for Progress
                </CustomLink>
              </li>
              <li>
                <CustomLink color='secondary' variant='body1' href='#engaging-students'>
                  Engaging Students – starters and plenaries
                </CustomLink>
              </li>
              <li>
                <CustomLink color='secondary' variant='body1' href='#special-education-needs'>
                  Special Educational Needs
                </CustomLink>
              </li>
              <li>
                <CustomLink color='secondary' variant='body1' href='#helpful-resources'>
                  Helpful Resources
                </CustomLink>
              </li>
              <li>
                <CustomLink color='secondary' variant='body1' href='#insurance'>
                  Insurance
                </CustomLink>
              </li>
              <li>
                <CustomLink color='secondary' variant='body1' href='#DBS-checks'>
                  DBS Checks
                </CustomLink>
              </li>
              <li>
                <CustomLink color='secondary' variant='body1' href='#HMRC'>
                  Registering with HMRC
                </CustomLink>
              </li>
            </ul>
          </Grid>
          <Grid item xs>
            <Box>
              <Typography variant='h4' id='tuition-process'>
                The Tuition Process
                <Divider />
              </Typography>
              <br />
              <Typography variant='h6' gutterBottom>
                Consultation
              </Typography>
              <Typography>
                Free 15 minute consultations should take place prior to bookings in order for
                Tutors/Mentors and Students to enquire about the service and ensure you are an
                adequate match. This is beneficial for everyone involved as it ensures you can be
                fully prepared to work with the student and they can ask you questions about what
                they should expect from you.
                <br />
                <br />
                We advise you find out what exam board your student will be sitting an exam in, in
                addition to asking what their current working grades and target grades are.
                <br />
                <br />
                This is also an opportunity for you to sell yourself and explain how you can help
                them to achieve the results they want from your service. Please do be honest with
                students though, and if you’re not confident you can help them with their
                requirements please do say. We will endeavour to find you students who you will be
                able to help, it’s better for everyone involved that you don’t commit to the job if
                you feel that you can’t help a student.
              </Typography>
              <br />
              <Typography variant='h6' gutterBottom>
                Target setting
              </Typography>
              <Typography>
                When discussing a student’s needs we recommend setting SMART targets with them prior
                to the first lesson. SMART stands for:
                <br />
                <br />
                Specific - target a specific area for improvement.
                <br />
                <br />
                Measurable - quantify or at least suggest an indicator of progress.
                <br />
                <br />
                Achievable - specify how they will achieve this.
                <br />
                <br />
                Realistic - state what results can realistically be achieved.
                <br />
                <br />
                Timely - provide a time frame for when they should expect to see progress.
              </Typography>
              <br />
              <Typography variant='h6' gutterBottom>
                Initial assessment
              </Typography>
              <Typography>
                We recommend creating and providing an initial assessment for your student to
                complete prior to the first session with them. This will enable you to see where
                their strengths and areas for development are.
                <br />
                <br />
                We have provided some basic knowledge assessments on our website for core subjects
                up to Key Stage 4, however we recommend you find more specific assessments online
                according to the exam board your student is using.
              </Typography>
              <br />
              <Typography variant='h6' gutterBottom>
                Process
              </Typography>
              <Typography>
                We recommend at least 10 one-hour sessions before reassessing for progress. The
                initial assessment should give you a good idea about your student’s current working
                level and we recommend at least 10 more focused sessions before reassessing to see
                progress.
                <br />
                <br />
                It’s important to identify your student’s areas for development and share these with
                them and their parents/guardians.
              </Typography>
              <br />
              <Typography variant='h6' gutterBottom>
                Feedback
              </Typography>
              <Typography>
                We recommend providing parents with both verbal and written feedback on their
                child’s progress and advise them on ways to further support their child's learning.
                We have included an exemplar feedback sheet in this handbook on page 4 to assist you
                with this.
              </Typography>
              <br />
              <br />
              <br />
              <br />
              <Typography variant='h4' id='planning-for-progress'>
                Planning for Progress
                <Divider />
              </Typography>
              <br />
              <Typography>
                <strong>How should you close the knowledge-skill gap?</strong>
              </Typography>
              <br />
              <Typography>
                <strong>Students should master ‘parts’ before attempting to ‘whole.</strong>
              </Typography>
              <br />
              <Typography>
                Practice makes permanent. Some practice is good, some –like memorising model
                answers, is not useful. Memorisation is often criticised for not leading to
                understanding. A student can memorise a word but not recall it in reality. Another
                example is that all students know sentences should begin with a capital letter yet
                many often don’t do this in reality.
                <br />
                <br />
                The best way to fix this is through direct instruction and deliberate practice.
                Students should be given tasks that represent a ‘part’ of the ‘whole’ and should
                practice it until they get mastery. For example, this may mean heavily structuring
                and scaffolding tasks at the start, which will lead to greater independence at the
                end. Students need to practice doing specific skills ‘parts’ which will eventually
                contribute towards the ‘whole.’ For example, they should master a paragraph in an
                essay before writing the whole essay itself.
              </Typography>
              <br />
              <Typography variant='h6' gutterBottom>
                What is good formative assessment?
              </Typography>
              <Typography>
                <strong>
                  Formative assessments shouldn’t necessarily look like the final assessment.
                </strong>
              </Typography>
              <br />
              <Typography>
                Formative assessments, also known as assessment for learning, is a method of
                assessing students whilst learning is happening in the lesson, rather than at the
                end of a topic or sequence of lessons. The latter is referred to as a summative
                assessment and includes end of topic tests and exams as a whole.
                <br />
                <br />
                What matters is how information from an assessment is used, it should feed into your
                planning of the next lesson. If the information isn’t used, then the assessment
                isn’t valuable. A lot of formative assessments are simply mini-summative
                assessments. Good formative assessments do not have to look like the final
                assessment. For example, if students should complete a 16 mark essay question in the
                end, a formative assessment could involve multiple-choice questions on key
                knowledge, gap-fill paragraphs and misconception questions that embed key skills and
                knowledge before the final assessment.
              </Typography>
              <br />
              <br />
              <Typography>
                <strong>How should we feedback lesson progress to students?</strong>
              </Typography>
              <br />
              <Typography>
                <strong>
                  Feedback should be specific and tailored to the student and subject.
                </strong>
              </Typography>
              <br />
              <Typography>
                Feedback is a really effective way for students to make progress. However, despite
                the UK giving more feedback than almost any other country, progress hasn’t changed
                much at all. A main reason for this is relying too much on grading which creates a
                false sense of progress. Grade descriptors often contained generic statements like
                ‘good level of analysis’that have very different meanings depending on their
                context.
                <br />
                <br />
                Furthermore, skillslike analysis can’t be taught in abstract. For example, if a
                student can analyse a historical source, it doesn’t mean they can analyse a piece of
                art at the same level. This is because analysis is based on the knowledge you have.
                A History teacher many be excellent at analysing historical sources, but that
                doesn’t mean they can analyse scientific experiments.
              </Typography>
              <br />
              <Typography variant='h6' gutterBottom>
                How should we feedback progress to parents?
              </Typography>
              <Typography>
                Parents like to see progress over time. It’s good practice to provide parents with a
                progress report that address the targets set in your initial consultation and how
                the student has developed over time. The following is an example of how you could
                structure this:
              </Typography>
              <br />
              <CustomLink
                className={classes.progressReport}
                href='https://firebasestorage.googleapis.com/v0/b/booksmart-tutors.appspot.com/o/uploads%2FBooksmart%20Tutors%20Tutor%20Handbook.docx?alt=media&token=02df64e3-1638-4d00-ad91-c8ee94bcfd7a'
              >
                Progress Report
                <Icon>file_download</Icon>
              </CustomLink>
              <br />
              <br />
              <br />
              <br />
              <Typography variant='h4' id='engaging-students'>
                Engaging Students – starters and plenaries
                <Divider />
              </Typography>
              <br />
              <Typography>
                These are not 'compulsory' but can add greatly to a lesson's effectiveness.
                <br />
                <br />
                Starters provide thought-provoking and engaging beginnings to lessons, and plenaries
                provide opportunities for students to review and clarify their learning.
              </Typography>
              <br />
              <Typography variant='h6'>Examples of starter tasks:</Typography>
              <br />
              <Typography variant='h6' gutterBottom>
                Odd one out
              </Typography>
              <Typography>
                Students are given a sheet with sets of terms and/or vocabulary related to the
                topic. They identify which is the odd one out and justify their decisions.
              </Typography>
              <br />
              <Typography variant='h6' gutterBottom>
                List, group, label
              </Typography>
              <Typography>
                Students list all terms relevant to a topic, or mind mapping them. With the help of
                the tutor, students can sort and group them, devising their own title for each
                group. In person, providing post-it notes helps students to physically move the
                terms and helps maintain clarity, and using the online classroom this is possible to
                achieve simply by moving the textboxes around.
              </Typography>
              <br />
              <Typography variant='h6' gutterBottom>
                Sequencing
              </Typography>
              <Typography>
                Processes are cut up into steps (e.g. the steps required to produce a graph from a
                spreadsheet in ICT, or diagrams of the stages of a science experiment). Students are
                given the steps to order into the corre ct sequence. Tutors can then share the
                original, complete process and use a variety of questions to challenge and stretch
                students thinking further.
              </Typography>
              <br />
              <Typography variant='h6' gutterBottom>
                Question sort
              </Typography>
              <Typography>
                Sets of increasingly difficult questions on a topic are provided and students
                individually sort these cards into ‘can do’, ‘can’t do’ and ‘not sure’ piles.
              </Typography>
              <br />
              <Typography variant='h6' gutterBottom>
                Follow on
              </Typography>
              <Typography>
                A student gives a statement or sentence. Another student has to come up with a
                statement or sentence related to the same topic which begins with the last letter of
                the previous offering which develops the argument/discussion.
              </Typography>
              <br />
              <Typography variant='h6'>Examples of plenary tasks:</Typography>
              <br />
              <Typography variant='h6' gutterBottom>
                Gimme 5
              </Typography>
              <Typography>
                Students agree on five things they have learned during the lesson or they generate
                five questions they now want answering. Or the tutor could devise five questions
                which would test their understanding of what they have learnt.
              </Typography>
              <br />
              <Typography variant='h6' gutterBottom>
                Lucky dip
              </Typography>
              <Typography>
                At an appropriate point in the lesson students have to write a question based on the
                topic studied. Questions are put in a box (either virtually in a text box if
                conducting an online lesson, or physically into a tissue/shoe box if conducting a
                lesson in person). At the end of the lesson the student picks the questions from the
                box one by one to see if they can answer it.
              </Typography>
              <br />
              <Typography variant='h6' gutterBottom>
                Hot seating
              </Typography>
              <Typography>
                Put a student in the ‘hot seat’ as an expert or character and ask them questions.
              </Typography>
              <br />
              <Typography variant='h6' gutterBottom>
                Bingo
              </Typography>
              <Typography>
                Get students to draw out a noughts and crosses grid and give them a list of 12 key
                words – they will need to select 9 of these to fill in their grids. The tutor can
                then read out the definition of the word (but not the word itself) and students
                cross of the word if they have written it down. This will asse ss a student’s
                understanding of the words.
              </Typography>
              <br />
              <Typography variant='h6' gutterBottom>
                Call my bluff
              </Typography>
              <Typography>
                Tutors write a definition of a key word and challenge the student to say if they are
                right or wrong.
              </Typography>
              <br />
              <Typography variant='h6' gutterBottom>
                Verbal tennis
              </Typography>
              <Typography>
                Choose a word or concept and the student has to explain it and then choose another
                word to link to it, whereby then the tutor provides an explanation for it and gives
                the student another key term back.
              </Typography>
              <br />
              <Typography variant='h6' gutterBottom>
                Tree of knowledge
              </Typography>
              <Typography>
                Draw a sketch of a bare tree and students can write around the tree as briefly as
                possible what has been the most important thing they have learnt in the lesson.
              </Typography>
              <br />
              <br />
              <br />
              <br />
              <Typography variant='h4' id='special-education-needs'>
                Special Educational Needs
                <Divider />
              </Typography>
              <br />
              <Typography variant='h6'>
                Strategies to use with students who have Autistic Spectrum Disorder (ASD)
              </Typography>

              <Box pl={6}>
                <Typography component='div'>
                  <ol style={{ lineHeight: 2.5 }}>
                    <li>Start each lesson by outlining what will be covered.</li>
                    <li>
                      Display basic rules and routines, illustrated by pictures, if needed for
                      student to refer to
                    </li>
                    <li>Explain any changes of routine to the student in advance.</li>
                    <li>
                      Use a traffic-light system or something similar to indicate whether students
                      can talk or not.
                    </li>
                    <li>
                      Use visual aids such as pictures, diagrams, charts etc. to support what is
                      being taught. This can be a sequenced series of pictures, or written list.
                    </li>
                    <li>Set small tasks with clear objectives.</li>
                    <li>
                      Set very clear expectations for the tasks set, for example, how many lines to
                      write, how many questions to answer etc.
                    </li>
                    <li>
                      Allow time for breaks in the lesson, these can be used when needed or with
                      structure with allocates times.
                    </li>
                    <li>
                      Work with clear routines, for example starter activity, main task and then
                      plenary.
                    </li>
                    <li>
                      Use short simple instructions. Give one at a time and check for understanding.
                    </li>
                    <li>
                      Write instructions down as a list for student to tick off when completed. This
                      will give the student a sense of accomplishment and will break down the
                      lessons into chunks.
                    </li>
                    <li>
                      Avoid use of sarcasm, metaphorical language or idioms such as ‘break a leg’.
                    </li>
                    <li>Ask clear, direct questions when checking for understanding.</li>
                    <li>Don’t ask the student to talk or write about imagined experiences.</li>
                    <li>
                      Always tell the student what to do rather than what not to do. Focus on
                      positives rather than negatives.
                    </li>
                    <li>
                      Create a culture in lessons where you show that it is OK to make mistakes and
                      it is important to learn from the mistakes.
                    </li>
                    <li>
                      If the student or students are distracted and go off topic, direct
                      conversation back to the topic in hand.
                    </li>
                  </ol>
                </Typography>
              </Box>
              <br />
              <Typography variant='h6'>
                Strategies to use with students who have Social, Emotional and Mental Health
                Difficulties
              </Typography>
              <Box pl={6}>
                <Typography component='div'>
                  <ol style={{ lineHeight: 2.5 }}>
                    <li>Start each lesson by outlining what will be covered.</li>
                    <li>Explain any changes of routine to the student in advance.</li>
                    <li>
                      Set very clear expectations for the lesson at the beginning of each session.
                    </li>
                    <li>
                      Set small tasks with clear objectives. Use short simple instructions. Give one
                      at a time and check for understanding.
                    </li>
                    <li>
                      Write instructions down as a list for you or the student to tick off when
                      completed. This will give the student a sense of accomplishment and will break
                      down the lessons into chunks.
                    </li>
                    <li>
                      Expect to teach student specific behavioural skills such as how to ask for
                      help, take turns when talking etc.
                    </li>
                    <li>
                      If student finds it difficult and becomes wound up/anxious, allow them some
                      time to calm down before continuing with the lesson.
                    </li>
                    <li>
                      Always tell the student what to do rather than what not to do. Focus on
                      positives rather than negatives.
                    </li>
                    <li>Challenge and label the behaviour, rather than the student.</li>
                    <li>
                      Remind the students of rules and expectations rather than telling them off:
                      Our rules are that we take turns to speak rather than all talking at the same
                      time.
                    </li>
                    <li>Use praise to encourage and motivate students and celebrate successes.</li>
                    <li>Use a planned reward system for good behaviour or work.</li>
                  </ol>
                </Typography>
              </Box>

              <br />
              <Typography variant='h6'>
                Strategies to use with students who have Dyslexia
              </Typography>
              <Box pl={6}>
                <Typography component='div'>
                  <ol style={{ lineHeight: 2.5 }}>
                    <li>
                      When using worksheets, use cream paper, large and a clear font such as Arial.
                      Highlight sections with key words, symbols and pictures. Put important
                      information in bold or colour. Make sure that the layout is uncluttered.
                    </li>
                    <li>
                      Avoid asking student to copy and have ‘handouts’ready prior to the lesson.
                    </li>
                    <li>Set small tasks with clear objectives.</li>
                    <li>
                      Write instructions down as a list for student to tick off when completed. This
                      will give the student a sense of accomplishment and will break down the
                      lessons into chunks.
                    </li>
                    <li>
                      Be aware that dyslexic students may know something one day and forget it the
                      next. Please be patient with the student if this happens.
                    </li>
                    <li>
                      Avoid criticism when this happens; instead, talk to them about overcoming this
                      and they can use this to help them remember things
                    </li>
                    <li>
                      Do not expect students to easily remember sequences such as days of the week,
                      months of the year, the alphabet, times tables, number facts. Provide aids to
                      support with this.
                    </li>
                    <li>
                      Teach student strategies to improve organisation, such as diaries, checklists
                      of tasks they have to complete.
                    </li>
                    <li>
                      When marking, focus on content, rather than the presentation of work. Praise
                      for correct spellings and maybe target some that are incorrect. The focus
                      should be on what they have done right rather than the mistake they made.
                    </li>
                    <li>Allow extra time to complete tasks.</li>
                    <li>
                      Write down homework for students, or give it as handouts for them to complete.
                    </li>
                  </ol>
                </Typography>
              </Box>

              <br />
              <Typography variant='h6'>
                Strategies to use with students who have Dyspraxia
              </Typography>
              <Box pl={6}>
                <Typography component='div'>
                  <ol style={{ lineHeight: 2.5 }}>
                    <li>
                      When using worksheets, use cream paper, large and a clear font such as Arial.
                      Highlight sections with key words, symbols and pictures. Put important
                      information in bold or colour. Make sure that the layout is uncluttered.
                    </li>
                    <li>
                      Minimise need for extensive handwritten recording –use ICT, bullet points,
                      mind mapping, flow charts, photocopied transcripts of notes.
                    </li>
                    <li>
                      Allow student to choose writing tools that are most comfortable for them –
                      pencil, pen, felt pen.
                    </li>
                    <li>Use larger-lined book or paper.</li>
                    <li>
                      Help student follow text on screen/paper by writing/highlighting alternate
                      lines in different colours.
                    </li>
                    <li>
                      Teach student strategies to improve organisation, such as diaries, checklists
                      of tasks they have to complete.
                    </li>
                    <li>
                      If the student needs to work through a series of questions, help them keep
                      their place by highlighting where they are on the task or by creating a
                      checklist.
                    </li>
                    <li>
                      Choose resources that don’t require manipulation for example, use a number
                      line rather than counters in maths
                    </li>
                    <li>
                      Allow time for breaks in the lesson, these can be used when needed or with
                      structure with allocates times.
                    </li>
                    <li>
                      Write down homework for students, or give it as handouts for them to complete.
                    </li>
                  </ol>
                </Typography>
              </Box>

              <br />
              <Typography variant='h6'>
                Strategies to use with students who have Learning Difficulties
              </Typography>
              <Box pl={6}>
                <Typography component='div'>
                  <ol style={{ lineHeight: 2.5 }}>
                    <li>
                      Begin each lesson linking new learning to what student already knows or
                      recapping what you did in the last lesson.
                    </li>
                    <li>Tell students the three key points of the lesson</li>
                    <li>
                      Display basic rules and routines, illustrated by pictures, if needed for
                      student to refer to.
                    </li>
                    <li>Explain any changes of routine to the student in advance.</li>
                    <li>
                      Use a traffic-light system or something similar to indicate whether students
                      can talk or not.
                    </li>
                    <li>
                      Write instructions down as a list for you or the student to tick off when
                      completed. This will give the student a sense of accomplishment and will break
                      down the lessons and content into chunks.
                    </li>
                    <li>
                      Create a list of key vocabulary each topic or lesson and teach the meaning of
                      each word.
                    </li>
                    <li>
                      Provide multiple examples of new concepts, and take these examples from
                      student’s own reallife experience.
                    </li>
                    <li>
                      Use visual aids such as pictures or diagrams or use practical equipment.
                    </li>
                    <li>
                      Create a culture in lessons where you show that it is OK to make mistakes and
                      it is important to learn from the mistakes.
                    </li>
                    <li>
                      . If the student or students are distracted and go off topic, direct
                      conversation back to the topic in hand.
                    </li>
                    <li>
                      Write down homework for students or provide simple handouts for students to
                      complete.
                    </li>
                  </ol>
                </Typography>
              </Box>

              <br />
              <Typography variant='h6'>
                Strategies to use with students who have Speech, Language and Communication needs
              </Typography>
              <Box pl={6}>
                <Typography component='div'>
                  <ol style={{ lineHeight: 2.5 }}>
                    <li>
                      Begin each lesson linking new learning or new topics to what student already
                      knows or recapping what you did in the last lesson. Also use personal
                      experiences.
                    </li>
                    <li>
                      Create a list of key vocabulary each topic or lesson and teach the meaning of
                      each word.
                    </li>
                    <li>
                      Teach each new terminology by going over what it sounds like, the definition
                      and how it fits in sentences.
                    </li>
                    <li>
                      Provide multiple examples of new concepts, and take these examples from
                      student’s own reallife experience.
                    </li>
                    <li>
                      Use short simple instructions. Give one at a time and check for understanding.
                    </li>
                    <li>Give instructions before you start an activity, not during it</li>
                    <li>
                      Verbal instructions should be simple and make clear, the order in which the
                      task should be carried out.
                    </li>
                    <li>
                      Allow time for students understanding, if necessary, repeat key instructions.
                    </li>
                    <li>
                      Check for understanding of instructions by asking student to tell you what
                      they have to do.
                    </li>
                    <li>Use symbols or visual aids to support spoken language.</li>
                    <li>
                      Make clear to the student if and when you are changing the topic of
                      conversation.
                    </li>
                    <li>
                      Use scaffolding technique to support students if they are unable to answer a
                      question.
                    </li>
                    <li>Use text frames/key phrases to support oral work.</li>
                    <li>Ask clear, direct questions when checking for understanding.</li>
                  </ol>
                </Typography>
              </Box>
              <br />
              <Typography variant='h6'>Strategies to use with students who have ADHD</Typography>
              <Box pl={6}>
                <Typography component='div'>
                  <ol style={{ lineHeight: 2.5 }}>
                    <li>Start each lesson by outlining what will be covered.</li>
                    <li>Explain any changes of routine to the student in advance.</li>
                    <li>
                      Set very clear expectations/rules for the lesson at the beginning of each
                      session.
                    </li>
                    <li>Have high expectations for the student</li>
                    <li>Focus on effort, rather than excellence.</li>
                    <li>
                      Always tell the student what to do rather than what not to do. Focus on
                      positives rather than negatives.
                    </li>
                    <li>Set small tasks with clear objectives.</li>
                    <li>Break down activities into short manageable chunks.</li>
                    <li>Provide students with opportunities for verbal responses.</li>
                    <li>
                      Create a culture in lessons where you show that it is OK to make mistakes and
                      it is important to learn from the mistakes.
                    </li>
                    <li>
                      If your student’s distracted and goes off topic, direct the conversation back
                      to the topic.
                    </li>
                  </ol>
                </Typography>
              </Box>
              <br />
              <br />
              <br />
              <br />

              <Typography variant='h4' id='helpful-resources'>
                Helpful Resources
                <Divider />
              </Typography>
              <br />

              <Typography>
                To further support you tutoring or mentoring a student with a specific need, we’ve
                listed some helpful resources for you below:
              </Typography>
              <br />
              <Typography>Dyslexia/literacy</Typography>
              <ul style={{ listStyle: 'none', lineHeight: 2 }}>
                <li>
                  <CustomLink href='http://www.thedyslexia-spldtrust.org.uk/resources'>
                    http://www.thedyslexia-spldtrust.org.uk/resources
                  </CustomLink>
                </li>
                <li>
                  <CustomLink href='http://www.interventionsforliteracy.org.uk/'>
                    http://www.interventionsforliteracy.org.uk/
                  </CustomLink>
                </li>
                <li>
                  <CustomLink href='http://www.dyslexiaaction.org.uk/'>
                    http://www.dyslexiaaction.org.uk/
                  </CustomLink>
                </li>
                <li>
                  <CustomLink href='https://literacytrust.org.uk/'>
                    https://literacytrust.org.uk/
                  </CustomLink>
                </li>
              </ul>
              <br />
              <Typography>Speech, Language and Communication</Typography>
              <ul style={{ listStyle: 'none', lineHeight: 2 }}>
                <li>
                  <CustomLink href='https://ican.org.uk/'>https://ican.org.uk/</CustomLink>
                </li>
                <li>
                  <CustomLink href='https://www.slcframework.org.uk/'>
                    https://www.slcframework.org.uk/
                  </CustomLink>
                </li>

                <li>
                  <CustomLink href='https://www.afasic.org.uk/'>
                    https://www.afasic.org.uk/
                  </CustomLink>
                </li>
                <li>
                  <CustomLink href='http://www.communicationmatters.org.uk/'>
                    http://www.communicationmatters.org.uk/
                  </CustomLink>
                </li>
              </ul>
              <br />
              <Typography>Autism</Typography>
              <ul style={{ listStyle: 'none', lineHeight: 2 }}>
                <li>
                  <CustomLink href='http://www.autismeducationtrust.org.uk/'>
                    http://www.autismeducationtrust.org.uk/
                  </CustomLink>
                </li>
                <li>
                  <CustomLink href='https://autismawareness.com/'>
                    https://autismawareness.com/
                  </CustomLink>
                </li>
                <li>
                  <CustomLink href='http://www.autism.org.uk/'>
                    http://www.autism.org.uk/
                  </CustomLink>
                </li>
              </ul>

              <br />
              <Typography>Mental Health</Typography>
              <ul style={{ listStyle: 'none', lineHeight: 2 }}>
                <li>
                  <CustomLink href='http://www.youngminds.org.uk/'>
                    http://www.youngminds.org.uk/
                  </CustomLink>
                </li>
                <li>
                  <CustomLink href='https://www.headstogether.org.uk/'>
                    https://www.headstogether.org.uk/
                  </CustomLink>
                </li>
                <li>
                  <CustomLink href='https://www.mind.org.uk/'>https://www.mind.org.uk/</CustomLink>
                </li>
              </ul>
              <br />
              <Typography>General resources</Typography>
              <ul style={{ listStyle: 'none', lineHeight: 2 }}>
                <li>
                  <CustomLink href='http://www.twinkl.co.uk/'>http://www.twinkl.co.uk/</CustomLink>
                </li>
                <li>
                  <CustomLink href='http://www.nasen.org.uk/'>http://www.nasen.org.uk/</CustomLink>
                </li>
                <li>
                  <CustomLink href='https://senmagazine.co.uk/'>
                    https://senmagazine.co.uk/
                  </CustomLink>
                </li>
                <li>
                  <CustomLink href='http://www.senteacher.org/'>
                    http://www.senteacher.org/
                  </CustomLink>
                </li>
              </ul>

              <br />
              <br />
              <br />
              <br />

              <Typography variant='h4' id='insurance'>
                Insurance
                <Divider />
              </Typography>

              <br />

              <Typography>
                There’s no legal obligation for private tutors to have insurance, but it’s worth
                thinking about, especially if you plan to tutor in person.
                <br />
                <br />
                There are two types of insurance that tutors normally have:
                <br />
                <br />
                <strong>Public liability insurance </strong> – this protects you against legal
                liability following an injury to a student (through a trip or fall, for example) or
                damage to third party property (spilt coffee on a laptop, for example).
                <br />
                <br />
                <strong>Private indemnity insurance </strong>– this covers you against any claims
                that you made a mistake in your work or failed to provide an adequate service.
                <br />
                <br />
                You should be able to find professional indemnity and public liability insurance for
                less than £10 per month, and perhaps with discounted rates for taking both together.
                <br />
                <br />
                The following companies are amongst insurers in the UK that offer insurance packages
                specifically for tutors:
                <br />
                <br />
                <CustomLink href='https://www.hiscox.co.uk/business-insurance/tutors-and-private-teachers'>
                  Hiscox
                </CustomLink>
                <br />
                <br />
                <CustomLink href='https://www.directlineforbusiness.co.uk/small-business-insurance/teachers-and-tutors?cmpid=ppc_generic/acqc/op&gclid=EAIaIQobChMIqp2n1on07wIVkMLtCh2dkw9gEAAYAyAAEgKwZvD_BwE&gclsrc=aw.ds'>
                  Direct Line for Business
                </CustomLink>
                <br />
                <br />
                <CustomLink href='https://www.simplybusiness.co.uk/insurance/private-tutor/'>
                  Simply Business
                </CustomLink>
                <br />
                <br />
                <CustomLink href='https://www.towergateinsurance.co.uk/'>
                  Towergate Insurance
                </CustomLink>
                <br />
                <br />
                <CustomLink href='https://www.markeluk.com/business-insurance/teachers-tutors-insurance?gclid=EAIaIQobChMIqp2n1on07wIVkMLtCh2dkw9gEAAYASAAEgJjXfD_BwE&gclsrc=aw.ds'>
                  Markel Direct
                </CustomLink>
                <br />
                <br />
                <CustomLink href='https://www.policybee.co.uk/teachers-insurance'>
                  Policy Bee
                </CustomLink>
                <br />
                <br />
                We recommend shopping around or using a comparison website to find the best deals.
              </Typography>

              <br />
              <br />
              <br />
              <br />
              <Typography variant='h4' id='DBS-checks'>
                DBS Checks
                <Divider />
              </Typography>
              <br />
              <Typography>
                Teachers in the UK all have Enhanced DBS checks to work with children and young
                people in schools, but there aren’t any legal obligations for private tutors to have
                DBS checks.
                <br />
                <br />
                If you do want to get one however then you will need to find an umbrella company to
                provide you with this as detailed on the government’s website:{' '}
                <CustomLink href='https://www.gov.uk/find-dbs-umbrella-body'>
                  https://www.gov.uk/find-dbs-umbrella-body
                </CustomLink>
                .
                <br />
                <br />A DBS check shows parents that you don’t have any criminal convictions and
                makes them feel safe when hiring you to work with their child. It’s something you
                should certainly inform them of in your initial consultation if you do have one.
              </Typography>

              <br />
              <br />
              <br />
              <br />
              <Typography variant='h4' id='HMRC'>
                Registering with HMRC
                <Divider />
              </Typography>
              <br />

              <Typography>
                As a private tutor you’re a sole trader, so you’re only responsible for yourself.
                <br />
                <br />
                As a sole trader, you’re responsible for completing your own tax returns and paying
                your own tax, although you can pay an accountant to help you.
                <br />
                <br />
                To register as a sole trader, you need to contact HMRC within 3 months of delivering
                your first session to a student. You need to tell HMRC that you pay tax through
                ‘Self Assessment’ and you’ll need to file a tax return every year.
                <br />
                <br />
                You can register with HMRC online, or by phone.
                <br />
                <br />
                More information about this can be found here:{' '}
                <CustomLink href='https://www.gov.uk/set-up-sole-trader'>
                  https://www.gov.uk/set-up-sole-trader
                </CustomLink>
                .
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Handbook;
