import React, { useCallback, useState } from 'react';
import { Box, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { MIN_RATE, POUND_SYMBOL } from 'enums';
import { useStyles } from './style';
import { computeEarning } from 'utils/earnings';

function HourlyRate({ fieldName, defaultValue }) {
  const classes = useStyles();
  const [hourlyRate, setHourlyRate] = useState(defaultValue);

  const handleHourlyRateChange = useCallback((e) => setHourlyRate(e.target.value), []);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          className={classes.hourlyRate}
          fullWidth
          inputProps={{ min: MIN_RATE, max: 999, type: 'number' }}
          InputProps={{
            endAdornment: <InputAdornment position='end'>/hr</InputAdornment>,
            startAdornment: <InputAdornment position='start'>{POUND_SYMBOL}</InputAdornment>,
          }}
          label='Online rate'
          name={fieldName}
          onKeyDown={(e) => ['e', 'E', '-', '+', '.'].includes(e.key) && e.preventDefault()}
          onChange={handleHourlyRateChange}
          placeholder='Enter your online hourly rate'
          required
          value={hourlyRate}
          variant='outlined'
        />
      </Grid>
      <Grid item xs={12}>
        <Box pb={1} pt={4}>
          <Typography>
            <strong>Your earnings</strong>
          </Typography>
        </Box>
        <table className={classes.earningsTable}>
          <thead>
            <tr>
              <th>
                <Typography variant='caption'>1-249 hours taught</Typography>
              </th>
              <th>
                <Typography variant='caption'>250+ hours taught</Typography>
              </th>
              <th>
                <Typography variant='caption'>Group class</Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Typography>
                  {POUND_SYMBOL}
                  {computeEarning(hourlyRate, 25).toFixed(2)}
                  /hr
                </Typography>
              </td>
              <td>
                <Typography>
                  {POUND_SYMBOL}
                  {computeEarning(hourlyRate, 20).toFixed(2)}
                  /hr
                </Typography>
              </td>
              <td>
                <Typography>
                  {POUND_SYMBOL}
                  {computeEarning(hourlyRate, 0).toFixed(2)}
                  /hr
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
}

export default HourlyRate;
