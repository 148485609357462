import { addDoc, collection, getDocs, query, updateDoc, doc } from '@firebase/firestore';
import { db } from 'config/firebaseConfig';
import { getDateFromObject } from 'utils/date';

const bookingsRef = collection(db, 'bookings');

export const createBooking = (bookingDetails) => addDoc(bookingsRef, bookingDetails);

export const getBookings = (...queryConstraints) =>
  getDocs(queryConstraints ? query(bookingsRef, ...queryConstraints) : bookingsRef).then((snapshot) =>
    snapshot.docs.map((doc) => {
      const docData = doc.data();
      return {
        id: doc.id,
        ...docData,
        createdAt: getDateFromObject(docData.createdAt),
        selectedDate: getDateFromObject(docData.selectedDate),
        cancelDate: getDateFromObject(docData.cancelDate),
      };
    })
  );

export const updateBooking = (id, newData) => updateDoc(doc(bookingsRef, id), newData);
