import { ROLES } from 'enums';

const { ADMIN, TUTOR, STUDENT, VISITOR } = ROLES;

export const getUserRole = (user) => {
  switch (true) {
    case user?.roles?.includes('administrator'): {
      return ADMIN;
    }

    case user?.roles?.includes('tutor_instructor'): {
      return TUTOR;
    }

    case user?.roles?.includes('student'): {
      return STUDENT;
    }

    default: {
      return VISITOR;
    }
  }
};

export const getTutorLocation = (user) => {
  try {
    const tutor_locations = JSON.parse(user.meta.tutor_locations[0]);
    return tutor_locations;
  } catch (error) {
    return {
      location: null,
      travelDistance: 0,
    };
  }
};
