import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  Icon,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { theme } from 'themes';
import { useStyles } from './style';
import classNames from 'classnames';
import { useAuth } from 'providers';
import { PHOTO_PLACEHOLDER } from 'enums';
import { addReview } from 'services/firebase/reviews';

const ReviewDialog = React.memo(({ open, tutor, onCancel }) => {
  const { user } = useAuth();
  const classes = useStyles();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const [submitting, setSubmitting] = useState(false);
  const [rating, setRating] = useState(1);
  const [errors, setErrors] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmitReview = async (e) => {
    e.preventDefault();
    const reviewText = e.target.reviewText.value;
    const newErrors = {};

    if (rating === 0) {
      newErrors.rating = 'On a scale of 1 to 5, how would you rate this tutor/mentor?';
    }

    if (!reviewText) {
      newErrors.reviewText =
        "What's the point of an empty review? Please write something relevant.";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length) {
      return;
    }

    setSubmitting(true);

    try {
      // save review
      const reviewData = {
        rating,
        reviewText,
        reviewer: {
          id: user.id,
          name: user.name,
          email: user.email,
          photoURL: user.meta.photo_url[0] || PHOTO_PLACEHOLDER,
        },
        reviewed: { id: tutor.id, name: tutor.name, email: tutor.email },
        approved: false,
        createdAt: new Date(),
      };

      await addReview(reviewData);
      setShowConfirmation(true);
    } catch (error) {
      setErrors({ serverError: error.message });
    }

    setSubmitting(false);
  };

  return (
    <Dialog open={open} fullWidth>
      <Box p={2}>
        {showConfirmation ? (
          <Box>
            <Box textAlign='center'>
              <Typography gutterBottom variant='h6'>
                Thanks for your feedback!
              </Typography>
              <Box pb={2}>
                <Divider />
              </Box>
              <Typography gutterBottom>
                Your review will become visible once it will be approved by an admin
              </Typography>
              <Button
                variant='outlined'
                color='primary'
                disabled={submitting}
                onClick={() => onCancel()}
              >
                Close
              </Button>
            </Box>
          </Box>
        ) : (
          <form onSubmit={handleSubmitReview}>
            <Box textAlign='center' pb={4}>
              <Typography variant='h6' gutterBottom>
                Write a review for:{' '}
                <span style={{ color: theme.palette.primary.main }}>{tutor?.name}</span>
              </Typography>
              <Divider />
            </Box>

            <Typography gutterBottom>
              <strong>Rating:</strong>
            </Typography>

            <Grid container className={classes.ratingContainer}>
              {Array.from(Array(5).keys()).map((k, i) => (
                <Icon
                  onClick={() => setRating(5 - i)}
                  key={k}
                  className={classNames(
                    classes.reviewStar,
                    rating === 5 - i ? 'selected' : undefined
                  )}
                  fontSize='large'
                >
                  {'star'}
                </Icon>
              ))}
            </Grid>

            {errors.rating && (
              <Typography color='error' variant='caption'>
                {errors.rating}
              </Typography>
            )}

            <Box py={2}>
              <Typography>
                <strong>Review:</strong>
              </Typography>
              <TextField
                name='reviewText'
                multiline
                variant='outlined'
                fullWidth
                minRows={5}
                maxRows={5}
                error={Boolean(errors?.reviewText)}
                helperText={errors?.reviewText}
                placeholder='Describe your experience with this tutor...'
              />
            </Box>

            {errors?.serverError && (
              <Box textAlign='right' pb={2}>
                <Typography color='error' variant='caption'>
                  {errors.serverError}
                </Typography>
              </Box>
            )}

            <Grid container justifyContent='flex-end' spacing={2}>
              <Grid item xs={xs}>
                <Button
                  fullWidth
                  variant='outlined'
                  color='primary'
                  disabled={submitting}
                  onClick={() => {
                    setErrors({});
                    setRating(0);
                    onCancel();
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={xs}>
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={submitting}
                >
                  Submit {!xs && <>Review</>}
                  {submitting && <CircularProgress size={24} style={{ position: 'absolute' }} />}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Box>
    </Dialog>
  );
});

export default ReviewDialog;
