import React, { useCallback, useMemo, useState } from 'react';
import { theme } from 'themes';
import { getTomorrow } from 'helpers/date';
import {
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

const hours = Array.from(Array(23).keys()).slice(8);
const halfHoursLength = Math.floor((hours.length - 1) / 2);

function AvailabilityCalendar(
  { bookings = [], availability = [], setAvailability, onTimeSlotSelect, defaultStartDate },
  ref
) {
  const xs = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const sm = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const daysCount = useMemo(() => (sm ? 3 : 7), [sm]);
  const [startDate, setStartDate] = useState(defaultStartDate || getTomorrow());
  const [collapsed, setCollapsed] = useState(true);

  const daysToDisplay = useMemo(() => {
    const days = [];

    for (let i = 0; i < daysCount; i++) {
      const nextDay = new Date(startDate.getTime());
      nextDay.setDate(nextDay.getDate() + i);
      nextDay.setHours(0, 0, 0, 0);
      days.push(nextDay);
    }

    return days;
  }, [daysCount, startDate]);

  const handleGoNext = () => setStartDate(getTomorrow(daysToDisplay[daysToDisplay.length - 1]));

  const handleGoBack = () => {
    const firstDay = new Date(daysToDisplay[0].getTime());
    firstDay.setDate(firstDay.getDate() - daysCount);

    if (firstDay.getTime() < getTomorrow().getTime()) {
      return setStartDate(getTomorrow());
    }

    setStartDate(firstDay);
  };

  const isBookedHour = useCallback(
    (day, hour) => {
      if (!bookings.length) {
        return false;
      }

      const bookedHours = bookings
        .filter((b) => new Date(b.selectedDate).toDateString() === day.toDateString())
        .map((b) => Array.from(Array(b.duration).keys()).map((key) => b.startTime + key))
        .flat();

      return bookedHours.includes(hour);
    },
    [bookings]
  );

  const isSelected = useCallback(
    (day, hour) =>
      availability.some(
        ({ date, hours }) => date.toDateString() === day.toDateString() && hours.includes(hour)
      ),
    [availability]
  );

  const getAvailableHours = useCallback(
    (day) =>
      setAvailability
        ? hours
        : availability.find((a) => a.date.toLocaleDateString() === day.toLocaleDateString())
            ?.hours || [],
    [availability, setAvailability]
  );

  const handleTimeSlotClick = (date, hour) => {
    if (!setAvailability) {
      if (typeof onTimeSlotSelect === 'function') {
        onTimeSlotSelect(date, hour);
      }
      return;
    }

    const foundDay = availability.find((a) => a.date.getTime() === date.getTime());

    if (foundDay) {
      if (isSelected(date, hour)) {
        const newHours = foundDay.hours.filter((h) => h !== hour);
        if (!newHours.length) {
          return setAvailability((prevAvailability) =>
            prevAvailability.filter((a) => a.date.getTime() !== date.getTime())
          );
        }

        return setAvailability((prevAvailability) =>
          prevAvailability.map((a) =>
            a.date.getTime() === date.getTime() ? { ...a, hours: newHours } : a
          )
        );
      }

      return setAvailability((prevAvailability) =>
        prevAvailability.map((a) =>
          a.date.getTime() === date.getTime()
            ? { ...a, hours: [...a.hours, hour].sort((a, b) => a - b) }
            : a
        )
      );
    }

    setAvailability((prevAvailability) => [...prevAvailability, { date, hours: [hour] }]);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems='center'
          spacing={xs ? 0 : 2}
          justifyContent={xs ? 'center' : 'flex-start'}
        >
          <Grid item>
            <IconButton
              color='primary'
              onClick={handleGoBack}
              disabled={startDate.toDateString() === getTomorrow().toDateString()}
            >
              <Icon>arrow_back</Icon>
            </IconButton>
          </Grid>

          <Grid item>
            <Typography variant='h6'>
              {daysToDisplay[0].toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short',
              })}{' '}
              -{' '}
              {daysToDisplay[daysToDisplay.length - 1].toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
              })}
            </Typography>
          </Grid>

          <Grid item>
            <IconButton color='primary' onClick={handleGoNext}>
              <Icon>arrow_forward</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container wrap='nowrap'>
          {daysToDisplay.map((day) => (
            <Grid
              item
              xs
              key={String(day.getTime())}
              container
              direction='column'
              alignItems='center'
              spacing={2}
            >
              <Grid item>
                <Typography>{day.toDateString().slice(0, 3)}</Typography>
              </Grid>
              <Grid item>
                <Typography variant='h6'>
                  {day.toLocaleDateString('en-GB', { day: '2-digit' })}
                </Typography>
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <Divider />
              </Grid>
              {(collapsed
                ? getAvailableHours(day).slice(0, halfHoursLength)
                : getAvailableHours(day)
              ).map((hour) => {
                const isBooked = isBookedHour(day, hour);

                return (
                  <Grid key={hour} item style={{ width: '100%', padding: theme.spacing(0.5, 2) }}>
                    <Button
                      color={
                        setAvailability
                          ? isSelected(day, hour)
                            ? 'primary'
                            : 'default'
                          : undefined
                      }
                      disabled={isBooked}
                      variant={
                        isBooked
                          ? 'text'
                          : setAvailability && isSelected(day, hour)
                          ? 'contained'
                          : 'outlined'
                      }
                      fullWidth
                      style={{ minWidth: 'unset' }}
                      onClick={() => handleTimeSlotClick(day, hour)}
                    >
                      {isBooked ? 'Booked' : `${hour}:00`}
                    </Button>
                  </Grid>
                );
              })}
              {!getAvailableHours(day).length && (
                <Grid item style={{ width: '100%', textAlign: 'center' }}>
                  <Typography variant='body2' color='textSecondary'>
                    No availability
                  </Typography>
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
      {daysToDisplay.some((day) => getAvailableHours(day).length > halfHoursLength) && (
        <Grid item xs={12}>
          <Box textAlign='center' py={2}>
            <Button
              fullWidth
              color='primary'
              variant='contained'
              endIcon={<Icon>{collapsed ? 'arrow_downwards' : 'arrow_upwards'}</Icon>}
              onClick={() => setCollapsed((prevState) => !prevState)}
              style={{ maxWidth: 200 }}
            >
              {collapsed ? 'View All' : 'Collapse'}
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

export default React.memo(React.forwardRef(AvailabilityCalendar));
