import { createTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

export const COLORS = {
  primary: '#0015BF',
  secondary: '#ff9040',
  lightGrey: grey[200],
};

export const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.secondary,
    },
  },
  typography: {
    h1: {
      fontFamily: `"Lato", "Helvetica", sans-serif`,
    },
    h2: {
      fontFamily: `"Lato", "Helvetica", sans-serif`,
    },
    h3: {
      fontFamily: `"Lato", "Helvetica", sans-serif`,
    },
    h4: {
      fontFamily: `"Lato", "Helvetica", sans-serif`,
    },
    h5: {
      fontFamily: `"Lato", "Helvetica", sans-serif`,
    },
    h6: {
      fontFamily: `"Lato", "Helvetica", sans-serif`,
      fontWeight: 600,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: 35,
        minWidth: 120,
      },
    },
    MuiDialog: {
      paper: {
        marginLeft: 16,
        marginRight: 16,
      },
      paperFullWidth: {
        width: '100%',
      },
    },
    MuiDataGrid: {
      root: {
        '& .MuiDataGrid-toolbarContainer': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  props: {
    MuiTooltip: {
      arrow: true,
    },
  },
});
