import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Icon,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useTutors, useReviews, useFilters } from 'providers';
import { MIN_RATE } from 'enums';
import { Skeleton } from '@material-ui/lab';
import { filterTutors } from 'helpers/filter';
import { useStyles } from './style';
import { theme } from 'themes';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { collection, query } from 'firebase/firestore';
import { db } from 'config/firebaseConfig';
import SearchHeader from 'components/SearchHeader';
import SearchResults from 'components/SearchResults';
import FiltersDialog from 'components/FiltersDialog';
import CustomLink from 'components/CustomLink';

export default function Search() {
  const classes = useStyles();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const { tutors, tutorsLoaded, loading, loadTutors } = useTutors();
  const { reviews, reviewsLoaded, loading: loadingReviews, loadReviews } = useReviews();
  const { filters, setFilters } = useFilters();
  const [openFilters, setOpenFilters] = useState(false);
  const [uploadedFiles = []] = useCollectionData(query(collection(db, 'filesToApprove')), {
    idField: 'id',
  });

  useEffect(() => {
    if (!tutorsLoaded && !loading) {
      loadTutors();
    }
  }, [loadTutors, loading, tutorsLoaded]);

  useEffect(() => {
    if (!reviewsLoaded && !loadingReviews) {
      loadReviews();
    }
  }, [loadReviews, loadingReviews, reviewsLoaded]);

  const handleFiltersClose = useCallback(() => {
    setOpenFilters(false);
  }, []);

  const filteredResults = useMemo(
    () =>
      filterTutors(
        tutors.map((t) => ({
          ...t,
          tutorFiles: uploadedFiles.filter((file) => file.uploadedBy.id === t.id),
          reviews: reviews.filter((r) => r.reviewed.id === t.id),
        })),
        filters
      ),
    [filters, reviews, tutors, uploadedFiles]
  );

  const onlineTutors = useMemo(() => {
    const { inPerson, ...otherFilters } = filters;

    return filterTutors(
      tutors.map((t) => ({
        ...t,
        tutorFiles: uploadedFiles.filter((file) => file.uploadedBy.id === t.id),
        reviews: reviews.filter((r) => r.reviewed.id === t.id),
      })),
      otherFilters
    );
  }, [filters, reviews, tutors, uploadedFiles]);

  const resultsCount = useMemo(() => filteredResults.length, [filteredResults]);
  const loadingData = useMemo(() => loading || loadingReviews, [loading, loadingReviews]);
  const filtersCount = useMemo(() => {
    const { priceRange, availability, gender, additionalFilters } = filters;
    return [
      priceRange && (!priceRange.includes(MIN_RATE) || !priceRange.includes(80)),
      availability?.length,
      gender,
      additionalFilters?.length,
    ].filter((value) => Boolean(value)).length;
  }, [filters]);

  return (
    <Box>
      <Container>
        <Box pb={10} pt={18}>
          <Box textAlign='center' pb={10}>
            <Typography variant='h2'>
              Find your perfect{' '}
              <CustomLink
                href='#'
                variant='h2'
                style={filters?.searchFor === 'tutor' ? { textDecoration: 'underline' } : undefined}
                onClick={(e) => {
                  e.preventDefault();
                  setFilters((prevFilters) => ({ ...prevFilters, searchFor: 'tutor' }));
                }}
              >
                <strong>tutor</strong>
              </CustomLink>{' '}
              or{' '}
              <CustomLink
                href='#'
                variant='h2'
                style={
                  filters?.searchFor === 'mentor' ? { textDecoration: 'underline' } : undefined
                }
                onClick={(e) => {
                  e.preventDefault();
                  setFilters((prevFilters) => ({ ...prevFilters, searchFor: 'mentor' }));
                }}
              >
                <strong>mentor</strong>
              </CustomLink>
            </Typography>
          </Box>

          <SearchHeader />

          <Box pt={4}>
            <Grid container alignItems='center' justifyContent='flex-end' spacing={2}>
              <Grid item>
                <Badge badgeContent={filtersCount} color='primary'>
                  <Button
                    style={xs ? { minWidth: 'unset' } : undefined}
                    color='primary'
                    variant='outlined'
                    startIcon={<Icon>filter_list</Icon>}
                    onClick={() => setOpenFilters(true)}
                  >
                    Filters
                  </Button>
                </Badge>
              </Grid>
              <Grid item>
                <TextField
                  size='small'
                  label='Sort By'
                  variant='outlined'
                  select
                  value={filters.sortBy || 'price-asc'}
                  onChange={(e) =>
                    setFilters((prevFilters) => ({ ...prevFilters, sortBy: e.target.value }))
                  }
                >
                  <MenuItem value='price-asc'>Price Low to High</MenuItem>
                  <MenuItem value='price-desc'>Price High to Low</MenuItem>
                  <MenuItem value='rating'>Top Rated</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Box>
          <Box py={2}>
            <Divider />
          </Box>

          <Box pb={4}>
            {loadingData ? (
              <Skeleton variant='rect' style={{ maxWidth: 200 }} />
            ) : (
              <Typography>
                <strong>{resultsCount}</strong>{' '}
                {['tutor', 'mentor'].includes(filters.searchFor) ? filters?.searchFor : 'tutor'}
                {resultsCount === 1 ? '' : 's'}
              </Typography>
            )}
          </Box>

          <SearchResults loading={loadingData} tutors={filteredResults} showTravelDistance />

          {filteredResults.length === 0 && !loadingData && (
            <>
              <Box textAlign='center' py={4} px={2} mt={2} mb={4} className={classes.noResults}>
                {filters?.inPerson && filters?.address && onlineTutors.length > 0 ? (
                  <Typography variant='h5' style={{ maxWidth: 700, margin: 'auto' }}>
                    We're sorry we have no one available for <strong>in person</strong> services
                    within your specified location, but the following tutors/mentors are available
                    for <strong>online</strong> sessions, which are just as good!
                  </Typography>
                ) : (
                  <Typography variant='h5' style={{ maxWidth: 700, margin: 'auto' }}>
                    We're sorry but there are no {filters.searchFor}s currently available that match
                    your criteria at this time, please check back in the future!
                  </Typography>
                )}
              </Box>
              <SearchResults loading={loadingData} tutors={onlineTutors} />
            </>
          )}
        </Box>
      </Container>
      <FiltersDialog
        open={openFilters}
        onClose={handleFiltersClose}
        filters={filters}
        setFilters={setFilters}
      />
    </Box>
  );
}
