import React, { createContext, useCallback, useContext, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

const ConfirmationContext = createContext();
export const useConfirmation = () => useContext(ConfirmationContext);

export const ConfirmationProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [dialogDescription, setDialogDescription] = useState(null);
  const [promise, setPromise] = useState(null);

  const confirm = useCallback(
    (description) =>
      new Promise((resolve, reject) => {
        setDialogDescription(description);
        setOpen(true);
        setPromise({ resolve, reject });
      }),
    []
  );

  return (
    <ConfirmationContext.Provider value={confirm}>
      {children}
      <Dialog open={open} fullWidth>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography component='div'>{dialogDescription}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            variant='outlined'
            onClick={() => {
              promise.resolve(false);
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              promise.resolve(true);
              setOpen(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmationContext.Provider>
  );
};
