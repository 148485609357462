import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  TextField,
  Typography,
} from '@material-ui/core';
import { db } from 'config/firebaseConfig';
import { collection, deleteField, orderBy, query } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useQualifications } from 'providers';
import { getDateFromObject, prettifyDateTime } from 'utils/date';
import { DataGrid } from '@mui/x-data-grid';
import { useStyles } from './style';
import { deleteFileToApprove, updateFileToApproveDoc } from 'services/firebase/filesToApprove';
import { deleteFile } from 'services/firebase/storage';
import { theme } from 'themes';
import LoadingButton from 'components/LoadingButton';
import PageLayout from 'components/PageLayout';
import CustomLink from 'components/CustomLink';

const statusColor = {
  pending_approval: theme.palette.text.primary,
  approved: theme.palette.success.main,
  rejected: theme.palette.error.main,
};

const getColumns = (qualifications, onUpdate, onDelete, loadingRowId) => [
  {
    field: 'createdAt',
    headerName: 'Uploaded At',
    type: 'date',
    flex: true,
    valueGetter: ({ value }) => getDateFromObject(value),
    renderCell: ({ value }) => prettifyDateTime(value),
    minWidth: 200,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: true,
    valueGetter: ({ value }) => String(value).replace('_', ' '),
    renderCell: ({ value, row }) => (
      <span style={{ textTransform: 'capitalize', color: statusColor[row.status] }}>{value}</span>
    ),
    minWidth: 200,
  },
  {
    field: 'tag',
    headerName: 'Tag',
    valueGetter: ({ value }) =>
      ['QTS', 'DBS'].includes(value) ? value : qualifications.find((q) => q.id === value)?.title,
    flex: true,
    minWidth: 200,
  },
  {
    field: 'downloadUrl',
    headerName: 'File URL',
    renderCell: ({ value }) => (
      <Box style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <CustomLink href={value} variant='caption' target='_blank'>
          {value}
        </CustomLink>
      </Box>
    ),
    flex: true,
    minWidth: 200,
  },
  {
    field: 'uploadedBy',
    headerName: 'Uploaded By',
    valueGetter: ({ value }) => value.name,
    renderCell: ({
      row: {
        uploadedBy: { name, email },
      },
    }) => (
      <CustomLink href={`mailto:${email}`} variant='body2'>
        {name}
      </CustomLink>
    ),
    flex: true,
    minWidth: 200,
  },
  {
    field: 'approve',
    headerName: 'hidden',
    renderCell: ({ row }) => (
      <Grid container spacing={1}>
        {row.status !== 'approved' && (
          <Grid item>
            <LoadingButton
              size='small'
              color='default'
              loading={row.id === loadingRowId}
              startIcon={<Icon style={{ color: theme.palette.success.main }}>check</Icon>}
              onClick={() => onUpdate(row, 'approved')}
            >
              Approve
            </LoadingButton>
          </Grid>
        )}
        {row.status === 'rejected' ? (
          <Grid item>
            <LoadingButton
              size='small'
              color='default'
              loading={row.id === loadingRowId}
              startIcon={<Icon color='error'>delete</Icon>}
              onClick={() => onDelete(row)}
            >
              Delete
            </LoadingButton>
          </Grid>
        ) : (
          <Grid item>
            <LoadingButton
              size='small'
              color='default'
              loading={row.id === loadingRowId}
              startIcon={<Icon color='error'>close</Icon>}
              onClick={() => onUpdate(row, 'rejected')}
            >
              Reject
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    ),
    flex: true,
    minWidth: 300,
  },
];

const rowsPerPageOptions = [5, 10, 20];

function TutorCertificates() {
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(5);
  const { qualifications, loadingQualifications } = useQualifications();
  const [loadingRowId, setLoadingRowId] = useState(null);
  const [rejectingRow, setRejectingRow] = useState(null);
  const [error, setError] = useState(null);
  const [filesToApprove = [], loadingFilesToApprove] = useCollectionData(
    query(collection(db, 'filesToApprove'), orderBy('createdAt', 'desc')),
    { idField: 'id' }
  );

  const handleStatusUpdate = useCallback(async (rowId, status, reason) => {
    setLoadingRowId(rowId);
    try {
      await updateFileToApproveDoc(
        rowId,
        status === 'rejected' ? { status, reason } : { status, reason: deleteField() }
      );
    } catch (error) {
      setError(error.message);
    }
    setLoadingRowId(null);
  }, []);

  const handleUpdate = useCallback(
    async (row, newStatus) => {
      if (newStatus === 'rejected') {
        return setRejectingRow(row);
      }

      handleStatusUpdate(row.id, newStatus);
    },
    [handleStatusUpdate]
  );

  const handleDelete = useCallback(async (row) => {
    setLoadingRowId(row.id);
    try {
      await deleteFile(row.downloadUrl);
      await deleteFileToApprove(row.id);
    } catch (error) {
      setError(error.message);
    }
    setLoadingRowId(null);
  }, []);

  const handleReject = (e) => {
    e.preventDefault();
    const reason = e.target.reason?.value?.trim();
    handleStatusUpdate(rejectingRow.id, 'rejected', reason);
    setRejectingRow(null);
  };

  return (
    <PageLayout>
      <Box pb={10} textAlign='center'>
        <Typography variant='h2'>Manage Tutor Certificates</Typography>
      </Box>

      <DataGrid
        className={classes.dataGrid}
        autoHeight
        rows={filesToApprove}
        columns={getColumns(qualifications, handleUpdate, handleDelete, loadingRowId)}
        rowsPerPageOptions={rowsPerPageOptions}
        loading={loadingQualifications || loadingFilesToApprove}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
      />
      {error && (
        <Dialog open={true} maxWidth='sm' fullWidth>
          <DialogContent>
            <Typography color='error'>{error}</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' color='primary' onClick={() => setError(null)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {Boolean(rejectingRow) && (
        <Dialog open={true} maxWidth='sm' fullWidth>
          <DialogTitle>
            <Box textAlign={'center'}>
              <Typography variant='h6'>Reason</Typography>
            </Box>
          </DialogTitle>
          <Divider />
          <DialogContent style={{ padding: theme.spacing(4, 2) }}>
            <form onSubmit={handleReject} id='reject-certificate'>
              <Typography>
                Let <strong>{rejectingRow.uploadedBy.name}</strong> know why you are rejecting this
                certificate.
              </Typography>
              <br />

              <TextField
                required
                fullWidth
                multiline
                minRows={5}
                variant='outlined'
                placeholder='Feedback...'
                name='reason'
              />
            </form>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button variant='outlined' color='primary' onClick={() => setRejectingRow(null)}>
              Close
            </Button>
            <LoadingButton type='submit' form='reject-certificate'>
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </PageLayout>
  );
}

export default TutorCertificates;
