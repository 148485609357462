import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Divider, Tab, Tabs, Typography } from '@material-ui/core';
import { getBookings } from 'services/firebase/bookings';
import { useAuth } from 'providers';
import { where } from '@firebase/firestore';
import { getUserRole } from 'utils/user';
import { useStyles } from './style';
import Upcoming from './Upcoming';
import Completed from './Completed';
import Cancelled from './Cancelled';
import { ROLES } from 'enums';

const TabPanel = ({ children, value, index, ...other }) => (
  <Box
    width='100%'
    height='100%'
    hidden={value !== index}
    role='tabpanel'
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && children}
  </Box>
);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const tabProps = (index) => ({
  id: `bookings-tab-${index}`,
  'aria-controls': `bookings-tabpanel-${index}`,
});

export default function Bookings() {
  const { user } = useAuth();
  const [value, setValue] = useState(0);
  const classes = useStyles({ tab: value });
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const userRole = useMemo(() => getUserRole(user), [user]);

  useEffect(() => {
    const loadBookings = async () => {
      const bookings1 = await getBookings(where(`${userRole}.id`, '==', user.id));
      const bookings2 = await getBookings(
        where('invitedUsers', 'array-contains', { id: user.id, name: user.name, email: user.email })
      );

      const bookings = [...bookings1, ...bookings2];

      if (bookings?.length) {
        setBookings(bookings);
      }

      setLoading(false);
    };

    loadBookings();
  }, [user, userRole]);

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  const rows = useMemo(
    () =>
      bookings.map((b) => {
        const dateTime = b.selectedDate;
        dateTime.setHours(b.startTime, 0, 0, 0);
        return { ...b, dateTime };
      }),
    [bookings]
  );

  const upcoming = useMemo(() => {
    const currentDateTime = new Date();
    return rows
      .filter((r) => r.dateTime > currentDateTime && !r.cancelled)
      .map((r) => ({ ...r, status: 'upcoming' }));
  }, [rows]);

  const completed = useMemo(() => {
    const currentDateTime = new Date();
    return rows
      .filter((r) => r.dateTime < currentDateTime && !r.cancelled)
      .map((r) => ({ ...r, status: 'completed' }));
  }, [rows]);

  const cancelled = useMemo(
    () => rows.filter((r) => r.cancelled).map((r) => ({ ...r, status: 'cancelled' })),
    [rows]
  );

  return (
    <Box pb={10} pt={18}>
      <Box mb={10}>
        <Container>
          <Box textAlign='center' pb={10}>
            <Typography variant='h2'>Manage your bookings</Typography>
          </Box>
          {getUserRole(user) === ROLES.TUTOR && (
            <>
              <Box textAlign='center' pb={10}>
                <Typography variant='h6'>
                  <em>
                    Please make sure you message your student the link to your online classroom at
                    least 2 hours in advance of the lesson
                  </em>
                </Typography>
              </Box>
              <Divider />
            </>
          )}
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='Bookings tabs'
            variant='fullWidth'
            scrollButtons='auto'
          >
            <Tab label='Upcoming' {...tabProps(0)} />
            <Tab label='Completed' {...tabProps(1)} />
            <Tab label='Cancelled' {...tabProps(2)} />
          </Tabs>
          <Divider />
        </Container>
      </Box>

      <Container>
        <TabPanel value={value} index={0}>
          <Upcoming
            rows={upcoming}
            loading={loading}
            className={classes.dataGrid}
            userRole={userRole}
            onChange={setBookings}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Completed
            rows={completed}
            loading={loading}
            className={classes.dataGrid}
            userRole={userRole}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Cancelled
            rows={cancelled}
            loading={loading}
            className={classes.dataGrid}
            userRole={userRole}
          />
        </TabPanel>
      </Container>
    </Box>
  );
}
