import React, { useMemo } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useStyles } from './style';
import { useAuth } from 'providers';
import { PHOTO_PLACEHOLDER } from 'enums';

const ChatListItem = React.memo(({ active, chat, onChatSelect }) => {
  const { user } = useAuth();
  const classes = useStyles();
  const { chatWith, lastMessage } = chat;

  const hasNewMessage = useMemo(
    () => !chat.lastMessage?.seenBy?.includes(user.id),
    [chat.lastMessage?.seenBy, user]
  );

  return (
    <li className={classes.chatListItem}>
      <Button
        color={active ? 'primary' : undefined}
        variant={active ? 'contained' : undefined}
        onClick={() => onChatSelect(chat)}
        fullWidth
        startIcon={<img src={chatWith?.meta?.photo_url[0] || PHOTO_PLACEHOLDER} alt='Chat Item' />}
      >
        <Box className={classes.chatListItemText}>
          <Typography style={{ textTransform: 'capitalize' }}>
            <strong>{chatWith?.name || 'Unavailable'}</strong>
          </Typography>
          <Typography
            variant='caption'
            style={{
              fontWeight: hasNewMessage ? 'bold' : undefined,
            }}
          >
            {lastMessage?.text}
          </Typography>
        </Box>
      </Button>
    </li>
  );
});

const ChatList = React.memo(({ activeChatId, chats, onChatSelect }) => {
  const classes = useStyles();
  return (
    <ul className={classes.chatList}>
      {chats.map((c) => (
        <ChatListItem
          chat={c}
          key={c.id}
          onChatSelect={onChatSelect}
          active={c.id === activeChatId}
        />
      ))}
    </ul>
  );
});

export default ChatList;
