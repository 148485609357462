import React, { createContext, useCallback, useContext, useState } from 'react';
import { getWPUsers } from '../services/wp/users';

const StudentsContext = createContext();
export const useStudents = () => useContext(StudentsContext);

export function StudentsProvider({ children }) {
  const [students, setStudents] = useState([]);
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [studentsLoaded, setStudentsLoaded] = useState(false);

  const loadStudents = useCallback(() => {
    setLoadingStudents(true);
    getWPUsers(new URLSearchParams('context=edit&roles=student&per_page=100'))
      .then(async (students) => {
        setStudents(students);
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setStudentsLoaded(true);
        setLoadingStudents(false);
      });
  }, []);

  return (
    <StudentsContext.Provider
      value={{
        students,
        loadingStudents,
        loadStudents,
        studentsLoaded,
        setStudents,
      }}
    >
      {children}
    </StudentsContext.Provider>
  );
}
