import { collection, doc, getDocs, setDoc } from '@firebase/firestore';
import { db } from 'config/firebaseConfig';

export const tutorSubjectsRef = collection(db, 'tutorSubjects');

export const updateTutorSubjects = (tutorId, tutorSubjectsData) =>
  setDoc(doc(tutorSubjectsRef, String(tutorId)), tutorSubjectsData);

export const getAllTutorsSubjects = () =>
  getDocs(tutorSubjectsRef).then((snapshot) =>
    snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
  );
