import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  chapterList: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      top: 128,
    },
    listStyle: 'none',
    color: theme.palette.secondary.main,
    fontWeight: 500,
    '& > li > a': {
      lineHeight: 2,
      transition: theme.transitions.create('color'),
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
    },
  },
  progressReport: {
    display: 'flex',
  },
}));
