import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  qualificationChip: {
    maxWidth: 'calc(100vw - 100px)',
    '& .MuiChip-label': {
      maxWidth: 1000,
    },
  },
}));
