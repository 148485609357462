import React, { useMemo } from 'react';
import {
  Avatar,
  Box,
  Container,
  Divider,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Tooltip,
  useScrollTrigger,
  useMediaQuery,
} from '@material-ui/core';
import { useAuth } from '../../providers/Auth';
import { useStyles } from './style';
import { routes } from '../../routes';
import { APP_TITLE } from 'enums';
import { stringAvatar } from 'utils/avatar';
import { COLORS, theme } from 'themes';
import { getUserRole } from 'utils/user';
import { menuConfig } from 'config/menuConfig';
import CustomLink from 'components/CustomLink/CustomLink';
import logo from 'assets/images/logo.png';

export default function AppBar() {
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const trigger = useScrollTrigger();
  const { user, logout } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userRole = useMemo(() => getUserRole(user), [user]);

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      <Box className={classes.container}>
        <Container>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            className={classes.content}
          >
            <Tooltip title={`${APP_TITLE} Dashboard`} arrow>
              <CustomLink to={routes.home}>
                <img src={logo} alt='logo' />
              </CustomLink>
            </Tooltip>

            <Tooltip title={<span style={{ textTransform: 'capitalize' }}>{user.name}</span>} arrow>
              <IconButton size='small' onClick={handleClick}>
                <Avatar
                  src={user.meta.photo_url[0] || undefined}
                  {...stringAvatar(user.name)}
                  style={{ backgroundColor: COLORS.secondary, textTransform: 'capitalize' }}
                />
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: xs ? 'center' : 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            getContentAnchorEl={undefined}
          >
            {menuConfig[userRole].map((item) => (
              <CustomLink
                to={item.to}
                href={item.href}
                key={item.title}
                style={{ textDecoration: 'none' }}
              >
                <MenuItem>
                  <Icon className={classes.menuIcon}>{item.icon}</Icon> {item.title}
                </MenuItem>
              </CustomLink>
            ))}

            <Box py={1}>
              <Divider />
            </Box>

            <CustomLink style={{ textDecoration: 'none' }}>
              <MenuItem
                key='logout'
                onClick={() => {
                  logout();
                }}
              >
                <Icon className={classes.menuIcon}>{'logout_icon'}</Icon> Logout
              </MenuItem>
            </CustomLink>
          </Menu>
        </Container>
      </Box>
    </Slide>
  );
}
