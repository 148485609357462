import React, { createContext, useContext } from 'react';

import { orderBy, query } from 'firebase/firestore';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { subjectsRef } from 'services/firebase/subjects';

const SubjectsContext = createContext();
export const useSubjects = () => useContext(SubjectsContext);

export const SubjectsProvider = ({ children }) => {
  const [subjects = [], loading] = useCollectionDataOnce(
    query(subjectsRef, orderBy('name', 'asc')),
    {
      idField: 'id',
    }
  );

  return (
    <SubjectsContext.Provider value={{ subjects, loading }}>{children}</SubjectsContext.Provider>
  );
};
