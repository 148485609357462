import { orderBy, query, where } from '@firebase/firestore';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { deleteReview, reviewsRef, updateReview } from 'services/firebase/reviews';

function PendingReviews() {
  const [reviews = [], loading] = useCollectionData(
    query(reviewsRef, where('approved', '==', false), orderBy('createdAt', 'desc')),
    {
      idField: 'id',
    }
  );

  return (
    <Box pt={18} pb={10}>
      <Container>
        <Box textAlign='center' pb={10}>
          <Typography variant='h2'>Pending Reviews</Typography>
        </Box>

        {loading && (
          <Box style={{ display: 'grid', placeItems: 'center' }} py={4}>
            <CircularProgress />
          </Box>
        )}

        {!loading && reviews.length === 0 && (
          <Box style={{ display: 'grid', placeItems: 'center', width: '100%' }} py={4}>
            <Typography color='textSecondary'>There are no pending reviews...</Typography>
          </Box>
        )}

        <Grid container spacing={2}>
          {reviews.map((r, i) => (
            <Grid item xs={12} key={`review_${i}`}>
              <Grid container alignItems='flex-end' justifyContent='space-between' spacing={2}>
                <Grid item xs={12} sm={12} md>
                  <Typography>
                    <strong>Reviewer: </strong>
                    <a href={`mailto:${r.reviewer.email}`}>{r.reviewer.name}</a>
                  </Typography>
                  <Typography>
                    <strong>Reviewed: </strong>
                    <a href={`mailto:${r.reviewed.email}`}>{r.reviewed.name}</a>
                  </Typography>
                  <Typography>
                    <strong>Rating: </strong>
                    {r.rating}
                  </Typography>
                  <Typography>
                    <strong>Review Content: </strong>
                    {r.reviewText}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Tooltip title='Approve this review' arrow>
                        <Button
                          color='primary'
                          variant='contained'
                          onClick={async () => await updateReview(r.id, { approved: true })}
                        >
                          Approve
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title='Delete this review' arrow>
                        <Button
                          color='primary'
                          variant='outlined'
                          onClick={async () => await deleteReview(r.id)}
                        >
                          Delete
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box py={1}>
                <Divider />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default PendingReviews;
