import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { theme } from 'themes';
import { useAuth } from 'providers';
import { checkConsultationExists, createConsultationRequest } from 'services/firebase/consultation';
import { useStyles } from './style';
import { useCallback } from 'react';
import PhoneInput from 'components/PhoneInput';
import { isValidPhoneNumber } from 'react-phone-number-input';

export default function RequestConsultation({ onClose, tutor }) {
  const classes = useStyles();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const { user } = useAuth();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleChange = useCallback((e) => setInputValue(e.target.value), []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const phoneField = e.target.phone_number;
    const phoneNumber = phoneField?.value?.trim();

    if (!isValidPhoneNumber(phoneNumber)) {
      return phoneField?.focus();
    }

    if (inputValue.length < 50) {
      return document.querySelector('textarea[name=intro]')?.focus();
    }

    setLoading(true);
    try {
      // check if this consultation was already requested
      if (await checkConsultationExists(tutor.id, user.id)) {
        throw new Error(`You have already requested a consultation with ${tutor.first_name}!`);
      }

      const consultationDetails = {
        createdAt: new Date(),
        intro: e.target.intro?.value,
        status: 'new',
        student: {
          id: user.id,
          email: user.email,
          name: user.name,
          phone: phoneNumber,
        },
        tutor: {
          id: tutor.id,
          email: tutor.email,
          name: tutor.name,
        },
      };

      await createConsultationRequest(consultationDetails);
      setSubmitted(true);
    } catch (error) {
      const errorMessage = 'Oops! ' + error.message;
      setError(errorMessage);
    }

    setLoading(false);
  };

  return submitted ? (
    <Box p={2}>
      <Grid container direction='column' spacing={4} alignItems='center'>
        <Grid item>
          <Icon className={classes.successIcon}>{'mail_outlined_icon'}</Icon>
        </Grid>
        <Grid item>
          <Box textAlign='center'>
            <Typography variant='h5'>
              Your consultation request was sent to {tutor.first_name}!
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Button variant='outlined' color='primary' onClick={onClose}>
            Close
          </Button>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <>
      <DialogTitle
        style={{ textAlign: 'center', borderBottom: `1px solid ${theme.palette.divider}` }}
      >
        Request a free 15 minutes consultation with {tutor.first_name}
      </DialogTitle>
      <DialogContent style={{ padding: 16 }}>
        <form onSubmit={handleSubmit} id='request-consultation'>
          <Box py={2}>
            <Box pb={2}>
              <PhoneInput label='Phone' required defaultValue={user.meta.phone_number?.[0] || ''} />
            </Box>
            <TextField
              error={inputValue.length < 50}
              helperText={
                inputValue.length < 50 ? (
                  <em>*Request description must contain at least 50 characters</em>
                ) : null
              }
              onChange={handleChange}
              value={inputValue}
              name='intro'
              variant='outlined'
              fullWidth
              multiline
              minRows={10}
              required
              placeholder={`Introduce yourself to ${tutor?.first_name} and describe what you're looking for!
If you have a preferred time and date, please let the tutor know.`}
            />
          </Box>
        </form>
      </DialogContent>
      <Divider />
      {error && (
        <Box p={2} textAlign='center'>
          <Typography color='error'>{error}</Typography>
        </Box>
      )}
      <DialogActions>
        <Button
          onClick={onClose}
          variant='outlined'
          color='primary'
          fullWidth={xs}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          form='request-consultation'
          variant='contained'
          color='primary'
          type='submit'
          endIcon={<Icon>{'send'}</Icon>}
          fullWidth={xs}
          disabled={loading}
        >
          {loading && (
            <CircularProgress
              size={24}
              style={{ position: 'absolute', transform: 'translateX(-50)' }}
            />
          )}
          Send request
        </Button>
      </DialogActions>
    </>
  );
}
