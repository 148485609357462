import React, { useState } from 'react';
import { db } from 'config/firebaseConfig';
import { collection, orderBy, query } from 'firebase/firestore';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { Box, Typography } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import PageLayout from 'components/PageLayout';
import { useMemo } from 'react';
import { getDateFromObject, prettifyDateTime } from 'utils/date';
import { APP_TITLE, POUND_SYMBOL } from 'enums';
import CustomLink from 'components/CustomLink';
import { computeEarning } from 'utils/earnings';

const q = query(collection(db, 'bookings'), orderBy('createdAt', 'desc'));
const columns = [
  {
    field: 'id',
    headerName: 'BID',
    flex: true,
    minWidth: 200,
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    type: 'dateTime',
    flex: true,
    minWidth: 200,
    renderCell: ({ value }) => prettifyDateTime(value),
  },
  {
    field: 'subject',
    headerName: 'Subject',
    flex: true,
    minWidth: 200,
  },
  {
    field: 'level',
    headerName: 'Level',
    renderCell: ({ value }) => value || '-',
    flex: true,
    minWidth: 150,
  },
  {
    field: 'selectedDate',
    headerName: 'Selected Time Slot',
    valueGetter: ({ value, row }) => {
      const selectedDate = value;
      selectedDate.setHours(row.startTime);
      return selectedDate;
    },
    renderCell: ({ value }) => prettifyDateTime(value),
    type: 'dateTime',
    flex: true,
    minWidth: 200,
  },
  {
    field: 'duration',
    headerName: 'Duration',
    renderCell: ({ value }) => `${value} hour${value > 1 ? 's' : ''}`,
    flex: true,
    minWidth: 150,
  },
  {
    field: 'groupClass',
    headerName: 'Lesson Type',
    valueGetter: ({ value }) => (value ? 'Group Class' : 'One-to-one'),
    flex: true,
    minWidth: 200,
  },
  {
    field: 'invitedUsers',
    headerName: 'Friends Invited',
    valueGetter: ({ value, row }) => (row.groupClass ? value?.length : ''),
    flex: true,
    minWidth: 200,
  },
  {
    field: 'inPerson',
    headerName: 'In Person',
    valueGetter: ({ value }) => (value ? 'Yes' : 'No'),
    flex: true,
    minWidth: 150,
  },
  {
    field: 'tutor',
    headerName: 'Tutor',
    valueGetter: ({ value }) => value.name,
    renderCell: ({ value, row }) => (
      <CustomLink href={`mailto:${row.tutor.email}`}>{value}</CustomLink>
    ),
    flex: true,
    minWidth: 200,
  },
  {
    field: 'tutorRatePerHour',
    headerName: 'Tutor Rate/Hour',
    valueGetter: ({ row }) => row.tutor.rate,
    renderCell: ({ value }) => (
      <>
        {POUND_SYMBOL}
        {value}
      </>
    ),
    flex: true,
    minWidth: 180,
  },
  {
    field: 'tutorHoursTaught',
    headerName: 'Tutor Hours Taught',
    valueGetter: ({ row }) => row.tutor.hoursTaught,
    flex: true,
    minWidth: 200,
  },
  {
    field: 'tutorEarning',
    headerName: 'Tutor Earnings',
    valueGetter: ({ row }) =>
      row.groupClass
        ? row.tutor.rate
        : computeEarning(row.paymentDetails.amount / 100, row.tutor.hoursTaught < 250 ? 25 : 20),
    renderCell: ({ value }) => (
      <>
        {POUND_SYMBOL}
        {value}
      </>
    ),
    flex: true,
    minWidth: 180,
  },

  {
    field: 'student',
    headerName: 'Booked By',
    valueGetter: ({ value }) => value.name,
    renderCell: ({ value, row }) => (
      <CustomLink href={`mailto:${row.student.email}`}>{value}</CustomLink>
    ),
    flex: true,
    minWidth: 200,
  },
  {
    field: 'paymentDetails',
    headerName: 'Total Paid',
    valueGetter: ({ value }) => value.amount / 100,
    renderCell: ({ value }) => (
      <>
        {POUND_SYMBOL}
        {value}
      </>
    ),
    flex: true,
    minWidth: 170,
  },
  {
    field: 'platformProfit',
    headerName: `${APP_TITLE} Profit`,
    valueGetter: ({ row }) =>
      row.groupClass
        ? row.paymentDetails.amount / 100 - row.tutor.rate
        : (row.paymentDetails.amount / 100) * (row.tutor.hoursTaught < 250 ? 0.25 : 0.2),
    renderCell: ({ value }) => (
      <>
        {POUND_SYMBOL}
        {value}
      </>
    ),
    flex: true,
    minWidth: 250,
  },
];

function BookingHistory() {
  const [pageSize, setPageSize] = useState(5);
  const [bookings = [], loadingBookings] = useCollectionDataOnce(q, { idField: 'id' });
  const mappedBookings = useMemo(
    () =>
      bookings.map((b) => ({
        ...b,
        createdAt: getDateFromObject(b.createdAt),
        selectedDate: getDateFromObject(b.selectedDate),
      })),
    [bookings]
  );

  return (
    <PageLayout>
      <Box textAlign='center' pb={10}>
        <Typography variant='h2'>Booking History</Typography>
      </Box>
      <DataGrid
        autoHeight
        columns={columns}
        rows={mappedBookings}
        loading={loadingBookings}
        rowsPerPageOptions={[5, 10, 20]}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        components={{ Toolbar: GridToolbar }}
        checkboxSelection
      />
    </PageLayout>
  );
}

export default BookingHistory;
