import React from 'react';
import { Box, Container } from '@material-ui/core';

function PageLayout({ children, ...props }) {
  return (
    <Box pt={18} pb={10} {...props}>
      <Container>{children}</Container>
    </Box>
  );
}

export default PageLayout;
