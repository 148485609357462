import React, { useState } from 'react';
import {
  Box,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Switch,
  Button,
  Icon,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import { referencesRef, updateReference } from 'services/firebase/reference';
import { routes } from 'routes';
import { Redirect } from 'react-router-dom';
import { useStyles } from './style';
import { APP_TITLE, WP_SITE_URL } from 'enums';
import { useAuth } from 'providers';
import { useMemo } from 'react';
import { getUserRole } from 'utils/user';
import LoadingButton from 'components/LoadingButton';
import CustomLink from 'components/CustomLink';

function ReferenceFill() {
  const classes = useStyles();
  const { id } = useParams();
  const { user } = useAuth();
  const [period, setPeriod] = useState('0-1');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [approved, setApproved] = useState(false);
  const [error, setError] = useState(null);
  const [concerns, setConcerns] = useState(false);
  const [dbsRaisedIssues, setDBSRaisedIssues] = useState(false);
  const [childrenConcern, setChildrenConcerns] = useState(false);
  const [subjectOfInvestigation, setSubjectOfInvestigation] = useState(false);
  const [disciplinaryAction, setDisciplinaryAction] = useState(false);
  const [reEmploy, setReEmploy] = useState(false);
  const [recommendCandidate, setRecommendCandidate] = useState('as-exceptional');
  const [reference, loading] = useDocumentDataOnce(doc(referencesRef, String(id)), {
    idField: 'id',
  });

  const isAdmin = useMemo(() => (user ? getUserRole(user) === 'admin' : false), [user]);
  const readOnly = useMemo(
    () => isAdmin && ['pending_approval', 'approved'].includes(reference?.status),
    [isAdmin, reference?.status]
  );

  const handlePeriodChange = (e, value) => {
    setPeriod(value);
  };

  const handleRecommendCandidateChange = (e, value) => {
    setRecommendCandidate(value);
  };

  const handleSubmitRequest = async (e) => {
    e.preventDefault();
    // ref details
    const firstName = e.target.firstName.value;
    const lastName = e.target.lastName.value;
    const jobTitle = e.target.jobTitle.value;
    // tutor details
    const workedFrom = e.target.workedFrom.value;
    const workedTo = e.target.workedTo.value;
    const position = e.target.position.value;
    const responsibilities = e.target.responsibilities.value;
    const leavingReason = e.target.leavingReason.value;
    const capacity = e.target.capacity.value;
    const suitability = e.target.suitability.value;
    const performanceConcernsComment = e.target.performanceConcernsComment?.value;
    const childrenConcernsComment = e.target.childrenConcernsComment?.value;
    const subjectOfInvestigationComment = e.target.subjectOfInvestigationComment?.value;
    const disciplinaryActionComment = e.target.disciplinaryActionComment?.value;
    const teachingQuality = e.target.teachingQuality.value;
    const preparation = e.target.preparation.value;
    const subjectCurriculum = e.target.subjectCurriculum.value;
    const studentsProgress = e.target.studentsProgress.value;
    const disciplineSkills = e.target.disciplineSkills.value;
    const supportStudentsAbility = e.target.supportStudentsAbility.value;
    const resourcesOrganization = e.target.resourcesOrganization.value;
    const eagerness = e.target.eagerness.value;
    const effectiveTeaching = e.target.effectiveTeaching.value;
    const motivateStudents = e.target.motivateStudents.value;
    const appropriatelyDressed = e.target.appropriatelyDressed.value;
    const qualityTeachingMethods = e.target.qualityTeachingMethods.value;
    const hardWorking = e.target.hardWorking.value;
    const rationalDecisions = e.target.rationalDecisions.value;
    const recogniseWeaknessAndStrengths = e.target.recogniseWeaknessAndStrengths.value;
    const emotionalStability = e.target.emotionalStability.value;
    const approachable = e.target.approachable.value;
    const teamPlayer = e.target.teamPlayer.value;
    const handlesStress = e.target.handlesStress.value;
    const providesSupport = e.target.providesSupport.value;
    const friendly = e.target.friendly.value;
    const tactful = e.target.tactful.value;
    const trustWorthy = e.target.trustWorthy.value;
    const skillful = e.target.skillful.value;
    const timeManagement = e.target.timeManagement.value;
    const attendance = e.target.attendance.value;
    const punctuality = e.target.punctuality.value;
    const overallRating = e.target.overallRating.value;
    const reEmployComment = e.target.reEmployComment?.value;

    const formData = {
      reference: {
        firstName,
        lastName,
        jobTitle,
      },
      tutor: {
        workedFrom,
        workedTo,
        position,
        responsibilities,
        leavingReason,
        eagerness,
        period,
        capacity,
        suitability,
        performanceConcernsComment,
        dbsRaisedIssues,
        childrenConcernsComment,
        subjectOfInvestigationComment,
        disciplinaryActionComment,
        teachingQuality,
        preparation,
        subjectCurriculum,
        studentsProgress,
        disciplineSkills,
        supportStudentsAbility,
        resourcesOrganization,
        effectiveTeaching,
        motivateStudents,
        appropriatelyDressed,
        qualityTeachingMethods,
        hardWorking,
        rationalDecisions,
        recogniseWeaknessAndStrengths,
        emotionalStability,
        approachable,
        teamPlayer,
        handlesStress,
        providesSupport,
        friendly,
        tactful,
        trustWorthy,
        skillful,
        timeManagement,
        attendance,
        punctuality,
        overallRating,
        reEmployComment,
        recommendCandidate,
      },
    };

    Object.keys(formData.tutor).forEach((key) => {
      if (typeof formData.tutor[key] === 'undefined') {
        formData.tutor[key] = '';
      }
    });

    if (new Date(workedFrom) > new Date(workedTo)) {
      document.querySelector('[name=workedFrom]')?.focus();
      return setError('Invalid dates: "Worked From" and "Worked To"');
    }

    if (new Date(workedTo) > new Date()) {
      document.querySelector('[name=workedTo]')?.focus();
      return setError('Invalid date: "Worked To"');
    }

    if (error) {
      setError(null);
    }

    setSubmitting(true);

    try {
      const { id, ...newReferenceData } = {
        ...reference,
        ...formData.reference,
        status: 'pending_approval',
        tutor: { ...reference.tutor, ...formData.tutor },
      };

      await updateReference(id, newReferenceData);
      setSubmitted(true);
    } catch (error) {
      setError(error.message);
    }

    setSubmitting(false);
  };

  const handleApproveReference = async (e) => {
    setSubmitting(true);
    try {
      await updateReference(reference?.id, { status: 'approved' });
      setApproved(true);
    } catch (error) {
      // console.log(error.message);
    }
    setSubmitting(false);
  };

  if (loading) {
    return (
      <Box style={{ display: 'grid', placeContent: 'center', minHeight: '80vh' }}>
        <Container>
          <Typography variant='h6'>Loading reference details... </Typography>
        </Container>
      </Box>
    );
  }

  if (!loading && !reference) {
    return <Redirect to={routes.home} />;
  }

  if (['pending_approval', 'approved'].includes(reference?.status) && !isAdmin) {
    return (
      <Box
        style={{
          textAlign: 'center',
          minHeight: '80vh',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Box>
          <Typography variant='h6' gutterBottom>
            Oops! You are not allowed to access this page.
          </Typography>
          <CustomLink to={user ? routes.home : routes.login}>
            <Button variant='contained' color='primary'>
              {user ? 'Go to Dashboard' : 'Login'}
            </Button>
          </CustomLink>
        </Box>
      </Box>
    );
  }

  if (submitted) {
    return (
      <Box
        style={{
          textAlign: 'center',
          minHeight: '80vh',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Box>
          <Typography variant='h2' gutterBottom>
            Thank you for completing this form!
          </Typography>
          <CustomLink {...(user ? { to: routes.home } : { href: WP_SITE_URL })}>
            <Button variant='contained' color='primary'>
              Go To {user ? 'Dashboard' : APP_TITLE}
            </Button>
          </CustomLink>
        </Box>
      </Box>
    );
  }

  return (
    <Box pb={10} pt={18}>
      <Container>
        <form onSubmit={handleSubmitRequest} className={classes.referenceForm}>
          {reference?.status === 'awaiting_reference' && (
            <>
              <Typography variant='h2'>Hi {reference.firstName},</Typography>
              <Typography variant='h2'>
                Please complete reference for {reference.tutor.name}
              </Typography>
            </>
          )}

          {['pending_approval', 'approved'].includes(reference?.status) && (
            <>
              <Typography variant='h2'>Reference for {reference.tutor.name}</Typography>
              <Typography variant='h2' style={{ textTransform: 'capitalize' }}>
                Status: {approved ? 'Approved' : reference.status.split('_').join(' ')}
              </Typography>
            </>
          )}

          <Box pt={10}>
            <Box pb={3}>
              <Typography variant='h6'>{readOnly ? 'Reference' : 'Your'} Details</Typography>
              <Divider />
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name='firstName'
                  variant='outlined'
                  fullWidth
                  defaultValue={reference.firstName}
                  label='First Name'
                  required
                  inputProps={{ readOnly }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name='lastName'
                  variant='outlined'
                  fullWidth
                  defaultValue={reference.lastName}
                  label='Last Name'
                  required
                  inputProps={{ readOnly }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  fullWidth
                  label='Job Title'
                  name='jobTitle'
                  required
                  defaultValue={reference.jobTitle}
                  inputProps={{ readOnly }}
                />
              </Grid>
            </Grid>

            <Box pt={10} pb={2}>
              <Typography variant='h6'>About {reference.tutor.name}</Typography>
              <Divider />
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  Please confirm the dates the candidate worked for you
                </Typography>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6} container wrap='nowrap' alignItems='center'>
                    <TextField
                      label='From'
                      variant='outlined'
                      type='date'
                      name='workedFrom'
                      fullWidth
                      defaultValue={reference?.tutor?.workedFrom}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ readOnly }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} container wrap='nowrap' alignItems='center'>
                    <TextField
                      label='To'
                      variant='outlined'
                      type='date'
                      name='workedTo'
                      fullWidth
                      defaultValue={reference?.tutor?.workedTo}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ readOnly }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography>
                  The applicant's position if employed or in training with your organisation
                </Typography>
                <TextField
                  variant='outlined'
                  fullWidth
                  name='position'
                  inputProps={{ readOnly }}
                  defaultValue={reference?.tutor?.position || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>The main duties and responsibilities of the post</Typography>
                <TextField
                  variant='outlined'
                  fullWidth
                  name='responsibilities'
                  inputProps={{ readOnly }}
                  defaultValue={reference?.tutor?.responsibilities || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  The applicant's reason for leaving your employment (if applicable)?
                </Typography>
                <TextField
                  variant='outlined'
                  fullWidth
                  name='leavingReason'
                  inputProps={{ readOnly }}
                  defaultValue={reference?.tutor?.leavingReason || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>How long have you known {reference.tutor.name}?</Typography>
                <RadioGroup
                  aria-label='period'
                  value={reference?.tutor?.period || period}
                  name='howLong'
                  onChange={readOnly ? undefined : handlePeriodChange}
                  readOnly={readOnly}
                >
                  <FormControlLabel value='0-1' control={<Radio />} label='Less than a year' />
                  <FormControlLabel value='1-2' control={<Radio />} label='1-2 years' />
                  <FormControlLabel value='2-5' control={<Radio />} label='2-5 years' />
                  <FormControlLabel value='5+' control={<Radio />} label='5+ years' />
                </RadioGroup>
              </Grid>

              <Grid item xs={12}>
                <Typography>In what capacity to you know him/her?</Typography>
                <TextField
                  variant='outlined'
                  fullWidth
                  name='capacity'
                  inputProps={{ readOnly }}
                  defaultValue={reference?.tutor?.capacity || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Please comment on the applicant's suitability for this post. Please describe any
                  strengths and development needs.
                </Typography>
                <TextField
                  multiline
                  minRows={5}
                  variant='outlined'
                  fullWidth
                  name='suitability'
                  inputProps={{ readOnly }}
                  defaultValue={reference?.tutor?.suitability || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Are there any concerns regarding the applicant's performance of his/her duties?
                </Typography>
                <FormControlLabel
                  label={
                    concerns || (readOnly && reference?.tutor?.performanceConcernsComment)
                      ? 'YES'
                      : 'NO'
                  }
                  control={
                    <Switch
                      checked={
                        readOnly ? Boolean(reference?.tutor?.performanceConcernsComment) : concerns
                      }
                      onChange={readOnly ? undefined : (e, checked) => setConcerns(checked)}
                    />
                  }
                />
                {(concerns || readOnly) && (
                  <TextField
                    placeholder='Please provide details of any areas needing improvement.'
                    multiline
                    minRows={5}
                    variant='outlined'
                    fullWidth
                    name='performanceConcernsComment'
                    required
                    defaultValue={reference?.tutor?.performanceConcernsComment || ''}
                    inputProps={{ readOnly }}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Are you aware of any issues raised on a DBS check for the applicant carried out by
                  your organization?
                </Typography>
                <FormControlLabel
                  label={
                    dbsRaisedIssues || (readOnly && reference?.tutor?.dbsRaisedIssues)
                      ? 'YES'
                      : 'NO'
                  }
                  control={
                    <Switch
                      checked={
                        readOnly ? Boolean(reference?.tutor?.dbsRaisedIssues) : dbsRaisedIssues
                      }
                      onChange={readOnly ? undefined : (e, checked) => setDBSRaisedIssues(checked)}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Typography>
                  Do you have any concerns regarding the applicant's suitability to work with
                  children?
                </Typography>
                <FormControlLabel
                  label={
                    childrenConcern || (readOnly && reference?.tutor?.childrenConcernsComment)
                      ? 'YES'
                      : 'NO'
                  }
                  control={
                    <Switch
                      checked={
                        readOnly
                          ? Boolean(reference?.tutor?.childrenConcernsComment)
                          : childrenConcern
                      }
                      onChange={readOnly ? undefined : (e, checked) => setChildrenConcerns(checked)}
                    />
                  }
                />
                {(childrenConcern || readOnly) && (
                  <TextField
                    placeholder='Please provide details about your concerns.'
                    multiline
                    minRows={5}
                    variant='outlined'
                    fullWidth
                    name='childrenConcernsComment'
                    required
                    defaultValue={reference?.tutor?.performanceConcernsComment || ''}
                    inputProps={{ readOnly }}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  To the best of your knowledge has the applicant ever had an allegation made
                  against them, or been the subject of an investigation, in regard to his/her
                  behaviour towards children?
                </Typography>
                <FormControlLabel
                  label={
                    subjectOfInvestigation ||
                    (readOnly && reference?.tutor?.subjectOfInvestigationComment)
                      ? 'YES'
                      : 'NO'
                  }
                  control={
                    <Switch
                      checked={
                        readOnly
                          ? Boolean(reference?.tutor?.subjectOfInvestigationComment)
                          : subjectOfInvestigation
                      }
                      onChange={
                        readOnly ? undefined : (e, checked) => setSubjectOfInvestigation(checked)
                      }
                    />
                  }
                />
                {(subjectOfInvestigation || readOnly) && (
                  <TextField
                    placeholder='Please give details of the nature and date(s) of the allegation(s), by whom they were investigated, what conclusion was reached as a result of the investigation, whether any action was taken and if so what that was.'
                    multiline
                    minRows={5}
                    variant='outlined'
                    fullWidth
                    name='subjectOfInvestigationComment'
                    required
                    defaultValue={reference?.tutor?.subjectOfInvestigationComment || ''}
                    inputProps={{ readOnly }}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  To the best of your knowledge, has the applicant been subject to any disciplinary
                  action relating to his/her suitability to work with children where penalties or
                  sanctions were imposed but have since expired?
                </Typography>
                <FormControlLabel
                  label={
                    disciplinaryAction || (readOnly && reference?.tutor?.disciplinaryActionComment)
                      ? 'YES'
                      : 'NO'
                  }
                  control={
                    <Switch
                      checked={
                        readOnly
                          ? Boolean(reference?.tutor?.disciplinaryActionComment)
                          : disciplinaryAction
                      }
                      onChange={
                        readOnly ? undefined : (e, checked) => setDisciplinaryAction(checked)
                      }
                    />
                  }
                />
                {(disciplinaryAction || readOnly) && (
                  <TextField
                    placeholder='Please give full details of the nature and date(s) of the misconduct, and the penalty or sanctions that were imposed.'
                    multiline
                    minRows={5}
                    variant='outlined'
                    fullWidth
                    name='disciplinaryActionComment'
                    required
                    defaultValue={reference?.tutor?.disciplinaryActionComment || ''}
                    inputProps={{ readOnly }}
                  />
                )}
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 48 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <em>
                      <Typography>
                        <strong>5=Exceptional (top 5%)</strong>
                      </Typography>

                      <Typography>
                        <strong>4=Excellent</strong>
                      </Typography>

                      <Typography>
                        <strong>3=Good</strong>
                      </Typography>

                      <Typography>
                        <strong>2=Adequate</strong>
                      </Typography>

                      <Typography>
                        <strong>1=Some weakness</strong>
                      </Typography>
                    </em>
                    <Divider />
                  </Grid>

                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>Quality of teaching.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='teachingQuality'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.teachingQuality || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>Preparation and marking.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='preparation'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.preparation || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>Subject curriculum knowledge.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='subjectCurriculum'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.subjectCurriculum || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>Progress made by students' in applicant's classes.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='studentsProgress'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.studentsProgress || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Discipline skills including ability to cope well with even with challenging
                        behaviour
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='disciplineSkills'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.disciplineSkills || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Ability to support students in need of additional support e.g. EAL; SEN; G&T
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='supportStudentsAbility'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.supportStudentsAbility || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>Organisation of resources.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='resourcesOrganization'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.resourcesOrganization || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>Eagerness to refine practice, learn and reflect.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='eagerness'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.eagerness || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Use ICT of as an effective teaching and learning tool.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='effectiveTeaching'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.effectiveTeaching || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Ability to motivate and gain the respect of disaffected students.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='motivateStudents'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.motivateStudents || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>Appropriately dressed and groomed for work.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='appropriatelyDressed'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.appropriatelyDressed || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Produces quality of schemes of work and teaching resources.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='qualityTeachingMethods'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.qualityTeachingMethods || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>Is exceptionally hard-working and tenacious.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='hardWorking'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.hardWorking || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Displays excellent judgement and is able to make rational decisions.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='rationalDecisions'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.rationalDecisions || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Is able to recognise own weaknesses as well as strengths.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='recogniseWeaknessAndStrengths'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.recogniseWeaknessAndStrengths || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Emotional stability, able to deal with pressure and things going wrong.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='emotionalStability'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.emotionalStability || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Is approachable and will endeavour to help colleagues.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='approachable'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.approachable || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>Works well with others. Easy to work with.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='teamPlayer'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.teamPlayer || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Never grumbles even when circumstances are demanding or stressful.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='handlesStress'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.handlesStress || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Is concerned about students' well-being and provides support.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='providesSupport'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.providesSupport || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>Demeanour; cheerful, friendly and positive.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='friendly'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.friendly || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Ability to speak tactfully but honestly with parents and students.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='tactful'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.tactful || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>Can be relied upon to get things done.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='trustWorthy'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.trustWorthy || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>Inspirational skills.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='skillful'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.skillful || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>Personal organisation and time management skills. </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='timeManagement'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.timeManagement || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>Attendance.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='attendance'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.attendance || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>Punctuality.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='punctuality'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.punctuality || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Overall, how would you rate this candidate for the post.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        name='overallRating'
                        type='tel'
                        fullWidth
                        defaultValue={reference?.tutor?.overallRating || ''}
                        inputProps={{ maxLength: 1, readOnly }}
                        onKeyDown={(e) =>
                          !['1', '2', '3', '4', '5', 'Backspace'].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} style={{ paddingTop: 48 }}>
                    <Typography>
                      Would you re-employ the applicant if you happened to have a similar post
                      available?
                    </Typography>
                    <FormControlLabel
                      label={
                        reEmploy || (readOnly && !reference?.tutor?.reEmployComment) ? 'YES' : 'NO'
                      }
                      control={
                        <Switch
                          checked={readOnly ? !reference?.tutor?.reEmployComment : reEmploy}
                          onChange={readOnly ? undefined : (e, checked) => setReEmploy(checked)}
                        />
                      }
                    />
                    {(!reEmploy || readOnly) && (
                      <TextField
                        placeholder='Please advise.'
                        multiline
                        minRows={5}
                        variant='outlined'
                        fullWidth
                        name='reEmployComment'
                        required
                        defaultValue={reference?.tutor?.reEmployComment || ''}
                        inputProps={{ readOnly }}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Typography>Would you recommend this candidate for the post:</Typography>
                    <RadioGroup
                      aria-label='recommend'
                      value={reference?.tutor?.recommendCandidate || recommendCandidate}
                      name='recommendCandidate'
                      onChange={readOnly ? undefined : handleRecommendCandidateChange}
                      readOnly={readOnly}
                    >
                      <FormControlLabel
                        value='as-exceptional'
                        control={<Radio />}
                        label='As exceptional'
                      />
                      <FormControlLabel
                        value='without-reservation'
                        control={<Radio />}
                        label='Without reservation'
                      />
                      <FormControlLabel
                        value='with-some-reservation'
                        control={<Radio />}
                        label='With some reservation'
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} hidden={!Boolean(error)}>
                <Typography color='error'>{error}</Typography>
              </Grid>

              {['pending_approval', 'approved'].includes(reference?.status) && isAdmin ? (
                <Grid item xs={12} container justifyContent='center'>
                  {approved || reference?.status === 'approved' ? (
                    <Grid item style={{ textAlign: 'center' }}>
                      <br />
                      <Typography gutterBottom variant='h6'>
                        Approved!
                      </Typography>
                      <br />

                      <CustomLink to={routes.home}>
                        <Button type='button' variant='contained' color='primary'>
                          Go to Dashboard
                        </Button>
                      </CustomLink>
                    </Grid>
                  ) : (
                    <LoadingButton
                      size='large'
                      type='button'
                      loading={submitting}
                      onClick={handleApproveReference}
                      startIcon={<Icon>done</Icon>}
                    >
                      Approve reference
                    </LoadingButton>
                  )}
                </Grid>
              ) : (
                <Grid item xs={12} container justifyContent='center'>
                  <LoadingButton
                    size='large'
                    type='submit'
                    loading={submitting}
                    startIcon={<Icon>publish</Icon>}
                  >
                    Submit your reference
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          </Box>
        </form>
      </Container>
    </Box>
  );
}

export default ReferenceFill;
