import React from 'react';
import {
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  Icon,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Rating, Skeleton } from '@material-ui/lab';
import { POUND_SYMBOL } from 'enums';
import { routes } from 'routes';
import { useStyles } from './style';
import { theme } from 'themes';
import CustomLink from 'components/CustomLink';
import { useFilters } from 'providers';
import haversineDistance from 'haversine-distance';
import { getMiles } from 'helpers/distance';

function SearchResults({ loading, tutors, showTravelDistance }) {
  const classes = useStyles();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const { filters } = useFilters();

  return (
    <Grid container spacing={xs ? 4 : 2}>
      {loading &&
        Array.from(Array(4).keys()).map((k) => (
          <Grid item key={k} xs={12} sm={6} md={4} lg={3}>
            <Card variant='outlined'>
              <Skeleton height={300} variant='rect' />
              <Box p={2} height='100%'>
                <Grid container justifyContent='space-between'>
                  <Grid item xs={6}>
                    <Skeleton variant='text' height={40} />
                    <Skeleton variant='text' />
                  </Grid>
                </Grid>
                <Box pt={2}>
                  <Grid container direction='column' spacing={1}>
                    <Grid item xs={8}>
                      <Skeleton variant='rect' height={20} />
                    </Grid>
                    <Grid item>
                      <Skeleton variant='rect' height={100} />
                    </Grid>
                    <Grid item xs={6}>
                      <Skeleton variant='rect' height={20} />
                    </Grid>
                    <Grid item>
                      <Skeleton variant='rect' height={37} />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}

      {!loading &&
        tutors.map((tutor) => {
          let tutorPosition = null;
          let milesAway = null;

          if (showTravelDistance && filters?.address) {
            try {
              tutorPosition = JSON.parse(tutor.meta.tutor_locations[0]).location.position;
            } catch (error) {}

            if (tutorPosition) {
              try {
                milesAway = getMiles(
                  haversineDistance(tutorPosition, filters?.address?.position)
                ).toFixed(1);
              } catch (error) {}
            }
          }

          return (
            <Grid item key={tutor.id} xs={12} sm={6} md={4} lg={3}>
              <CustomLink to={`${routes.tutors}/${tutor.id}`} style={{ textDecoration: 'none' }}>
                <Card variant='outlined' elevation={2} className={classes.card}>
                  <Box>
                    <CardMedia
                      component='img'
                      image={
                        tutor.meta.photo_url[0] ||
                        'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'
                      }
                      className={classes.cardMedia}
                      alt='Tutor'
                    />
                    <Box p={2} className='card-text-content'>
                      <Box textAlign='right'>
                        <Typography style={{ fontWeight: 500 }}>
                          {POUND_SYMBOL}
                          {(Number(tutor.meta.hours_rate[0]) || 0).toFixed(2)}
                          <span className={classes.perHour}>/hr</span>
                        </Typography>
                      </Box>

                      <Typography variant='h5' gutterBottom className={classes.headline}>
                        {tutor.name}
                      </Typography>

                      <Box className={classes.contentWrapper}>
                        <Typography variant='body2' className={classes.headline} gutterBottom>
                          <strong>{tutor.meta.profile_headline}</strong>
                        </Typography>

                        <Typography className={classes.bio} variant='body2'>
                          {tutor.meta.profile_bio}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box px={2} pb={2}>
                    {milesAway && (
                      <Box>
                        <Typography variant='body2' component='div'>
                          <Icon
                            color='action'
                            fontSize='small'
                            style={{ verticalAlign: 'text-bottom', marginRight: 4 }}
                          >
                            my_location_icon
                          </Icon>
                          {milesAway} miles away
                        </Typography>
                      </Box>
                    )}

                    <Box display='flex' alignItems='center' pb={2}>
                      <Rating
                        readOnly
                        size='small'
                        value={Math.round(
                          tutor.reviews.map((r) => r.rating).reduce((acc, curr) => acc + curr, 0) /
                            tutor.reviews.length
                        )}
                      />
                      &nbsp;
                      <Typography variant='body2'>({tutor.reviews.length})</Typography>
                    </Box>

                    <Button
                      fullWidth
                      variant='contained'
                      color='primary'
                      endIcon={<Icon>{'arrow_right_alt'}</Icon>}
                    >
                      Find out more
                    </Button>
                  </Box>
                </Card>
              </CustomLink>
            </Grid>
          );
        })}
    </Grid>
  );
}

export default React.memo(SearchResults);
