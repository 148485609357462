import {
  addDoc,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  deleteDoc,
} from '@firebase/firestore';
import { db } from 'config/firebaseConfig';

export const createConsultationRequest = (consultationDetails) =>
  addDoc(collection(db, 'consultations'), consultationDetails);

export const updateConsultationRequest = (consultationId, consultationDetails) =>
  updateDoc(doc(db, 'consultations', consultationId), consultationDetails);

export const checkConsultationExists = (tutorId, studentId) =>
  getDocs(query(collection(db, 'consultations'), where('student.id', '==', studentId))).then(
    (querySnapshot) => querySnapshot.docs.some((doc) => doc.data()?.tutor?.id === tutorId)
  );

export const deleteConsultationRequest = (consultationId) =>
  deleteDoc(doc(db, 'consultations', consultationId));
