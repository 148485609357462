export const WP_SITE_URL = 'https://booksmarttutors.co.uk';
export const WP_API_URL = WP_SITE_URL + '/wp-json/wp/v2';
export const WP_PASSWORD_RESET_WITH_CODE = WP_SITE_URL + '/wp-json/bdpwr/v1/reset-password';
export const WP_SET_NEW_PASSWORD = WP_SITE_URL + '/wp-json/bdpwr/v1/set-password';
export const WP_VALIDATE_PASSWORD_RESET_CODE = WP_SITE_URL + '/wp-json/bdpwr/v1/validate-code';
export const POUND_SYMBOL = <>&#163;</>;
export const API_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:5001/booksmart-tutors/us-central1/api'
    : 'https://us-central1-booksmart-tutors.cloudfunctions.net/api';
export const LAST_SEARCH = 'last-search';
export const TOKEN = 'wp-auth-token';
export const requestTypes = {
  consultation: 'consultation',
  lesson: 'lesson',
};
export const APP_TITLE = 'Booksmart Tutors';
export const UUID4 = '6121fe73-ca1e-4ed7-9f50-73d757ccef96';
export const PHOTO_PLACEHOLDER =
  'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png';
export const MIN_RATE = 15;
export const ROLES = {
  STUDENT: 'student',
  TUTOR: 'tutor',
  ADMIN: 'admin',
  VISITOR: 'visitor',
};
export const HERE_AUTOCOMPLETE_API_URL = 'https://autocomplete.search.hereapi.com/v1/autocomplete';
export const HERE_LOOKUP_API_URL = 'https://lookup.search.hereapi.com/v1/lookup';
export const HERE_GEOCODE_URL = 'https://geocode.search.hereapi.com/v1/geocode';
