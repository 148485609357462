import { db } from 'config/firebaseConfig';
import { addDoc, collection, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { getPathFromUrl } from './storage';

export const createFileToApproveDoc = (uploadedBy, tag, downloadUrl) =>
  addDoc(collection(db, 'filesToApprove'), {
    uploadedBy,
    tag,
    downloadUrl,
    path: getPathFromUrl(downloadUrl),
    status: 'pending_approval',
    createdAt: new Date(),
  });

export const updateFileToApproveDoc = (docId, newData) =>
  updateDoc(doc(db, 'filesToApprove', docId), newData);

export const deleteFileToApprove = (docId) => deleteDoc(doc(db, 'filesToApprove', docId));
