import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  description: {
    whiteSpace: 'pre-wrap',
    '& textarea::placeholder': {
      fontStyle: 'italic',
    },
  },
}));
