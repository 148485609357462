import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dataGrid: {
    '& .MuiDataGrid-columnsContainer': {
      borderRadius: theme.spacing(0.5, 0.5, 0, 0),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '& .MuiDataGrid-columnHeader:last-of-type': {
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
      },
      '& button': {
        color: theme.palette.primary.contrastText,
      },
      '& .MuiDataGrid-columnHeader': {
        '&.hidden': {
          display: 'none',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
          padding: 0,
        },
      },
    },
  },
}));
