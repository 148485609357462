import React, { useState, useCallback } from 'react';
import {
  Button,
  Grid,
  Divider,
  useMediaQuery,
  Box,
  Typography,
  Chip,
  IconButton,
  Icon,
  Dialog,
  TextField,
  CircularProgress,
  Tooltip,
  MenuItem,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useStyles } from './style';
import { theme } from 'themes';
import { useConfirmation } from 'providers';
import { deleteSubject, subjectsRef, updateSubject } from 'services/firebase/subjects';
import { orderBy, query, where } from '@firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { UUID4 } from 'enums';

const DialogContent = React.memo(({ subject, onCancel, onUpdate }) => {
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const [submitting, setSubmitting] = useState(false);
  const [levels, setLevels] = useState(subject.levels);
  const [error, setError] = useState(null);

  const handleEditSubject = async (e) => {
    e.preventDefault();
    setError(null);
    setSubmitting(true);
    const name = e.target.subjectName.value;
    const newData = { levels };

    if (name !== subject.name) {
      Object.assign(newData, { name });
    }

    try {
      await onUpdate(subject.id, newData);
      return onCancel();
    } catch (error) {
      setError(error?.message);
    }
    setSubmitting(false);
  };

  const handleLevelsChange = useCallback((e, value) => setLevels(value), []);

  return (
    <form onSubmit={handleEditSubject}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            variant='outlined'
            label='Subject Name'
            name='subjectName'
            defaultValue={subject.name}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            onChange={handleLevelsChange}
            value={levels}
            fullWidth
            multiple
            id='levels'
            options={[]}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  color='primary'
                  variant='outlined'
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                placeholder='Type and press Enter'
                label='Subject Levels'
                name='levels'
              />
            )}
          />
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Box textAlign='center'>
              <Typography color='error'>{error}</Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container justifyContent='flex-end' spacing={2}>
            <Grid item xs={xs}>
              <Button
                fullWidth
                variant='outlined'
                color='primary'
                disabled={submitting}
                onClick={onCancel}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={xs}>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                type='submit'
                disabled={submitting}
              >
                Save
                {submitting && <CircularProgress size={24} style={{ position: 'absolute' }} />}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
});

function SubjectsEditor({ categories = [] }) {
  const confirm = useConfirmation();
  const classes = useStyles();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [editingSubject, setEditingSubject] = useState(null);
  const [subjects = [], loading] = useCollectionData(
    query(subjectsRef, where('category', '==', selectedCategory || UUID4), orderBy('name', 'asc')),
    {
      idField: 'id',
    }
  );

  const handleDeleteSubject = async (subject) => {
    const confirmed = await confirm(
      <>
        <strong>{subject.name}</strong> will be deleted.
      </>
    );
    if (confirmed) {
      deleteSubject(subject.id);
    }
  };
  const handleUpdateSubject = useCallback(
    async (subjectId, newData) => {
      if (
        newData.name &&
        subjects.some((s) => s.category === selectedCategory && s.name === newData.name)
      ) {
        throw new Error('Subject name already exists under this category');
      }

      await updateSubject(subjectId, newData);
    },
    [selectedCategory, subjects]
  );

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={12} md={4}>
        <Grid container className={classes.categoriesContainer}>
          {categories.map(({ id }) => (
            <MenuItem
              key={id}
              className={classes.categoryItem}
              selected={selectedCategory === id}
              onClick={() => selectedCategory !== id && setSelectedCategory(id)}
            >
              {id}
            </MenuItem>
          ))}
        </Grid>
      </Grid>
      {!sm && (
        <Grid item>
          <Divider orientation='vertical' />
        </Grid>
      )}
      <Grid item xs>
        {!subjects.length && (
          <Box
            style={{
              display: 'grid',
              placeItems: 'center',
              textAlign: 'center',
              height: '100%',
              minHeight: 600,
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Typography color='textSecondary' variant='h6'>
                {!selectedCategory
                  ? 'Please select a category'
                  : 'There are no subjects under this category'}
              </Typography>
            )}
          </Box>
        )}

        {Boolean(subjects.length) && (
          <Grid container className={classes.subjectsContainer}>
            {subjects.map((s, i) => (
              <Grid item key={`subject_${i}`} xs={12}>
                {i > 0 && (
                  <Box py={2}>
                    <Divider />
                  </Box>
                )}
                <Grid container alignItems='center' justifyContent='space-between' wrap='nowrap'>
                  <Grid item xs>
                    <Typography variant='h6'>{s.name}</Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip title={`Edit ${s.name}`} arrow>
                      <IconButton onClick={() => setEditingSubject(s)}>
                        <Icon>edit</Icon>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={`Delete ${s.name}`} arrow>
                      <IconButton
                        style={{ marginLeft: theme.spacing(1) }}
                        onClick={() => handleDeleteSubject(s)}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                {!!s.levels?.length && (
                  <Box margin={-0.5} pt={2}>
                    {s.levels?.map((level, i) => (
                      <Chip
                        key={`level_${i}`}
                        label={<Typography>{level}</Typography>}
                        className={classes.levelItem}
                        variant='outlined'
                      />
                    ))}
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
      {Boolean(editingSubject) && (
        <Dialog open={true} fullWidth maxWidth='md'>
          <Box p={2}>
            <Box textAlign='center'>
              <Typography variant='h6' gutterBottom>
                Edit Subject
              </Typography>
            </Box>
            <Box pb={2}>
              <Divider />
            </Box>
            <DialogContent
              onCancel={() => setEditingSubject(null)}
              subject={editingSubject}
              onUpdate={handleUpdateSubject}
            />
          </Box>
        </Dialog>
      )}
    </Grid>
  );
}

export default React.memo(SubjectsEditor);
