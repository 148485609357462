import axios from 'axios';
import { HERE_AUTOCOMPLETE_API_URL, HERE_GEOCODE_URL, HERE_LOOKUP_API_URL } from 'enums';

export const getAutocompleteResults = (queryText = '', limit = 5) =>
  axios.get(
    `${HERE_AUTOCOMPLETE_API_URL}?q=${queryText}&limit=${limit}&apiKey=${process.env.REACT_APP_HERE_API_KEY}`
  );

export const getLocation = (locationId) =>
  axios.get(`${HERE_LOOKUP_API_URL}?id=${locationId}&apiKey=${process.env.REACT_APP_HERE_API_KEY}`);

export const getAddressByPostcode = (zip) =>
  axios.get(
    `${HERE_GEOCODE_URL}?qq=postalCode=${zip}&limit=1&apiKey=${process.env.REACT_APP_HERE_API_KEY}`
  );
