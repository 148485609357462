import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  logo: {
    width: '70%',
    maxWidth: 300,
  },
  container: {
    position: 'fixed',
    display: 'grid',
    placeItems: 'center',
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    '& .MuiButton-root': {
      height: '100%',
      padding: theme.spacing(6, 2),
      transition: theme.transitions.create('transform'),
      '& .MuiButton-label': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        '& .MuiIcon-root': {
          marginBottom: theme.spacing(2),
          fontSize: theme.typography.h2.fontSize,
        },
      },
      '&:hover': {
        transform: 'scale(1.1)',
        [theme.breakpoints.down('sm')]: {
          transform: 'scale(1.05)',
        },
      },
    },
  },
}));
