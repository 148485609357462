import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { CARD_ELEMENT_OPTIONS, useStyles } from './style';
import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { createLessonPaymentIntent } from 'services/firebase/createLessonPaymentIntent';
import { useAuth, useFilters } from 'providers';
import { COLORS, theme } from 'themes';
import { LAST_SEARCH, POUND_SYMBOL, ROLES, TOKEN } from 'enums';
import { createBooking } from 'services/firebase/bookings';
import { getWPUserByEmail } from 'services/wp/users';
import { getUserRole } from 'utils/user';
import { validateEmail } from 'utils/email';
import { computeEarning } from 'utils/earnings';
import { Autocomplete } from '@material-ui/lab';
import { filterOptions, getOptionLabel, getOptionSelected } from 'utils/subjectAutocomplete';
import securePayments from 'assets/images/secure-stripe-payment-logo.png';
import SquareLoader from 'react-spinners/SquareLoader';
import BookingDetails from 'components/BookingDetails';
import AvailabilityCalendar from 'components/AvailabilityCalendar';
import LoadingButton from 'components/LoadingButton';

const MAX_INVITED_USERS = 3;

const BuddyUp = React.memo(({ setInvitedUsers, invitedUsers, xs, classes }) => {
  const [loadingUser, setLoadingUser] = useState(false);
  const [invitedUserError, setInvitedUserError] = useState(null);

  const handleAddUser = async (e) => {
    e.preventDefault();
    const invitedUser = e.target.invitedUser.value;

    if (invitedUsers.length === MAX_INVITED_USERS) {
      return;
    }

    if (!validateEmail(invitedUser)) {
      e.target.invitedUser?.focus();
      return setInvitedUserError('Invalid email address');
    }

    if (invitedUsers.some((u) => u.email === invitedUser)) {
      e.target.invitedUser?.focus();
      return setInvitedUserError('You have already added this user to the list');
    }

    if (invitedUserError) {
      setInvitedUserError(null);
    }

    setLoadingUser(true);
    try {
      const users = await getWPUserByEmail(invitedUser);
      if (!users.length) {
        throw new Error('This user is not registered!');
      }

      const foundUser = users[0];

      if (getUserRole(foundUser) !== ROLES.STUDENT) {
        throw new Error('This user cannot be invited!');
      }

      setInvitedUsers((prevState) => [...prevState, foundUser]);
      e.target.reset();
    } catch (error) {
      setInvitedUserError(error.message);
    }

    setLoadingUser(false);
  };
  return (
    <form onSubmit={handleAddUser}>
      <Box pt={1}>
        <Grid container spacing={2}>
          <Grid item xs={xs ? 12 : true}>
            <TextField
              name='invitedUser'
              required
              label='Email'
              type='email'
              variant='outlined'
              fullWidth
              error={Boolean(invitedUserError)}
              helperText={invitedUserError || undefined}
            />
          </Grid>
          <Grid item>
            <LoadingButton
              style={{ marginTop: xs ? 0 : 9.5 }}
              startIcon={<Icon>person_add_alt_icon</Icon>}
              type='submit'
              loading={loadingUser}
              disabled={invitedUsers.length === MAX_INVITED_USERS}
            >
              Invite User
            </LoadingButton>
          </Grid>

          <Grid item xs={12} container justifyContent='center'>
            <Box py={2} maxWidth={500} width='100%'>
              <Typography style={{ textAlign: 'center' }}>
                <Icon
                  color='action'
                  fontSize='large'
                  style={{ verticalAlign: 'bottom', marginRight: 16 }}
                >
                  groups
                </Icon>
                <strong>
                  Invited Users ({invitedUsers.length} - {MAX_INVITED_USERS})
                </strong>
              </Typography>
              <Divider />
              <Grid container>
                {invitedUsers.length ? (
                  invitedUsers.map(({ email }, i) => (
                    <Grid
                      key={`user_${i}`}
                      item
                      xs={12}
                      className={classes.invitedUser}
                      container
                      alignItems='center'
                      justifyContent='space-between'
                    >
                      <Typography>
                        <Icon
                          style={{
                            verticalAlign: 'middle',
                            color: theme.palette.success.main,
                          }}
                        >
                          done
                        </Icon>{' '}
                        {email}
                      </Typography>
                      <IconButton
                        onClick={() =>
                          setInvitedUsers(invitedUsers.filter((u) => u.email !== email))
                        }
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <Box textAlign={'center'} pt={2}>
                      <Typography variant='body2'>
                        *Please add users to this group class.
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
});

function CheckoutForm({ tutor, onClose, bookings, selectedTimeSlot, hoursTaught }) {
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useAuth();
  const { filters, setFilters } = useFilters();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [paymentSucceeded, setPaymentSucceeded] = useState(false);
  const [selectedDate, setSelectedDate] = useState(selectedTimeSlot?.date || null);
  const [startTime, setStartTime] = useState(selectedTimeSlot?.hour || null);
  const [duration, setDuration] = useState(1);
  const [showCalendar, setShowCalendar] = useState(false);
  const [buddyUp, setBuddyUp] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(filters?.subject || null);
  const [selectedLevel, setSelectedLevel] = useState(filters?.level || '-');
  const [inPerson, setInPerson] = useState(filters?.inPerson || false);

  useEffect(() => {
    // get booked hours for the selectedDate
    const bookedHours = bookings
      .filter((b) => b.selectedDate.toDateString() === selectedDate?.toDateString())
      .map((b) => Array.from(Array(b.duration).keys()).map((key) => b.startTime + key))
      .flat();

    // check for overlaps
    let isOverlapping = false;
    Array.from(Array(duration).keys()).forEach((key) => {
      if (bookedHours.includes(startTime + key)) {
        isOverlapping = true;
      }
    });

    if (isOverlapping) {
      setSelectedDate(null);
      setStartTime(null);
      setDuration(1);
    }
  }, [bookings, duration, selectedDate, startTime]);

  useEffect(() => {
    try {
      const lastSearch = JSON.parse(sessionStorage.getItem(LAST_SEARCH)) || null;
      if (lastSearch) {
        setFilters(lastSearch);
      }
    } catch (error) {
      // don't set any filters
    }
  }, [setFilters]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    if (!selectedSubject) {
      document.querySelector('[name=subject]')?.focus();
      return setErrorMessage('Please select a subject!');
    }

    if (!startTime) {
      document.querySelector('[name=startTime]')?.focus();
      return setErrorMessage('Please select a start time!');
    }

    if (buddyUp && !invitedUsers.length) {
      document.querySelector('[name=invitedUser]')?.focus();
      return setErrorMessage('Please add at least one user to the group class.');
    }

    if (errorMessage) {
      setErrorMessage(null);
    }

    const bookingDetails = {
      subject: selectedSubject.name,
      level: selectedLevel || null,
      inPerson,
      selectedDate,
      startTime,
      duration,
      groupClass: buddyUp,
      tutor: {
        id: tutor.id,
        email: tutor.email,
        name: tutor.name,
        rate: tutor.meta.hours_rate[0],
        hoursTaught,
      },
      student: {
        id: user.id,
        email: user.email,
        name: user.name,
      },
      invitedUsers: buddyUp
        ? invitedUsers.map((u) => ({ id: u.id, name: u.name, email: u.email }))
        : [],
      createdAt: new Date(),
    };

    const card = elements.getElement(CardElement);

    setLoading(true);

    try {
      // check card info
      const payload = await stripe.createPaymentMethod({
        type: 'card',
        card,
      });

      if (payload.error) {
        throw payload.error;
      }

      // process payment
      setProcessingPayment(true);
      const { clientSecret } = await createLessonPaymentIntent({
        selectedDate,
        startTime,
        duration,
        tutorId: tutor.id,
        groupClass: buddyUp,
        students: buddyUp ? [user.email, ...invitedUsers.map((u) => u.email)] : [user.email],
        authToken: localStorage.getItem(TOKEN),
      }).catch((err) => {
        throw new Error(err.response?.data?.error);
      });

      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card,
          billing_details: {
            name: user.name,
            email: user.email,
          },
        },
      });

      if (error) {
        // Show error to your customer (e.g., insufficient funds)
        throw error;
      } else {
        // The payment has been processed!
        if (paymentIntent.status === 'succeeded') {
          setPaymentSucceeded(true);
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.

          // save booking details
          await createBooking({ ...bookingDetails, paymentDetails: paymentIntent }).catch((error) =>
            console.error('Error adding document: ', error)
          );
        }
      }
    } catch (error) {
      setErrorMessage(error.message);
    }

    setProcessingPayment(false);
    setLoading(false);
  };

  const handleDurationChange = useCallback(({ target: { value } }) => setDuration(value), []);

  const handleTimeSlotSelect = useCallback((date, hour) => {
    setSelectedDate(date);
    setStartTime(hour);
    setShowCalendar(false);
    setDuration(1);
  }, []);

  const handleSubjectChange = useCallback((e, value, reason) => {
    setSelectedSubject(value);
  }, []);

  const handleLevelChange = useCallback((e) => {
    setSelectedLevel(e.target.value);
  }, []);

  const mappedAvailability = useMemo(() => {
    const { tutor_availability } = tutor.meta;
    if (!tutor_availability?.[0]) {
      return [];
    }

    return JSON.parse(tutor_availability[0]).map((a) => ({ ...a, date: new Date(a.date) }));
  }, [tutor]);

  const isDurationDisabled = useCallback(
    (duration) => {
      const selectedDateAvailability = mappedAvailability.find(
        (a) => a.date.toDateString() === selectedDate?.toDateString()
      )?.hours;

      if (!selectedDateAvailability?.length) {
        return true;
      }

      const bookedHours = bookings
        .filter((b) => b.selectedDate.toDateString() === selectedDate?.toDateString())
        .map((b) => Array.from(Array(b.duration).keys()).map((key) => b.startTime + key))
        .flat()
        .sort((a, b) => a - b);

      const remainingAvailability = selectedDateAvailability.filter(
        (hour) => !bookedHours.includes(hour)
      );

      let disabled = false;
      Array.from(Array(duration).keys()).forEach((key) => {
        if (!remainingAvailability.includes(startTime + key)) {
          disabled = true;
        }
      });

      return disabled;
    },
    [bookings, mappedAvailability, selectedDate, startTime]
  );

  const ratePerHour = useMemo(() => Number(tutor.meta.hours_rate) || 0, [tutor.meta.hours_rate]);
  const totalCost = useMemo(
    () =>
      (buddyUp
        ? duration * (invitedUsers.length + 1) * (ratePerHour - (ratePerHour / 100) * 25)
        : duration * ratePerHour
      ).toFixed(2),
    [buddyUp, duration, invitedUsers.length, ratePerHour]
  );

  return paymentSucceeded ? (
    <Box p={2}>
      <Grid container direction='column' spacing={4} alignItems='center'>
        <Grid item>
          <Icon className={classes.successIcon}>{'check_circle_outline_icon'}</Icon>
        </Grid>
        <Grid item>
          <Box textAlign='center'>
            <Typography variant='h5'>Your payment has been processed!</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Button variant='outlined' color='primary' onClick={onClose}>
            Close
          </Button>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <>
      {processingPayment && (
        <Box p={2} textAlign='center' style={{ display: 'grid', placeItems: 'center' }}>
          <Typography variant='h5' gutterBottom>
            Payment processing...
          </Typography>
          <Typography variant='h6' style={{ fontWeight: 'normal' }} color='textSecondary'>
            Please wait
          </Typography>
          <Box py={4}>
            <SquareLoader size={100} color={COLORS.secondary} />
          </Box>
        </Box>
      )}
      <DialogTitle
        hidden={processingPayment}
        style={{ textAlign: 'center', borderBottom: `1px solid ${theme.palette.divider}` }}
      >
        Request a lesson
      </DialogTitle>
      <DialogContent className={classes.content} hidden={processingPayment}>
        <Box pt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6'>What would you like to learn?</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Autocomplete
                    fullWidth
                    id='subject-combo-box'
                    filterOptions={filterOptions}
                    loading={loading}
                    options={tutor.subjects || []}
                    getOptionLabel={getOptionLabel}
                    getOptionSelected={getOptionSelected}
                    onChange={handleSubjectChange}
                    value={selectedSubject}
                    renderInput={(params) => (
                      <TextField
                        name='subject'
                        label='Subject'
                        placeholder='Enter your subject'
                        variant='outlined'
                        {...params}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    name='level'
                    label='Level'
                    fullWidth
                    id='level-combo-box'
                    options={selectedSubject?.levels || []}
                    value={selectedLevel}
                    variant='outlined'
                    onChange={handleLevelChange}
                    select
                    disabled={!selectedSubject?.levels?.length}
                  >
                    <MenuItem value={'-'}>
                      <em>Select Level</em>
                    </MenuItem>
                    {selectedSubject?.levels?.map((level, index) => (
                      <MenuItem key={`level_${index}`} value={level}>
                        {level}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {tutor?.meta?.tutoring_method?.includes('In Person') && (
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      value={inPerson}
                      fullWidth
                      label='Type'
                      select
                      onChange={(e) => {
                        setInPerson(e.target.value);
                      }}
                    >
                      <MenuItem value={false}>Online</MenuItem>
                      <MenuItem value={true}>In Person</MenuItem>
                    </TextField>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box pt={4}>
                <Typography variant='h6'>When would you like your first lesson?</Typography>
              </Box>
            </Grid>

            <Grid item container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography>
                  <strong>Start time*</strong>
                </Typography>
                <TextField
                  inputProps={{ readOnly: true }}
                  required
                  name='startTime'
                  onClick={() => !showCalendar && setShowCalendar(true)}
                  fullWidth
                  variant='outlined'
                  placeholder='--/--/---- --:--'
                  value={
                    selectedDate && startTime
                      ? `${selectedDate?.toLocaleDateString('en-GB')}  ${startTime}:00`
                      : ''
                  }
                />
              </Grid>

              {showCalendar && (
                <Grid item xs={12}>
                  <ClickAwayListener
                    onClickAway={(e) =>
                      showCalendar && e.target.name !== 'startTime' && setShowCalendar(false)
                    }
                  >
                    <Box pb={2}>
                      <AvailabilityCalendar
                        bookings={bookings}
                        availability={mappedAvailability}
                        onTimeSlotSelect={handleTimeSlotSelect}
                      />
                    </Box>
                  </ClickAwayListener>
                  <Divider />
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <Typography>
                  <strong>Duration</strong>
                </Typography>
                <TextField
                  disabled={!startTime}
                  select
                  fullWidth
                  variant='outlined'
                  value={duration}
                  onChange={handleDurationChange}
                  name='duration'
                >
                  <MenuItem value={1}>1 hour</MenuItem>
                  <MenuItem value={2} disabled={isDurationDisabled(2)}>
                    2 hours
                  </MenuItem>
                  <MenuItem value={3} disabled={isDurationDisabled(3)}>
                    3 hours
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Grid>

          {tutor?.meta?.tuition_types?.includes('group-class') && (
            <Box pt={4}>
              <Typography variant='h6'>
                Do you want to buddy up with your friends for a{' '}
                <span style={{ color: theme.palette.secondary.main }}>25%</span> discount?
                <Tooltip
                  title={`You can invite up to ${MAX_INVITED_USERS} registered users and get a 25% discount for each.`}
                >
                  <Icon color='action' style={{ marginLeft: 8, verticalAlign: 'text-bottom' }}>
                    info
                  </Icon>
                </Tooltip>
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={buddyUp}
                    onChange={(e, checked) => {
                      if (invitedUsers.length) {
                        setInvitedUsers([]);
                      }
                      setBuddyUp(checked);
                    }}
                  />
                }
                label={buddyUp ? 'YES' : 'NO'}
              />
              {buddyUp && (
                <BuddyUp
                  invitedUsers={invitedUsers}
                  setInvitedUsers={setInvitedUsers}
                  xs={xs}
                  classes={classes}
                />
              )}
            </Box>
          )}

          <Box pt={4}>
            <BookingDetails
              subject={selectedSubject?.name || '-'}
              level={selectedLevel || '-'}
              type={inPerson ? 'In Person' : 'Online'}
              date={selectedDate?.toLocaleDateString('en-GB') || '--/--/----'}
              startTime={startTime ? startTime + ':00' : '--:--'}
              duration={`${duration} hour${duration > 1 ? 's' : ''}`}
              discount={buddyUp ? '-25%' : undefined}
              pricePerPerson={
                buddyUp ? (
                  <>
                    {POUND_SYMBOL}
                    {computeEarning(tutor.meta.hours_rate[0] || 0, 25).toFixed(2)}
                  </>
                ) : undefined
              }
              total={totalCost}
            />
          </Box>
          <form onSubmit={handleSubmit} id='checkout-form'>
            <Box py={4}>
              <label htmlFor='stripe-card-element'>
                <Typography variant='h6' gutterBottom>
                  Card details
                </Typography>
              </label>
              <CardElement
                id='stripe-card-element'
                className={classes.cardElement}
                options={CARD_ELEMENT_OPTIONS}
              />
            </Box>
            <Box pb={2} textAlign='center' className={classes.securePaymentsWrapper}>
              <img
                className={classes.securePayments}
                src={securePayments}
                alt='Secure Payments with Stripe'
              />
            </Box>
          </form>
        </Box>
      </DialogContent>
      <Divider hidden={processingPayment} />
      {errorMessage && (
        <Box textAlign='center' p={2}>
          <Typography color='error'>{errorMessage}</Typography>
        </Box>
      )}
      {!processingPayment && (
        <DialogActions>
          <Button
            variant='outlined'
            color='primary'
            onClick={onClose}
            disabled={loading}
            fullWidth={xs}
          >
            Cancel
          </Button>

          <Button
            form='checkout-form'
            fullWidth={xs}
            startIcon={<Icon>{'payment_icon'}</Icon>}
            variant='contained'
            color='primary'
            type='submit'
            disabled={!stripe || loading}
          >
            {loading && (
              <CircularProgress
                size={24}
                style={{ position: 'absolute', transform: 'translateX(-50)' }}
              />
            )}
            Pay &nbsp;
            {POUND_SYMBOL}
            {totalCost}
          </Button>
        </DialogActions>
      )}
    </>
  );
}

export default React.memo(CheckoutForm);
