import React, { useMemo } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Grow,
  Icon,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useStyles } from './style';
import { useAuth } from 'providers';
import { menuConfig } from 'config/menuConfig';
import { getUserRole } from 'utils/user';
import logo from 'assets/images/logo.png';
import CustomLink from 'components/CustomLink';
import { theme } from 'themes';

export default function Home() {
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { user } = useAuth();
  const userRole = useMemo(() => getUserRole(user), [user]);

  return (
    <Box className={classes.container} py={10}>
      <Container>
        <Box textAlign='center' mb={10}>
          <img src={logo} alt='logo' className={classes.logo} />
        </Box>
        <Grid
          container
          spacing={xs ? 2 : sm ? 3 : 4}
          justifyContent={menuConfig[userRole].length < 4 && !xs ? 'center' : 'flex-start'}
        >
          {menuConfig[userRole].map((item, index) => (
            <Grow in={true} timeout={Math.random() * 1000} key={item.title}>
              <Grid item xs={6} sm={4} md={3}>
                <CustomLink style={{ textDecoration: 'none' }} to={item.to} href={item.href}>
                  <Button fullWidth variant='outlined' color='primary'>
                    <Icon fontSize='large'>{item.icon}</Icon>
                    <Typography variant='h6'>{item.title}</Typography>
                  </Button>
                </CustomLink>
              </Grid>
            </Grow>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
