import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { getDateFromObject, prettifyDateTime } from 'utils/date';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { collection, orderBy, query } from 'firebase/firestore';
import { db } from 'config/firebaseConfig';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { theme } from 'themes';
import PageLayout from 'components/PageLayout';
import CustomLink from 'components/CustomLink';

const statusColor = {
  new: theme.palette.warning.main,
  completed: theme.palette.success.main,
};

const columns = [
  {
    field: 'createdAt',
    headerName: 'Created At',
    valueGetter: ({ value }) => prettifyDateTime(getDateFromObject(value)),
    flex: true,
    minWidth: 200,
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: ({ value }) => (
      <span style={{ textTransform: 'capitalize', color: statusColor[value] }}>{value}</span>
    ),
    flex: true,
    minWidth: 200,
  },

  {
    field: 'student',
    headerName: 'Student',
    flex: true,
    minWidth: 200,
    valueGetter: ({ value }) => value?.name,
    renderCell: ({ value, row }) => (
      <Grid container direction='column' justifyContent='center'>
        <span
          style={{
            lineHeight: 1.3,
            textTransform: 'capitalize',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 220,
          }}
        >
          <strong>{value}</strong>
        </span>
        <Typography
          variant='caption'
          style={{
            lineHeight: 1.3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 220,
          }}
        >
          {row['student']?.email}
        </Typography>
      </Grid>
    ),
  },
  {
    field: 'tutor',
    headerName: 'Tutor',
    flex: true,
    minWidth: 200,
    valueGetter: ({ value }) => value?.name,
    renderCell: ({ value, row }) => (
      <Grid container direction='column' justifyContent='center'>
        <span
          style={{
            lineHeight: 1.3,
            textTransform: 'capitalize',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 220,
          }}
        >
          <strong>{value}</strong>
        </span>
        <Typography
          variant='caption'
          style={{
            lineHeight: 1.3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 220,
          }}
        >
          {row['tutor']?.email}
        </Typography>
      </Grid>
    ),
  },
  {
    field: 'completedAt',
    headerName: 'Completed On',
    valueGetter: ({ value }) => prettifyDateTime(getDateFromObject(value)),
    flex: true,
    minWidth: 200,
  },
];

function ConsultationHistory() {
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const [selectedRow, setSelectedRow] = useState(null);
  const [pageSize, setPageSize] = useState(5);
  const [consultations = [], loadingConsultations] = useCollectionData(
    query(collection(db, 'consultations'), orderBy('createdAt', 'desc')),
    { idField: 'id' }
  );

  const handleRowClick = useCallback((params) => {
    setSelectedRow(params.row);
  }, []);

  return (
    <>
      <PageLayout>
        <Box pb={10} textAlign='center'>
          <Typography variant='h2'>Consultation History</Typography>
        </Box>
        <DataGrid
          rows={consultations}
          columns={columns}
          loading={loadingConsultations}
          autoHeight
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          rowsPerPageOptions={[5, 10, 20]}
          onRowClick={handleRowClick}
          components={{ Toolbar: GridToolbar }}
          checkboxSelection
        />
      </PageLayout>
      {Boolean(selectedRow) && (
        <Dialog fullWidth open={true} onClose={() => setSelectedRow(null)}>
          <DialogTitle style={{ textAlign: 'center' }}>Request details</DialogTitle>
          <Divider />
          <DialogContent style={{ padding: theme.spacing(4, 2) }}>
            <Typography variant='h6'>
              <strong>{'Student'}</strong>{' '}
            </Typography>
            <Typography>
              <strong>Name:</strong> {selectedRow['student']?.name}
            </Typography>
            <Typography>
              <strong>Email:</strong>{' '}
              <CustomLink href={`mailto:${selectedRow['student']?.email}`}>
                {selectedRow['student']?.email}
              </CustomLink>
            </Typography>

            <Typography>
              <strong>Phone:</strong>{' '}
              <CustomLink href={`tel:${selectedRow['student']?.phone}`}>
                {selectedRow['student']?.phone}
              </CustomLink>
            </Typography>
            <br />
            <Typography variant='h6'>
              <strong>{'Tutor'}</strong>{' '}
            </Typography>
            <Typography>
              <strong>Name:</strong> {selectedRow['tutor']?.name}
            </Typography>
            <Typography>
              <strong>Email:</strong>{' '}
              <CustomLink href={`mailto:${selectedRow['tutor']?.email}`}>
                {selectedRow['tutor']?.email}
              </CustomLink>
            </Typography>

            <br />
            <Typography variant='h6'>
              <strong>Intro</strong>{' '}
            </Typography>
            <Typography>{selectedRow.intro}</Typography>

            <br />
            <Typography variant='h6'>
              <strong>Created At</strong>{' '}
            </Typography>
            <Typography>{prettifyDateTime(getDateFromObject(selectedRow.createdAt))}</Typography>
            <br />
            <Typography variant='h6'>
              <strong>Status</strong>{' '}
            </Typography>
            <Typography style={{ textTransform: 'capitalize' }}>{selectedRow.status}</Typography>
            {selectedRow.status === 'completed' && (
              <>
                <br />
                <Typography variant='h6'>
                  <strong>Completed On</strong>{' '}
                </Typography>
                <Typography style={{ textTransform: 'capitalize' }}>
                  {prettifyDateTime(getDateFromObject(selectedRow.completedAt))}
                </Typography>
              </>
            )}
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              fullWidth={xs}
              variant='outlined'
              color='primary'
              onClick={() => setSelectedRow(null)}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default ConsultationHistory;
