import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useAuth } from '../../../providers/Auth';
import { updateWPUser } from '../../../services/wp/users';
import { theme } from 'themes';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import AvailabilityCalendar from 'components/AvailabilityCalendar';
import { Skeleton } from '@material-ui/lab';

function Availability({ tutor, tutorBookings, loadingBookings, onTimeSlotSelect }) {
  const { user, setUser } = useAuth();
  const [error, setError] = useState({});
  const [open, setOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const { id, meta: { tutor_availability = [] } = {} } = tutor || user;

  const mappedAvailability = useMemo(
    () =>
      tutor_availability?.[0]
        ? JSON.parse(tutor_availability[0]).map((a) => ({ ...a, date: new Date(a.date) }))
        : [],
    [tutor_availability]
  );
  const [availability, setAvailability] = useState(mappedAvailability);

  const clearErrors = () => Object.keys(error).length && setError({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearErrors();

    setSubmitting(true);

    try {
      const newUserData = (
        await updateWPUser(id, {
          meta: {
            tutor_availability: [JSON.stringify(availability)],
          },
        })
      ).data;
      if (newUserData) {
        setOpen(false);
        setUser(newUserData);
      }
    } catch (error) {
      setError({ serverError: error.response?.data?.message });
    }

    setSubmitting(false);
  };

  return (
    <>
      <Grid container spacing={2} wrap='nowrap' justifyContent='space-between'>
        <Grid item>
          <Typography variant='h4' color='textSecondary'>
            Availability
          </Typography>
        </Grid>
        {!tutor && (
          <Grid item>
            <Tooltip title='Edit Availability' arrow>
              <IconButton onClick={() => !loadingBookings && setOpen(true)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>

      <Box py={1}>
        <Divider />
      </Box>

      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Box pt={1}>
            {loadingBookings ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Skeleton variant='rect' height={100} />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant='rect' height={200} />
                </Grid>
              </Grid>
            ) : (
              <AvailabilityCalendar
                bookings={tutorBookings}
                availability={mappedAvailability}
                onTimeSlotSelect={tutor ? onTimeSlotSelect : undefined}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      {open && (
        <Dialog open={open} fullWidth maxWidth='md'>
          <DialogTitle
            style={{ borderBottom: `1px solid ${theme.palette.divider}`, textAlign: 'center' }}
          >
            Edit your availability
          </DialogTitle>
          <DialogContent style={{ padding: theme.spacing(2) }}>
            <form onSubmit={handleSubmit} id='edit-availability'>
              <AvailabilityCalendar
                bookings={tutorBookings}
                availability={availability}
                setAvailability={setAvailability}
              />
            </form>
          </DialogContent>
          <Divider />
          {error.serverError && (
            <Box p={2} textAlign='center'>
              <Typography color='error'>{error.serverError}</Typography>
            </Box>
          )}
          <DialogActions>
            <Button
              variant='outlined'
              onClick={() => {
                setOpen(false);
                clearErrors();
                setAvailability(mappedAvailability);
              }}
              color='primary'
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              form='edit-availability'
              variant='contained'
              color='primary'
              disabled={isSubmitting}
            >
              Save
              {isSubmitting && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                  }}
                />
              )}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default React.memo(Availability);
