import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { APP_TITLE, TOKEN } from 'enums';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { useStyles } from './style';
import LoadingButton from 'components/LoadingButton';
import { useAuth, useFilters } from 'providers';
import { createRequest } from 'services/firebase/requests';
import { theme } from 'themes';
import { routes } from 'routes';
import { useSubjects } from 'providers/Subjects';
import CustomLink from 'components/CustomLink';
import RequestsHistory from './RequestsHistory';

const DescriptionField = () => {
  const classes = useStyles();
  const [description, setDescription] = useState('');

  const handleDescriptionChange = useCallback((e) => setDescription(e.target.value), []);

  return (
    <TextField
      className={classes.description}
      error={description.length < 50}
      helperText={
        description.length < 50 ? (
          <em>*Request description must contain at least 50 characters</em>
        ) : null
      }
      fullWidth
      multiline
      required
      minRows={10}
      value={description}
      name='description'
      placeholder={`Let potential tutors/mentors know how they can help, what you want to learn, what you struggle with and what you want to get from tuition.
        
Make sure you don't include any personal information, like phone numbers at this stage!`}
      variant='outlined'
      onChange={handleDescriptionChange}
    />
  );
};

const getOptionLabel = (option) => option.name;
const getOptionSelected = (option, value) => option.name === value?.name;
const filterOptions = createFilterOptions({
  matchFrom: 'start',
});

function RequestTutorMentor() {
  const { subjects, loading: loadingSubjects } = useSubjects();
  const { user } = useAuth();

  const [submitting, setSubmitting] = useState(false);
  const [requestSubmitted, setRequestSubmitted] = useState(false);

  const { filters, setFilters } = useFilters();

  const [open, setOpen] = useState(false);
  const [serverError, setServerError] = useState(null);

  const handleSubjectChange = useCallback(
    (e, value, reason) => {
      setFilters((filters) => ({ ...filters, subject: value, level: null }));
    },
    [setFilters]
  );

  const handleSubjectLevelChange = useCallback(
    (e) => setFilters((filters) => ({ ...filters, level: e.target.value })),
    [setFilters]
  );

  const handleSubjectInputChange = useCallback(
    ({ target: { value } }) => (value ? !open && setOpen(true) : open && setOpen(false)),
    [open]
  );

  const handleCloseSubjects = useCallback(() => setOpen(false), []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { subject, level, searchFor } = filters;
    const description = e.target.description?.value;

    if (description?.length < 50) {
      document.querySelector('textarea[name=description]')?.focus();
      return;
    }

    const requestDetails = {
      subject,
      level,
      searchFor,
      description,
      createdAt: new Date(),
      user: { id: user.id, email: user.email, name: user.name },
      accessToken: localStorage.getItem(TOKEN),
    };

    setServerError(null);
    setSubmitting(true);

    try {
      await createRequest(requestDetails);
      setRequestSubmitted(true);
    } catch (error) {
      setServerError(error.message);
    }

    setSubmitting(false);
  };

  return (
    <Box pt={18} pb={10}>
      <Container>
        <Box textAlign='center'>
          <Typography variant='h2' gutterBottom>
            Find your trusted tutor or mentor
          </Typography>
        </Box>
        <Box textAlign='center' display='flex' justifyContent='center'>
          <Typography style={{ maxWidth: 1000 }}>
            {APP_TITLE} can help you to make the right choice. Submit your tuition request and get
            responses from the best tutors, shortlisted specifically for you, trusted and reviewed
            by other students.
          </Typography>
        </Box>

        {requestSubmitted ? (
          <Box pt={10} style={{ display: 'grid', placeItems: 'center', textAlign: 'center' }}>
            <Icon style={{ fontSize: 100, marginBottom: 16, color: theme.palette.success.main }}>
              check_circle_outline_icon
            </Icon>
            <Typography variant='h5' gutterBottom>
              Your request has been posted!
            </Typography>
            <Typography>Interested tutors will get in touch through the website</Typography>
            <br />
            <CustomLink to={routes.home} style={{ textDecoration: 'none' }}>
              <Button variant='outlined' color='primary'>
                Go to dashboard
              </Button>
            </CustomLink>
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <Box pt={10}>
              <Typography variant='h6' gutterBottom>
                Are you looking for a tutor or a mentor?
              </Typography>
              <FormControl
                component='fieldset'
                onChange={(e) =>
                  setFilters((prevFilters) => ({ ...prevFilters, searchFor: e.target.value }))
                }
              >
                <RadioGroup defaultValue={'tutor'} name='searchFor'>
                  <FormControlLabel value='tutor' control={<Radio />} label='Tutor' />
                  <FormControlLabel value='mentor' control={<Radio />} label='Mentor' />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box pt={4}>
              <Typography variant='h6'>What would you like to learn?</Typography>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md>
                  <Autocomplete
                    open={open}
                    onClose={handleCloseSubjects}
                    filterOptions={filterOptions}
                    fullWidth
                    id='subject-combo-box'
                    loading={loadingSubjects}
                    options={subjects}
                    getOptionLabel={getOptionLabel}
                    getOptionSelected={getOptionSelected}
                    onChange={handleSubjectChange}
                    value={filters.subject}
                    renderInput={(params) => (
                      <TextField
                        required
                        onChange={handleSubjectInputChange}
                        name='subject'
                        label='Subject'
                        placeholder='Enter your subject'
                        variant='outlined'
                        {...params}
                      />
                    )}
                  />
                </Grid>

                {filters?.subject?.levels?.length > 0 && (
                  <Grid item xs={12} sm={12} md>
                    <TextField
                      label='Level'
                      name='level'
                      fullWidth
                      id='level-combo-box'
                      options={filters.subject?.levels || []}
                      value={filters.level || '-'}
                      variant='outlined'
                      onChange={handleSubjectLevelChange}
                      select
                    >
                      <MenuItem value={'-'}>
                        <em>Select Level</em>
                      </MenuItem>
                      {filters.subject.levels.map((l, i) => (
                        <MenuItem key={`level_${i}`} value={l}>
                          {l}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
              </Grid>
            </Box>

            <Box py={4}>
              <Typography variant='h6' gutterBottom>
                Describe what you're looking for
              </Typography>
              <DescriptionField />
            </Box>

            <Grid container spacing={2} justifyContent='center'>
              {serverError && (
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography color='error'>{serverError}</Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={6}>
                <LoadingButton
                  loading={submitting}
                  fullWidth
                  style={{ height: 56 }}
                  variant='contained'
                  type='submit'
                  color='primary'
                  size='large'
                >
                  Submit Request
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        )}

        <Box py={10}>
          <Divider />
        </Box>

        <Typography gutterBottom variant='h6'>
          Requests History
        </Typography>
        <RequestsHistory />
      </Container>
    </Box>
  );
}

export default RequestTutorMentor;
