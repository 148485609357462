import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  bookingBar: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    padding: theme.spacing(2, 0),
    background: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0),
      '& .MuiButton-root': {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        fontSize: theme.typography.caption.fontSize,
      },
    },
  },
  profileSection: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  publicViewButton: {
    position: 'fixed',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    border: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
    [theme.breakpoints.down('xs')]: {
      right: theme.spacing(2),
      bottom: theme.spacing(2),
    },
  },
}));
