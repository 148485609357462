import React, { createContext, useContext } from 'react';
import { query } from '@firebase/firestore';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { categoriesRef } from 'services/firebase/subjects';

const CategoriesContext = createContext();
export const useCategories = () => useContext(CategoriesContext);

export function CategoriesProvider({ children }) {
  const [categories = [], loading] = useCollectionDataOnce(query(categoriesRef), {
    idField: 'id',
  });

  return (
    <CategoriesContext.Provider value={{ categories, loading }}>
      {children}
    </CategoriesContext.Provider>
  );
}
