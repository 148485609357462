import { collection, updateDoc, doc, setDoc, deleteDoc } from '@firebase/firestore';
import { db } from 'config/firebaseConfig';

export const userNotificationsRef = collection(db, 'userNotifications');

export const updateUserNotifications = (userId, newData) =>
  updateDoc(doc(userNotificationsRef, userId), newData);

export const createUserNotifications = (userId, data) =>
  setDoc(doc(userNotificationsRef, userId), data);

export const deleteUserNotifications = (userId) => deleteDoc(doc(userNotificationsRef, userId));
