import React from 'react';
import { Box, Divider, Grid, Typography, Avatar, useMediaQuery } from '@material-ui/core';
import { stringAvatar } from 'utils/avatar';
import { theme } from 'themes';
import { Rating } from '@material-ui/lab';

function Reviews({ reviews }) {
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box>
      <Grid container justifyContent='space-between' spacing={1}>
        <Grid item>
          <Typography variant='h4' color='textSecondary'>
            Reviews
          </Typography>
        </Grid>
        <Grid item>
          <Box
            display='flex'
            alignItems='center'
            height='100%'
            flexDirection={xs ? 'column' : 'row'}
          >
            <Box marginRight={xs ? 0 : 2}>
              <Rating
                value={Math.round(
                  reviews.map((r) => r.rating).reduce((acc, curr) => acc + curr, 0) / reviews.length
                )}
                readOnly
                size='large'
              />
            </Box>
            <Typography variant='caption'>
              {reviews.length} review{reviews.length === 1 ? '' : 's'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box py={1}>
        <Divider />
      </Box>

      <Box pt={2}>
        <Grid container spacing={2}>
          {reviews.length ? (
            reviews.map((r, i) => (
              <Grid item key={`review_${i}`} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={3} md={2}>
                    <Grid container direction='column' alignItems='center' spacing={1}>
                      <Grid item>
                        <Avatar src={null} {...stringAvatar(r.reviewer.name)} />
                      </Grid>
                      <Grid item>
                        <Typography>
                          <strong>{r.reviewer.name}</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={8} sm={9} md={10}>
                    <Box pb={1}>
                      <Rating value={r.rating} readOnly />
                    </Box>
                    <Typography>{r.reviewText}</Typography>
                  </Grid>
                </Grid>

                <Box textAlign='right' pt={1}>
                  <Typography gutterBottom variant='caption' color='textSecondary'>
                    {r.createdAt.toLocaleDateString('en-GB', {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                    })}
                  </Typography>
                </Box>
                {i < reviews.length - 1 && (
                  <Box pt={1}>
                    <Divider />
                  </Box>
                )}
              </Grid>
            ))
          ) : (
            <Grid item>
              <Typography color='textSecondary'>No reviews</Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default Reviews;
