import React, { useState, useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useAuth } from 'providers/Auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { query, where, orderBy } from 'firebase/firestore';
import { subjectsRef } from 'services/firebase/subjects';
import { updateTutorSubjects } from 'services/firebase/tutorSubjects';
import { useCategories } from 'providers';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import SubjectWithLevels from 'components/SubjectWithLevels';
import { theme } from 'themes';

const SubjectsDialogContent = React.memo(
  ({ categories, tutorId, tutorSelectedSubjects, onCancel }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState({});
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubjects, setSelectedSubjects] = useState(tutorSelectedSubjects);
    const [subjects = [], loadingSubjects] = useCollectionData(
      selectedCategory
        ? query(subjectsRef, where('category', '==', selectedCategory), orderBy('name', 'asc'))
        : undefined,
      {
        idField: 'id',
      }
    );

    const clearErrors = () => Object.keys(error).length && setError({});

    const handleCategoryChange = useCallback((e) => setSelectedCategory(e.target.value), []);

    const handleSubmit = async (e) => {
      e.preventDefault();
      clearErrors();

      setIsSubmitting(true);

      try {
        await updateTutorSubjects(tutorId, {
          tutorId,
          subjects: selectedSubjects.sort((s1, s2) => String(s1.name).localeCompare(s2.name)),
          updatedAt: new Date(),
        });
      } catch (error) {
        setError({ serverError: error.message });
        setIsSubmitting(false);
        return;
      }

      setIsSubmitting(false);
      onCancel();
    };

    return (
      <Dialog open={true} fullWidth>
        <DialogTitle>
          <Box textAlign='center' pb={2}>
            Edit your subjects
          </Box>
          <Box pb={2}>
            <Typography variant='body2'>
              Add the subjects and ALL levels that you are confident to tutor at. Select each
              category from the dropdown to see all the subjects that we offer.
            </Typography>
          </Box>
          <Box>
            <TextField
              onChange={handleCategoryChange}
              required
              fullWidth
              name='category'
              label='Category'
              variant='outlined'
              select
              defaultValue='*'
            >
              <MenuItem value={'*'} key={'none'}>
                <em>Select category</em>
              </MenuItem>
              {categories.map(({ id }) => (
                <MenuItem value={id} key={id}>
                  {id}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <form onSubmit={handleSubmit} id='edit-subjects-form'>
            <Grid container direction='column' justifyContent='space-between'>
              <Grid container spacing={2}>
                {!subjects.length && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'grid',
                      placeItems: 'center',
                      minHeight: 300,
                    }}
                  >
                    {selectedCategory && loadingSubjects && <CircularProgress />}
                    {!selectedCategory && (
                      <Typography variant='h6' color='textSecondary'>
                        Please select a category
                      </Typography>
                    )}
                  </Grid>
                )}
                {subjects.map((s) => (
                  <SubjectWithLevels
                    subject={s}
                    key={s.name}
                    selectedLevels={selectedSubjects.find(({ id }) => id === s.id)?.levels}
                    onChange={setSelectedSubjects}
                  />
                ))}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <Divider />
        {error.serverError && (
          <Box textAlign='center' p={2}>
            <Typography color='error' variant='caption'>
              {error.serverError}
            </Typography>
          </Box>
        )}
        <DialogActions>
          <Button variant='outlined' onClick={onCancel} color='primary' disabled={isSubmitting}>
            Cancel
          </Button>

          <Button
            type='submit'
            form='edit-subjects-form'
            variant='contained'
            color='primary'
            disabled={isSubmitting}
          >
            Save
            {isSubmitting && <CircularProgress size={24} style={{ position: 'absolute' }} />}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default function Subjects({ tutor }) {
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const { categories } = useCategories();
  const { id } = tutor || user;
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  const handleOnCancel = useCallback(() => {
    setOpen(false);
  }, []);

  const subjects = useMemo(() => (tutor || user)?.subjects || [], [tutor, user]);

  const selectedCategories = useMemo(
    () => (subjects.length ? [...new Set(subjects.map((s) => s.category))].sort() : []),
    [subjects]
  );

  return (
    <>
      <Grid container spacing={2} wrap='nowrap' justifyContent='space-between'>
        <Grid item>
          <Typography variant='h4' color='textSecondary'>
            Subjects
          </Typography>
        </Grid>
        {!tutor && (
          <Grid item>
            <Tooltip title='Edit Subjects' arrow>
              <IconButton onClick={() => setOpen(true)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <Box pt={1} pb={4}>
        <Divider />
      </Box>

      <Grid container spacing={4}>
        {selectedCategories.map((c) => (
          <Grid item xs={12} key={c}>
            <Typography variant='h6' gutterBottom>
              {c}
            </Typography>
            {subjects
              .filter((s) => s.category === c)
              .map((s, i) => (
                <Grid container key={s.id} alignItems='center'>
                  <Grid item xs={6}>
                    <Typography>{s.name}</Typography>
                  </Grid>

                  <Grid item xs={6} container>
                    {s.levels.map((l, i) => (
                      <Chip
                        key={l}
                        label={l}
                        size={xs ? 'small' : 'medium'}
                        style={{ margin: 2 }}
                      />
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <Box py={2}>
                      <Divider />
                    </Box>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        ))}

        {!subjects.length && (
          <Box textAlign='center' width='100%' py={4}>
            <Typography color='textSecondary'>No results</Typography>
          </Box>
        )}
      </Grid>

      {open && (
        <SubjectsDialogContent
          categories={categories}
          tutorId={id}
          tutorSelectedSubjects={subjects}
          onCancel={handleOnCancel}
        />
      )}
    </>
  );
}
