import React, { useCallback, useState } from 'react';
import { Grid, MenuItem, Slider, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { debounce } from 'lodash';
import { useStyles } from './style';
import { getAddressByPostcode } from 'services/hereMaps';
import { filterOptions, getOptionLabel, getOptionSelected } from 'utils/subjectAutocomplete';
import { useSubjects } from 'providers/Subjects';
import { useFilters } from 'providers';

function SearchHeader() {
  const classes = useStyles();
  const { subjects, loading } = useSubjects();
  const { filters, setFilters } = useFilters();
  const [postcodeError, setPostcodeError] = useState(null);
  const [travelDistance, setTravelDistance] = useState(filters?.travelDistance || 1);

  const handleSubjectChange = useCallback(
    (e, value, reason) => {
      setFilters((filters) => ({ ...filters, subject: value, level: null }));
    },
    [setFilters]
  );

  const handleLevelChange = useCallback(
    (e) =>
      setFilters((filters) => ({
        ...filters,
        level: e.target.value === 'none' ? null : e.target.value,
      })),
    [setFilters]
  );

  const handleZipChange = debounce(async (e) => {
    const value = e.target.value?.trim();

    if (!value) {
      setPostcodeError(null);
      return setFilters({ ...filters, address: null });
    }

    try {
      const res = await getAddressByPostcode(value);
      // get the exact postcode only
      const item = res.data.items?.find(
        (item) => item?.scoring?.queryScore === 1 && item?.scoring?.fieldScore?.postalCode === 1
      );

      if (!item) {
        throw new Error('Postcode not found');
      }

      setPostcodeError(null);
      setFilters((prevFilters) => ({ ...prevFilters, address: item }));
    } catch (error) {
      setFilters((prevFilters) => ({ ...prevFilters, address: null }));
      if (error.response?.status === 400) {
        return setPostcodeError('Invalid postcode format');
      }
      setPostcodeError(error.message);
    }
  }, 500);

  const handleTutorMentorChange = useCallback(
    (e) => setFilters((prevFilters) => ({ ...prevFilters, searchFor: e.target.value })),
    [setFilters]
  );

  const handleChangeTravelDistance = useCallback((e, value) => {
    setTravelDistance(value);
  }, []);

  const handleSetTravelDistance = useCallback(
    (e, value) => setFilters((prevFilters) => ({ ...prevFilters, travelDistance: value })),
    [setFilters]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <Autocomplete
          filterOptions={filterOptions}
          fullWidth
          id='subject-combo-box'
          loading={loading}
          options={subjects}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          onChange={handleSubjectChange}
          value={filters.subject}
          renderInput={(params) => (
            <TextField
              name='subject'
              label='Subject'
              placeholder='Enter your subject'
              variant='outlined'
              {...params}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <TextField
          name='level'
          label='Level'
          fullWidth
          id='level-combo-box'
          options={filters.subject?.levels || []}
          value={filters.level || 'none'}
          variant='outlined'
          onChange={handleLevelChange}
          select
          disabled={!filters?.subject?.levels?.length}
        >
          <MenuItem value={'none'}>
            <em>Select Level</em>
          </MenuItem>
          {filters.subject?.levels?.map((level, index) => (
            <MenuItem key={`level_${index}`} value={level}>
              {level}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <TextField
          variant='outlined'
          value={filters.searchFor || 'tutor'}
          fullWidth
          label='Search For'
          select
          onChange={handleTutorMentorChange}
        >
          <MenuItem value='tutor'>Tutor</MenuItem>
          <MenuItem value='mentor'>Mentor</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <TextField
          variant='outlined'
          value={filters.inPerson || false}
          fullWidth
          label='Type'
          select
          onChange={(e) => {
            const value = e.target.value;
            setFilters((prevFilters) => ({
              ...prevFilters,
              inPerson: value,
              address: null,
              travelDistance: 1,
            }));

            if (!value) {
              setTravelDistance(1);
            }
          }}
        >
          <MenuItem value={false}>Online</MenuItem>
          <MenuItem value={true}>In Person</MenuItem>
        </TextField>
      </Grid>

      {filters?.inPerson && (
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            defaultValue={filters.address?.address?.postalCode}
            onChange={handleZipChange}
            fullWidth
            label='Postcode'
            placeholder='Enter Zip'
            name='zip'
            variant='outlined'
            error={Boolean(postcodeError)}
            helperText={postcodeError}
          />
        </Grid>
      )}

      {filters?.inPerson && (
        <Grid item xs={12} sm={12} md={6}>
          <Typography style={{ textAlign: 'center' }}>
            <strong>Distance </strong>
            within <strong>{travelDistance}</strong> miles
          </Typography>
          <Slider
            className={classes.slider}
            min={1}
            max={50}
            valueLabelDisplay='auto'
            value={travelDistance}
            onChange={handleChangeTravelDistance}
            onChangeCommitted={handleSetTravelDistance}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default React.memo(SearchHeader);
