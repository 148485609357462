import React from 'react';
import { Link } from 'react-router-dom';
import { Link as MuiLink } from '@material-ui/core';

function CustomLink({ to, children, ...props }, ref) {
  return (
    <MuiLink variant='body1' component={to ? Link : undefined} to={to} ref={ref} {...props}>
      {children}
    </MuiLink>
  );
}

export default React.forwardRef(CustomLink);
