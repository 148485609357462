import React, { useEffect, useMemo, useState } from 'react';
import { Box, Container, Divider, Tab, Tabs, Typography } from '@material-ui/core';
import { useTutors } from 'providers/Tutors';
import { useStudents } from 'providers/Students';
import { useCallback } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getTutorColumns } from './tutorsColumns';
import { getStudentColumns } from './studentsColumns';
import { useConfirmation } from 'providers';
import { deleteWPUser, updateWPUser } from 'services/wp/users';
import TabPanel from 'components/TabPanel';
import TutorDetails from './TutorDetails';

const tabProps = (index) => ({
  id: `bookings-tab-${index}`,
  'aria-controls': `bookings-tabpanel-${index}`,
});

const rowsPerPageOptions = [5, 10, 20];

function Users() {
  const { tutors, loading: loadingTutors, loadTutors, tutorsLoaded, setTutors } = useTutors();
  const { students, loadingStudents, loadStudents, studentsLoaded, setStudents } = useStudents();
  const confirmation = useConfirmation();
  const [pageSize, setPageSize] = useState(5);
  const [usersTab, setUsersTab] = useState(0);
  const [loadingRowId, setLoadingRowId] = useState(null);
  const [selectedTutor, setSelectedTutor] = useState(null);

  useEffect(() => {
    if (!loadingTutors && !tutorsLoaded) {
      loadTutors();
    }
  }, [loadTutors, loadingTutors, tutorsLoaded]);

  useEffect(() => {
    if (!loadingStudents && !studentsLoaded) {
      loadStudents();
    }
  }, [loadStudents, loadingStudents, studentsLoaded]);

  const handleUsersTabChange = useCallback((e, value) => {
    setUsersTab(value);
    setSelectedTutor(null);
  }, []);

  const handleTutorRowClick = useCallback(({ row }, event) => {
    setSelectedTutor(row);
  }, []);

  const handleDeleteUser = useCallback(
    async (user) => {
      const confirmed = await confirmation(
        <>
          <strong>{user.name}</strong> will be deleted.
        </>
      );

      if (confirmed) {
        setLoadingRowId(user.id);

        try {
          // delete user
          await deleteWPUser(user.id);

          if (selectedTutor && user.id === selectedTutor.id) {
            setSelectedTutor(null);
          }

          const isTutor = user.id === selectedTutor?.id || tutors.some(({ id }) => id === user.id);
          if (isTutor) {
            setTutors((prevTutors) => prevTutors.filter((tutor) => tutor.id !== user.id));
          } else {
            setStudents((prevStudents) => prevStudents.filter((student) => student.id !== user.id));
          }
        } catch (error) {}

        setLoadingRowId(null);
      }
    },
    [confirmation, selectedTutor, setStudents, setTutors, tutors]
  );

  const handleUpdateTutorStatus = useCallback(
    async (tutor, profileStatus) => {
      setLoadingRowId(tutor.id);
      try {
        const newTutorData = (
          await updateWPUser(tutor.id, {
            meta: { profile_status: profileStatus },
          })
        ).data;

        setTutors((prevTutors) =>
          prevTutors.map((tutor) =>
            tutor.id === newTutorData.id ? { ...tutor, ...newTutorData } : tutor
          )
        );
      } catch (error) {}
      setLoadingRowId(null);
    },
    [setTutors]
  );

  const tutorColumns = useMemo(
    () => getTutorColumns(loadingRowId, handleDeleteUser, handleUpdateTutorStatus),
    [handleDeleteUser, handleUpdateTutorStatus, loadingRowId]
  );

  const studentColumns = useMemo(
    () => getStudentColumns(loadingRowId, handleDeleteUser),
    [handleDeleteUser, loadingRowId]
  );

  return (
    <Box pt={18}>
      <Container>
        <Box textAlign='center' pb={10}>
          <Typography variant='h2'>Users</Typography>
        </Box>

        <Tabs
          value={usersTab}
          onChange={handleUsersTabChange}
          aria-label='Users tabs'
          variant='fullWidth'
          scrollButtons='auto'
        >
          <Tab label='Tutors' {...tabProps(0)} />
          <Tab label='Students' {...tabProps(1)} />
        </Tabs>
        <Divider />
        <Box py={10}>
          <TabPanel value={usersTab} index={0}>
            <DataGrid
              rows={tutors}
              columns={tutorColumns}
              loading={loadingTutors || Boolean(loadingRowId)}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
              rowsPerPageOptions={rowsPerPageOptions}
              onRowClick={handleTutorRowClick}
              autoHeight
              components={{ Toolbar: GridToolbar }}
            />
          </TabPanel>
          <TabPanel value={usersTab} index={1}>
            <DataGrid
              rows={students}
              columns={studentColumns}
              loading={loadingStudents || Boolean(loadingRowId)}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
              rowsPerPageOptions={rowsPerPageOptions}
              autoHeight
              components={{ Toolbar: GridToolbar }}
            />
          </TabPanel>
        </Box>
      </Container>
      {selectedTutor && <TutorDetails tutor={selectedTutor} />}
    </Box>
  );
}

export default Users;
