import React from 'react';
import { Redirect, Route } from 'react-router';
import { routes } from 'routes';
import { useAuth } from 'providers/Auth';
import { getUserRole } from 'utils/user';

export default function PrivateRoute({ path, component, render, exact, roles = [], ...props }) {
  const { user } = useAuth();

  if (!user) {
    return <Redirect to={{ pathname: routes.login, state: { redirectTo: path } }} />;
  }

  if (roles.length && !roles.includes(getUserRole(user))) {
    return <Redirect to={routes.home} />;
  }

  return <Route exact={exact} path={path} component={component} render={render} {...props} />;
}
