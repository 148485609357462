import React, { useMemo, useState, useCallback } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { collection, query, where } from 'firebase/firestore';
import { useAuth, useConfirmation } from 'providers';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { db } from 'config/firebaseConfig';
import { getUserRole } from 'utils/user';
import { DataGrid } from '@mui/x-data-grid';
import { getDateFromObject, prettifyDateTime } from 'utils/date';
import { ROLES } from 'enums';
import { theme } from 'themes';
import { routes } from 'routes';
import {
  deleteConsultationRequest,
  updateConsultationRequest,
} from 'services/firebase/consultation';
import PageLayout from 'components/PageLayout';
import CustomLink from 'components/CustomLink';
import history from 'services/history';
import LoadingButton from 'components/LoadingButton';

const getColumns = (userRole) => [
  {
    field: 'createdAt',
    headerName: 'Created At',
    valueGetter: ({ value }) => prettifyDateTime(getDateFromObject(value)),
    flex: true,
    minWidth: 200,
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: ({ value }) => <span style={{ textTransform: 'capitalize' }}>{value}</span>,
    flex: true,
    minWidth: 200,
  },
  {
    field: 'completedAt',
    headerName: 'Completed On',
    valueGetter: ({ value }) => prettifyDateTime(getDateFromObject(value)),
    flex: true,
    minWidth: 200,
  },
  {
    field: userRole === ROLES.STUDENT ? ROLES.TUTOR : ROLES.STUDENT,
    headerName: userRole === ROLES.STUDENT ? 'Tutor' : 'Student',
    flex: true,
    minWidth: 200,
    valueGetter: ({ value }) => value?.name,
    renderCell: ({ value, row }) => (
      <Grid container direction='column' justifyContent='center'>
        <span
          style={{
            lineHeight: 1.3,
            textTransform: 'capitalize',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 220,
          }}
        >
          <strong>{value}</strong>
        </span>
        <Typography
          variant='caption'
          style={{
            lineHeight: 1.3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 220,
          }}
        >
          {row[userRole === 'student' ? 'tutor' : 'student']?.email}
        </Typography>
      </Grid>
    ),
  },
];

function Consultations() {
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const confirm = useConfirmation();
  const { user } = useAuth();
  const [selectedRow, setSelectedRow] = useState(null);
  const [pageSize, setPageSize] = useState(5);
  const [consultations = [], loadingConsultations] = useCollectionData(
    user
      ? query(collection(db, 'consultations'), where(`${getUserRole(user)}.id`, '==', user.id))
      : undefined,
    { idField: 'id' }
  );
  const [loadingConsultationId, setLoadingConsultationId] = useState(null);
  const [markedForCompletion, setMarkedForCompletion] = useState(null);

  const handleRowClick = useCallback((params) => {
    setSelectedRow(params.row);
  }, []);

  const handleConsultationComplete = async (e) => {
    e.preventDefault();
    const consultationComplete = e.target.consultationComplete?.checked;
    if (!consultationComplete) {
      return;
    }

    setLoadingConsultationId(markedForCompletion);
    try {
      await updateConsultationRequest(markedForCompletion?.id, {
        status: 'completed',
        completedAt: new Date(),
      });
      setMarkedForCompletion(null);
    } catch (error) {}
    setLoadingConsultationId(null);
  };

  const columns = useMemo(() => getColumns(getUserRole(user)), [user]);
  const pendingRequests = useMemo(
    () => consultations.filter((consultation) => consultation.status !== 'completed'),
    [consultations]
  );

  return (
    <>
      <PageLayout>
        <Box pb={10} textAlign='center'>
          <Typography variant='h2'>Consultations Requests</Typography>
        </Box>

        <Typography variant='h4' gutterBottom>
          Pending Requests
        </Typography>

        <Box mb={10} style={{ maxHeight: 1000, overflowY: 'auto' }}>
          {pendingRequests?.length ? (
            pendingRequests.map((consultation) => (
              <Box
                key={consultation.id}
                style={{
                  backgroundColor: theme.palette.grey[100],
                  borderRadius: theme.shape.borderRadius,
                  border: `1px solid ${theme.palette.divider}`,
                }}
                p={2}
                mb={4}
              >
                <Typography variant='h6'>
                  <strong>{getUserRole(user) === ROLES.TUTOR ? 'Student' : 'Tutor'}</strong>{' '}
                </Typography>
                <Typography>
                  <strong>Name:</strong>{' '}
                  {consultation[getUserRole(user) === ROLES.TUTOR ? 'student' : 'tutor']?.name}
                </Typography>
                <Typography>
                  <strong>Email:</strong>{' '}
                  <CustomLink
                    href={`mailto:${
                      consultation[getUserRole(user) === ROLES.TUTOR ? 'student' : 'tutor']?.email
                    }`}
                  >
                    {consultation[getUserRole(user) === ROLES.TUTOR ? 'student' : 'tutor']?.email}
                  </CustomLink>
                </Typography>
                {getUserRole(user) === ROLES.TUTOR && (
                  <Typography>
                    <strong>Phone:</strong>{' '}
                    <CustomLink href={`tel:${consultation['student']?.phone}`}>
                      {consultation['student']?.phone}
                    </CustomLink>
                  </Typography>
                )}

                <br />
                <Typography variant='h6'>
                  <strong>Intro</strong>{' '}
                </Typography>
                <Typography>{consultation.intro}</Typography>

                <br />
                <Typography variant='h6'>
                  <strong>Created At</strong>{' '}
                </Typography>
                <Typography>
                  {prettifyDateTime(getDateFromObject(consultation.createdAt))}
                </Typography>
                <Box pt={2}>
                  <Grid container spacing={2} justifyContent='flex-end'>
                    <Grid item>
                      <LoadingButton
                        color='default'
                        loading={loadingConsultationId === consultation.id}
                        fullWidth={xs}
                        variant='contained'
                        onClick={() =>
                          history.push(routes.messages, {
                            chatWith:
                              consultation?.[
                                getUserRole(user) === ROLES.STUDENT ? ROLES.TUTOR : ROLES.STUDENT
                              ]?.id,
                          })
                        }
                        startIcon={<Icon color='primary'>forum</Icon>}
                      >
                        Message {getUserRole(user) === ROLES.STUDENT ? ROLES.TUTOR : ROLES.STUDENT}
                      </LoadingButton>
                    </Grid>
                    <Grid item>
                      {getUserRole(user) === ROLES.TUTOR ? (
                        <LoadingButton
                          color='default'
                          loading={loadingConsultationId === consultation.id}
                          variant='contained'
                          startIcon={
                            <Icon style={{ color: theme.palette.success.main }}>check_icon</Icon>
                          }
                          onClick={() => {
                            setMarkedForCompletion(consultation);
                          }}
                        >
                          Mark as completed
                        </LoadingButton>
                      ) : (
                        <Button
                          variant='contained'
                          startIcon={<Icon color='error'>clear_icon</Icon>}
                          onClick={async (e) => {
                            const confirmed = await confirm(
                              'This consultation request will be cancelled.'
                            );

                            if (confirmed) {
                              try {
                                await deleteConsultationRequest(consultation.id);
                              } catch (error) {}
                            }
                          }}
                        >
                          Cancel Request
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ))
          ) : (
            <Box
              textAlign='center'
              py={4}
              style={{
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: theme.shape.borderRadius,
              }}
            >
              <Typography color='textSecondary'>
                You don't have any consultation requests.
              </Typography>
            </Box>
          )}
        </Box>

        <Typography variant='h4' gutterBottom>
          Consultation History
        </Typography>

        <DataGrid
          rows={consultations.filter((consultation) => consultation.status === 'completed')}
          columns={columns}
          loading={loadingConsultations}
          autoHeight
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          rowsPerPageOptions={[5, 10, 20]}
          onRowClick={handleRowClick}
        />
      </PageLayout>

      {Boolean(selectedRow) && (
        <Dialog fullWidth open={true} onClose={() => setSelectedRow(null)}>
          <DialogTitle style={{ textAlign: 'center' }}>Request details</DialogTitle>
          <Divider />
          <DialogContent style={{ padding: theme.spacing(4, 2) }}>
            <Box>
              <Typography variant='h6'>
                <strong>{getUserRole(user) === ROLES.TUTOR ? 'Student' : 'Tutor'}</strong>{' '}
              </Typography>
              <Typography>
                <strong>Name:</strong>{' '}
                {selectedRow[getUserRole(user) === ROLES.TUTOR ? 'student' : 'tutor']?.name}
              </Typography>
              <Typography>
                <strong>Email:</strong>{' '}
                <CustomLink
                  href={`mailto:${
                    selectedRow[getUserRole(user) === ROLES.TUTOR ? 'student' : 'tutor']?.email
                  }`}
                >
                  {selectedRow[getUserRole(user) === ROLES.TUTOR ? 'student' : 'tutor']?.email}
                </CustomLink>
              </Typography>
              {getUserRole(user) === ROLES.TUTOR && (
                <Typography>
                  <strong>Phone:</strong>{' '}
                  <CustomLink href={`tel:${selectedRow['student']?.phone}`}>
                    {selectedRow['student']?.phone}
                  </CustomLink>
                </Typography>
              )}

              <br />
              <Typography variant='h6'>
                <strong>Intro</strong>{' '}
              </Typography>
              <Typography>{selectedRow.intro}</Typography>

              <br />
              <Typography variant='h6'>
                <strong>Created At</strong>{' '}
              </Typography>
              <Typography>{prettifyDateTime(getDateFromObject(selectedRow.createdAt))}</Typography>
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              fullWidth={xs}
              variant='outlined'
              color='primary'
              onClick={() => setSelectedRow(null)}
            >
              Close
            </Button>
            <Button
              fullWidth={xs}
              variant='contained'
              color='primary'
              onClick={() =>
                history.push(routes.messages, {
                  chatWith:
                    selectedRow?.[getUserRole(user) === ROLES.STUDENT ? ROLES.TUTOR : ROLES.STUDENT]
                      ?.id,
                })
              }
              endIcon={<Icon>forum</Icon>}
            >
              Get in touch
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {Boolean(markedForCompletion) && (
        <Dialog fullWidth open={true} maxWidth='md'>
          <DialogTitle style={{ textAlign: 'center' }}>Confirm Consultation Complete</DialogTitle>
          <Divider />
          <DialogContent
            style={{
              padding: theme.spacing(4, 2),
            }}
          >
            <form
              id='complete-consultation'
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
              }}
              onSubmit={handleConsultationComplete}
            >
              <Box pb={3}>
                <Icon style={{ fontSize: 100, color: theme.palette.warning.main }}>
                  error_outline
                </Icon>
              </Box>
              <Typography>
                Check the box below to certify the consultation is completed and can be closed.
              </Typography>
              <br />
              <FormControlLabel
                label={'This consultation has been completed'}
                control={<Checkbox required name='consultationComplete' />}
                style={{ textAlign: 'left' }}
              />
            </form>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              fullWidth={xs}
              variant='outlined'
              color='primary'
              disabled={Boolean(loadingConsultationId)}
              onClick={() => {
                if (loadingConsultationId) {
                  return;
                }
                setMarkedForCompletion(null);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={Boolean(loadingConsultationId)}
              type='submit'
              form='complete-consultation'
              fullWidth={xs}
              variant='contained'
              color='primary'
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default Consultations;
