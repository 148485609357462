import React, { createContext, useContext } from 'react';
import { collection, orderBy, query } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { db } from 'config/firebaseConfig';

const QualificationsContext = createContext();
export const useQualifications = () => useContext(QualificationsContext);

export const QualificationsProvider = ({ children }) => {
  const [qualifications = [], loadingQualifications] = useCollectionData(
    query(collection(db, 'qualifications'), orderBy('title', 'asc')),
    {
      idField: 'id',
    }
  );

  return (
    <QualificationsContext.Provider value={{ qualifications, loadingQualifications }}>
      {children}
    </QualificationsContext.Provider>
  );
};
