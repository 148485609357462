import React, { useState } from 'react';
import { Box, Button, Grid, Icon, TextField, Tooltip, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useStyles } from './style';
import { useAuth } from '../../providers/Auth';
import { Redirect } from 'react-router';
import { WP_PASSWORD_RESET_WITH_CODE, WP_SET_NEW_PASSWORD, WP_SITE_URL } from '../../enums';
import { routes } from 'routes';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import logo from 'assets/images/logo.png';
import LoadingButton from 'components/LoadingButton';
import history from 'services/history';
import axios from 'axios';
import { validateEmail } from 'utils/email';

export default function ForgotPassword({ location }) {
  const { user } = useAuth();
  const classes = useStyles();
  const [isSubmitting, setSubmitting] = useState(false);
  const [codeSentConfirmation, setCodeSentConfirmation] = useState(null);
  const [error, setError] = useState(null);
  const [passwordResetConfirmation, setPasswordResetConfirmation] = useState(null);
  const [email, setEmail] = useState(null);
  const [step, setStep] = useState(0);

  const handleSendCode = async (e) => {
    e.preventDefault();
    const email = e.target.email?.value?.trim();

    if (!validateEmail(email)) {
      return setError('Invalid email address');
    }

    if (error) {
      setError(null);
    }

    setSubmitting(true);
    try {
      const res = await axios.post(WP_PASSWORD_RESET_WITH_CODE, { email });
      setCodeSentConfirmation(res.data.message);
      setEmail(email);
      setStep((prevStep) => prevStep + 1);
    } catch (error) {
      setError(error.response?.data?.message);
    }
    setSubmitting(false);
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    const code = e.target.code.value?.trim();
    const password = e.target.password.value?.trim();

    if (code.length < 4) {
      return setError('Reset code must be 4 digits');
    }

    if (password.length < 5) {
      return setError(
        'The new password is too short! Please lengthen your password to at least 5 characters.'
      );
    }

    setError(null);

    setSubmitting(true);
    try {
      const res = await axios.post(WP_SET_NEW_PASSWORD, {
        email,
        password,
        code,
      });
      setPasswordResetConfirmation(res.data.message);
      setStep((prevStep) => prevStep + 1);
    } catch (error) {
      setError(error.response?.data?.message);
      setCodeSentConfirmation(null);
    }
    setSubmitting(false);
  };

  if (user) {
    return <Redirect to={routes.home} />;
  }

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.loginContainer}>
        <Box p={4}>
          <a href={WP_SITE_URL}>
            <img src={logo} alt='logo' style={{ maxWidth: '100%', marginBottom: 16 }} />
          </a>
        </Box>

        {passwordResetConfirmation ? (
          <Box>
            <Alert variant='outlined' severity={'success'}>
              {passwordResetConfirmation}
            </Alert>
            <Box pt={2}></Box>
          </Box>
        ) : (
          <form onSubmit={step === 0 ? handleSendCode : handleResetPasswordSubmit}>
            {step === 0 && (
              <>
                <Box textAlign={'center'}>
                  <Typography variant='h6'>Forgot your password?</Typography>
                </Box>
                <Box textAlign={'center'} pt={2} pb={4}>
                  <Typography variant='body2'>
                    Tell us your email address, and we'll get you back on track in no time.
                  </Typography>
                </Box>{' '}
              </>
            )}

            <Grid container direction='column' spacing={2}>
              {codeSentConfirmation && (
                <Grid item>
                  <Alert variant='outlined' severity={'success'}>
                    {codeSentConfirmation}
                  </Alert>
                </Grid>
              )}

              {error && (
                <Grid item>
                  <Alert variant='outlined' severity='error'>
                    {error}
                  </Alert>
                </Grid>
              )}

              {step === 1 && (
                <Grid item>
                  <Typography>
                    Password reset code{' '}
                    <Tooltip title="We want to make sure it's really you so we've sent a password reset code to your email address.">
                      <Icon style={{ verticalAlign: 'text-bottom' }} color='action'>
                        info_icon
                      </Icon>
                    </Tooltip>
                  </Typography>
                  <TextField
                    autoFocus
                    fullWidth
                    inputProps={{ minLength: 4, maxLength: 4 }}
                    name='code'
                    onKeyDown={(e) =>
                      ((e.target.value.length > 3 && e.key !== 'Backspace') ||
                        ['e', 'E', '-', '+', '.'].includes(e.key)) &&
                      e.preventDefault()
                    }
                    required
                    variant='outlined'
                  />
                </Grid>
              )}

              {step === 1 && (
                <Grid item>
                  <Typography>New password</Typography>
                  <TextField
                    inputProps={{ minLength: 5 }}
                    required
                    fullWidth
                    variant='outlined'
                    type='password'
                    name='password'
                  />
                </Grid>
              )}

              {step === 0 && (
                <Grid item>
                  <Typography>Email Address</Typography>
                  <TextField
                    autoFocus
                    required
                    fullWidth
                    variant='outlined'
                    type='email'
                    name='email'
                  />
                </Grid>
              )}

              <Grid item>
                <LoadingButton
                  endIcon={
                    step === 0 ? (
                      <KeyboardBackspaceIcon style={{ transform: 'rotate(180deg)' }} />
                    ) : undefined
                  }
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                >
                  {step === 0 ? 'Continue' : 'Reset Password'}
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        )}
        {step !== 2 && (
          <Box py={2}>
            <Typography className={classes.dividerWithText}>or</Typography>
          </Box>
        )}

        <Grid item>
          <Button
            type='button'
            startIcon={<KeyboardBackspaceIcon />}
            fullWidth
            variant='outlined'
            color='primary'
            onClick={() => history.push(routes.login)}
          >
            Back to Login
          </Button>
        </Grid>
      </Box>
    </Box>
  );
}
