import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  messagesList: {
    listStyleType: 'none',
    overflowY: 'auto',
    height: '100%',
  },
  message: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
    whiteSpace: 'pre-wrap',
    overflowWrap: 'anywhere',
    maxWidth: '90%',
    width: 'fit-content',
    // borderRadius: theme.shape.borderRadius,
    '&.sent': {
      marginLeft: 'auto',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderRadius: theme.spacing(1.5, 1.5, 0, 1.5),
    },
    '&.received': {
      marginRight: 'auto',
      backgroundColor: theme.palette.secondary.main,
      fontWeight: 500,
      borderRadius: theme.spacing(0, 1.5, 1.5, 1.5),
    },
  },
  sendButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  chatHeaderPhoto: {
    height: 50,
    width: 50,
    borderRadius: '50%',
    marginLeft: theme.spacing(2),
    objectFit: 'cover',
    objectPosition: 'center',
  },
}));
