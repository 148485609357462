import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useStyles } from "./style";
import { useAuth } from "../../providers/Auth";
import { Redirect } from "react-router";
import { APP_TITLE, WP_SITE_URL } from "../../enums";
import { wpLogin } from "../../services/wp/users";
import { routes } from "routes";
import CircularProgress from "@material-ui/core/CircularProgress";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import logo from "assets/images/logo.png";
import CustomLink from "components/CustomLink";
// import history from "services/history";

export default function Login({ location }) {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { user, setUser } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSubmitting(true);
    const username = e.target.username.value;
    const password = e.target.password.value;

    try {
      const user = await wpLogin(username, password);
      setUser(user);
    } catch (error) {
      setError(error.response?.data?.message || "Invalid username or password");
      setSubmitting(false);
    }
  };

  if (user) {
    return <Redirect to={location?.state?.redirectTo || routes.home} />;
  }

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.loginContainer}>
        <Box p={4}>
          <img
            src={logo}
            alt="logo"
            style={{ maxWidth: "100%", marginBottom: 16 }}
          />
        </Box>
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={2}>
            {error && (
              <Grid item>
                <Alert variant="outlined" severity="error">
                  <div dangerouslySetInnerHTML={{ __html: error }}></div>
                </Alert>
              </Grid>
            )}
            <Grid item>
              <Typography variant="body2">Username or Email Address</Typography>
              <TextField
                autoFocus
                required
                fullWidth
                variant="outlined"
                type="text"
                name="username"
              />
            </Grid>
            <Grid item>
              <Typography variant="body2">Password</Typography>
              <TextField
                required
                fullWidth
                variant="outlined"
                type={showPassword ? "text" : "password"}
                name="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => setShowPassword((show) => !show)}
                      >
                        <Icon>
                          {showPassword ? "visibility_off" : "visibility"}
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <Box textAlign="right">
                <CustomLink variant="body2" to={routes.forgotPassword}>
                  Forgot password?
                </CustomLink>
              </Box>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Log In
                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    style={{ position: "absolute" }}
                  />
                )}
              </Button>
            </Grid>
            <Grid item>
              <Typography className={classes.dividerWithText}>or</Typography>
            </Grid>
            <Grid item>
              <Button
                type="button"
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() =>
                  window.location.assign(WP_SITE_URL + "/register")
                }
              >
                Become a Tutor / Mentor
              </Button>
            </Grid>
            {/* <Grid item>
              <Button
                type="button"
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => history.push(routes.registerAsStudent)}
              >
                Register as Student
              </Button>
            </Grid> */}
            <Grid item>
              <Button
                type="button"
                startIcon={<KeyboardBackspaceIcon />}
                fullWidth
                variant="outlined"
                color="primary"
                href={WP_SITE_URL}
                component={CustomLink}
                style={{ textDecoration: "none" }}
              >
                Go to {APP_TITLE}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
}
