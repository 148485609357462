import React, { useCallback, useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Slider,
  Typography,
  Checkbox,
  Divider,
  RadioGroup,
  Radio,
  FormGroup,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { MIN_RATE, POUND_SYMBOL } from 'enums';
import { useStyles } from './style';
import { theme } from 'themes';

const min = MIN_RATE;
const max = 80;

function FiltersDialog({ open, onClose, filters, setFilters }) {
  const classes = useStyles();
  const [priceRange, setPriceRange] = useState([min, max]);

  const handleChangePriceRange = useCallback((e, value) => {
    setPriceRange(value);
  }, []);

  const handleSetPriceRange = useCallback(
    (e, value) => setFilters((prevFilters) => ({ ...prevFilters, priceRange: value })),
    [setFilters]
  );

  const handleAvailabilityChange = useCallback(
    ({ target: { name, checked } }) =>
      setFilters((prevFilters) => {
        const prevAvailability = prevFilters.availability || [];
        return {
          ...prevFilters,
          availability: checked
            ? [...prevAvailability, name]
            : prevAvailability.filter((a) => a !== name),
        };
      }),
    [setFilters]
  );

  const handleAdditionalFiltersChange = useCallback(
    ({ target: { name, checked } }) =>
      setFilters((prevFilters) => {
        const prevAdditionalFilters = prevFilters.additionalFilters || [];
        return {
          ...prevFilters,
          additionalFilters: checked
            ? [...prevAdditionalFilters, name]
            : prevAdditionalFilters.filter((af) => af !== name),
        };
      }),

    [setFilters]
  );

  const handleGenderChange = useCallback(
    (e, value) =>
      setFilters((prevFilters) => ({ ...prevFilters, gender: value === '-' ? null : value })),
    [setFilters]
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Box textAlign='center'>Filters</Box>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ padding: theme.spacing(2) }}>
        <Typography component='div'>
          <strong>Price</strong>
          <br />
          <Box textAlign='center'>
            <span>
              {POUND_SYMBOL}
              {priceRange[0].toFixed(2)} - {POUND_SYMBOL}
              {priceRange[1].toFixed(2)}
              {priceRange[1] === max && '+'}/hr
            </span>
          </Box>
        </Typography>
        <Box px={2}>
          <Slider
            className={classes.slider}
            min={min}
            max={max}
            valueLabelDisplay='auto'
            value={priceRange}
            onChange={handleChangePriceRange}
            onChangeCommitted={handleSetPriceRange}
          />
        </Box>

        <Box pt={2}>
          <Typography>
            <strong>Availability</strong>
          </Typography>
          <Box pl={2}>
            <FormGroup onChange={handleAvailabilityChange}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='morning'
                    checked={filters.availability?.includes('morning') || false}
                  />
                }
                label='Morning (8am - 11am)'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name='afternoon'
                    checked={filters.availability?.includes('afternoon') || false}
                  />
                }
                label='Afternoon (12pm - 5pm)'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name='evening'
                    checked={filters.availability?.includes('evening') || false}
                  />
                }
                label='Evening (6pm - 10pm)'
              />
            </FormGroup>
          </Box>
        </Box>

        <Box pt={2}>
          <Typography>
            <strong>Gender</strong>
          </Typography>
          <Box pl={2}>
            <RadioGroup
              aria-label='gender'
              value={filters.gender || '-'}
              name='radio-buttons-group'
              onChange={handleGenderChange}
            >
              <FormControlLabel value='M' control={<Radio />} label='Male' />
              <FormControlLabel value='F' control={<Radio />} label='Female' />
              <FormControlLabel value='-' control={<Radio />} label='Any' />
            </RadioGroup>
          </Box>
        </Box>

        <Box pt={2}>
          <Typography>
            <strong>Additional Filters</strong>
          </Typography>
          <Box pl={2}>
            <FormGroup onChange={handleAdditionalFiltersChange}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='DBS'
                    checked={filters.additionalFilters?.includes('DBS') || false}
                  />
                }
                label='DBS Check'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name='QTS'
                    checked={filters.additionalFilters?.includes('QTS') || false}
                  />
                }
                label='Qualified Teacher'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name='SEND'
                    checked={filters.additionalFilters?.includes('SEND') || false}
                  />
                }
                label='SEND specialism'
              />
            </FormGroup>
          </Box>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant='outlined' color='primary' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(FiltersDialog);
