import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { db } from 'config/firebaseConfig';
import { collection, query } from 'firebase/firestore';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { DataGrid } from '@mui/x-data-grid';
import { getDateFromObject, prettifyDateTime } from 'utils/date';
import { routes } from 'routes';
import { useStyles } from './style';
import { theme } from 'themes';
import { useAuth } from 'providers';
import { useMemo } from 'react';
import history from 'services/history';

const columns = [
  {
    field: 'createdAt',
    headerName: 'Created At',
    renderHeader: () => (
      <Grid container alignItems='center' wrap='nowrap'>
        <Icon>{'event_icon'}</Icon> &nbsp; Created At
      </Grid>
    ),
    flex: 0.2,
    minWidth: 170,
    type: 'date',
    valueGetter: ({ value }) => getDateFromObject(value),
    renderCell: ({ value }) => prettifyDateTime(value),
  },
  {
    field: 'subject',
    flex: 0.4,
    minWidth: 220,
    headerName: 'Subject / Level',
    renderHeader: () => (
      <Grid container alignItems='center' wrap='nowrap'>
        <Icon>{'subject_icon'}</Icon>&nbsp; Subject / Level
      </Grid>
    ),
    renderCell: ({ row }) => (
      <Grid container direction='column'>
        <span style={{ lineHeight: 1.3, fontWeight: 'bold' }}>{row.subject.name}</span>
        <Typography variant='caption' style={{ lineHeight: 1.3 }}>
          {row.level || '-'}
        </Typography>
      </Grid>
    ),
    valueGetter: ({ value }) => value?.name,
    sortComparator: (v1, v2) => String(v1.name).localeCompare(String(v2.name)),
  },
  {
    field: 'user',
    headerName: 'User',
    flex: 0.4,
    minWidth: 300,
    renderHeader: () => (
      <Grid container alignItems='center' wrap='nowrap'>
        <Icon>{'person_icon'}</Icon>&nbsp;
        <span>Posted By</span>
      </Grid>
    ),
    valueGetter: ({ value }) => value?.name,
    renderCell: ({ value, row }) => (
      <Grid item container direction='column'>
        <span style={{ lineHeight: 1.3 }}>
          <strong>{value}</strong>
        </span>
        <Typography variant='caption' style={{ lineHeight: 1.3 }}>
          {row.user?.email}
        </Typography>
      </Grid>
    ),
  },
];

const initialSortModel = {
  field: 'createdAt',
  sort: 'asc',
};

function Requests() {
  const classes = useStyles();
  const { user } = useAuth();
  const [requests = [], loading] = useCollectionDataOnce(query(collection(db, 'requests')), {
    idField: 'id',
  });
  const [pageSize, setPageSize] = useState(5);
  const [sortModel, setSortModel] = useState([initialSortModel]);
  const [selectedRow, setSelectedRow] = useState(null);
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  const filteredRequests = useMemo(
    () =>
      requests.filter((r) =>
        user.subjects?.some((s) =>
          r.level
            ? s.category === r.subject.category &&
              s.name === r.subject.name &&
              s.levels?.includes(r.level)
            : s.category === r.subject.category && s.name === r.subject.name
        )
      ),
    [requests, user.subjects]
  );

  const handleRowClick = useCallback((params) => {
    setSelectedRow(params.row);
  }, []);

  return (
    <Box pt={18} pb={10}>
      <Container>
        <Box pb={10} textAlign='center'>
          <Typography variant='h2'>Requests that match with your criteria</Typography>
        </Box>
        <Box minHeight={400} height='100%' width='100%'>
          <DataGrid
            onRowClick={handleRowClick}
            rows={filteredRequests}
            className={classes.dataGrid}
            columns={columns}
            loading={loading}
            //   disableSelectionOnClick
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            rowsPerPageOptions={[5, 10, 20]}
            autoHeight
            sortModel={sortModel}
            onSortModelChange={setSortModel}
          />
        </Box>
      </Container>
      <Dialog fullWidth open={Boolean(selectedRow)} onClose={() => setSelectedRow(null)}>
        <DialogTitle style={{ textAlign: 'center' }}>Request details</DialogTitle>
        <Divider />
        <DialogContent>
          <Box py={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h6'>Searching for</Typography>
                <Typography style={{ textTransform: 'capitalize' }}>
                  {selectedRow?.searchFor}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>Subject / Level</Typography>
                <Typography style={{ textTransform: 'capitalize' }}>
                  {selectedRow?.subject?.name} / {selectedRow?.level || '-'}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant='h6'>Description</Typography>
                <Typography style={{ whiteSpace: 'pre-wrap' }}>
                  {selectedRow?.description}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>Posted By</Typography>
                <Typography>
                  <strong>Name: </strong> {selectedRow?.user?.name}
                </Typography>
                <Typography>
                  <strong>Email: </strong> {selectedRow?.user?.email}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>Created At</Typography>
                <Typography>
                  {prettifyDateTime(getDateFromObject(selectedRow?.createdAt))}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            fullWidth={xs}
            variant='outlined'
            color='primary'
            onClick={() => setSelectedRow(null)}
          >
            Cancel
          </Button>
          <Button
            fullWidth={xs}
            variant='contained'
            color='primary'
            onClick={() => history.push(routes.messages, { chatWith: selectedRow?.user?.id })}
            endIcon={<Icon>forum</Icon>}
          >
            Get in touch
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Requests;
