import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  loginContainer: {
    background: theme.palette.background.default,
    padding: theme.spacing(4, 2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    maxWidth: 400,
    margin: theme.spacing(10, 2),
  },
  wrapper: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    display: 'grid',
    placeItems: 'center',
    backgroundImage:
      'url(https://createdbydash.uk/booksmarttutors/wp-content/uploads/2021/08/pexels-max-fischer-5212331-scaled.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflow: 'auto',
  },
  dividerWithText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:before': {
      display: 'block',
      content: "''",
      width: '40%',
      height: 1,
      backgroundColor: theme.palette.divider,
    },
    '&:after': {
      display: 'block',
      content: "''",
      width: '40%',
      height: 1,
      backgroundColor: theme.palette.divider,
    },
  },
}));
