import React, { useState } from 'react';
import MuiPhoneInput from 'mui-phone-input-ssr';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Icon, InputAdornment } from '@material-ui/core';
import { theme } from 'themes';

const PhoneInput = ({ defaultValue, ...props }) => {
  const [phoneNumber, setPhoneNumber] = useState(defaultValue);
  const isValidPhone = isValidPhoneNumber(phoneNumber);

  return (
    <MuiPhoneInput
      defaultCountry='gb'
      autoFormat
      variant='outlined'
      fullWidth
      name='phone_number'
      value={phoneNumber}
      onChange={setPhoneNumber}
      error={!isValidPhone}
      helperText={!isValidPhone && 'Invalid phone number'}
      InputProps={
        isValidPhone
          ? {
              endAdornment: (
                <InputAdornment position='end'>
                  <Icon style={{ color: theme.palette.success.main }}>checked</Icon>
                </InputAdornment>
              ),
            }
          : undefined
      }
      {...props}
    />
  );
};

export default React.memo(PhoneInput);
