import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Icon,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { POUND_SYMBOL } from '../../../enums';
import { useStyles } from './style';
import { useAuth } from '../../../providers/Auth';
import { updateWPUser } from '../../../services/wp/users';
import { uploadFile, deleteFile } from '../../../services/firebase/storage';
import { isFileImage } from 'utils/imageFile';
import { PHOTO_PLACEHOLDER } from 'enums';
import { COLORS, theme } from 'themes';
import { useConfirmation, useTutors } from 'providers';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import classNames from 'classnames';
import CustomLink from 'components/CustomLink';
import { collection, query, where } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { db } from 'config/firebaseConfig';
import { getTutorLocation } from 'utils/user';
import { Rating } from '@material-ui/lab';

function BasicInfo({ tutor, isAdmin, hoursTaught }) {
  const { loadTutors } = useTutors();

  const confirmation = useConfirmation();
  const classes = useStyles({ hasEditButton: !tutor });
  const [imageFile, setImageFile] = useState(null);
  const [error, setError] = useState({});
  const [open, setOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const { user, setUser } = useAuth();
  const {
    id,
    first_name,
    last_name,
    meta: { hours_rate, profile_headline, profile_bio, photo_url, tutoring_method } = {},
  } = tutor || user;
  const { location = null } = useMemo(() => getTutorLocation(tutor || user), [tutor, user]);
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const [deletingPhoto, setDeletingPhoto] = useState(false);
  const [uploadedFiles = []] = useCollectionData(
    tutor
      ? query(collection(db, 'filesToApprove'), where('uploadedBy.id', '==', tutor.id))
      : undefined,
    { idField: 'id' }
  );

  const clearErrors = () => Object.keys(error).length && setError({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearErrors();

    const profile_headline = [e.target.profile_headline.value?.trim()];
    const profile_bio = [e.target.profile_bio.value?.trim()];
    const file = e.target.profilePhoto.files[0];

    if (!profile_headline[0]) {
      return setError({
        serverError: 'Profile Headline is a required field!',
      });
    }

    if (!profile_bio[0]) {
      return setError({
        serverError: 'Bio is a required field!',
      });
    }

    if (profile_bio[0].length < 750) {
      return setError({
        serverError: `Please lengthen your Bio to 750 characters or more (you are currently using ${profile_bio[0].length} characters).`,
      });
    }

    setSubmitting(true);

    try {
      const newUserData = {
        first_name,
        last_name,
        name: `${first_name} ${last_name}`,
        nickname: `${first_name} ${last_name}`,

        meta: {
          profile_headline,
          profile_bio,
          photo_url,
        },
      };

      if (imageFile && file) {
        const downloadUrl = await uploadFile(
          `uploads/${id}/profile_pic_${id}.${file.name.split('.').pop()}`,
          file
        );
        newUserData.meta.photo_url = [downloadUrl];
      }

      const updatedUserData = (await updateWPUser(id, newUserData)).data;

      if (updatedUserData) {
        setOpen(false);
        setUser(updatedUserData);
      }
    } catch (error) {
      setError({ serverError: error.response?.data?.message });
    }

    setSubmitting(false);
  };

  const handlePhotoUpload = ({ target: { files } }) => {
    const file = files[0];

    if (!file) {
      return;
    }

    if (!isFileImage(file)) {
      setError({ file: 'File is not an image' });
      return setImageFile(null);
    }

    if (file.size > 2097152) {
      setError({ file: 'The file is too large. Maximum file size allowed: 2MB' });
      return setImageFile(null);
    }

    setImageFile(URL.createObjectURL(file));
  };

  const handleDeletePhoto = async () => {
    const confirmed = await confirmation("Tutor's photo will be deleted?");

    if (confirmed) {
      setDeletingPhoto(true);

      try {
        await updateWPUser(id, { meta: { photo_url: [] } });
        await deleteFile(photo_url[0]);
        setImageLoaded(false);
        loadTutors();
      } catch (error) {}

      setDeletingPhoto(false);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item container wrap='nowrap'>
          <Grid item container spacing={2} style={{ position: 'relative' }}>
            <Grid item xs={xs} style={{ position: 'relative', padding: theme.spacing(2) }}>
              <Box
                hidden={imageLoaded}
                bgcolor={COLORS.lightGrey}
                className={classes.profilePhoto}
                style={!xs ? { width: 250 } : undefined}
              />
              {isAdmin && photo_url[0] && imageLoaded && (
                <Tooltip title='Delete Photo'>
                  <IconButton
                    className={classes.deletePhoto}
                    onClick={handleDeletePhoto}
                    disabled={deletingPhoto}
                  >
                    <Icon>delete_forever_icon</Icon>
                    {deletingPhoto && (
                      <CircularProgress
                        style={{ position: 'absolute', transform: 'translateX(-50)' }}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              )}
              <img
                hidden={!imageLoaded}
                src={photo_url[0] || PHOTO_PLACEHOLDER}
                alt='profile pic'
                className={classNames(classes.profilePhoto, tutor ? 'viewMode' : undefined)}
                onLoad={() => setImageLoaded(true)}
              />
            </Grid>

            <Grid item xs={12} sm container spacing={1} direction='column' justifyContent='center'>
              <Grid item style={{ overflow: 'hidden', maxWidth: '100%' }}>
                <Typography variant={'h3'} className={classes.tutorName}>
                  {first_name} {last_name}
                </Typography>
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              {location && (
                <Grid item container>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    <Icon color='action' style={{ marginRight: 10, verticalAlign: 'middle' }}>
                      my_location_icon
                    </Icon>
                    {location?.address?.city}, {location?.address?.county}
                  </Typography>
                </Grid>
              )}

              {tutor && hoursTaught > 0 && (
                <>
                  <Grid item>
                    <Typography variant='body2' color='textSecondary'>
                      <Icon color='action' style={{ verticalAlign: 'middle', marginRight: 10 }}>
                        access_time
                      </Icon>
                      {hoursTaught}+ hours taught
                    </Typography>
                  </Grid>
                </>
              )}

              {tutor &&
                uploadedFiles.some((file) => file.tag === 'DBS' && file.status === 'approved') && (
                  <>
                    <Grid item container>
                      <Typography
                        variant='body2'
                        color='textSecondary'
                        style={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        <Icon color='action' style={{ marginRight: 10, verticalAlign: 'middle' }}>
                          verified_user_icon
                        </Icon>
                        DBS checked
                      </Typography>
                    </Grid>
                  </>
                )}

              {tutor &&
                uploadedFiles.some((file) => file.tag === 'QTS' && file.status === 'approved') && (
                  <>
                    <Grid item container>
                      <Typography
                        variant='body2'
                        color='textSecondary'
                        style={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        <Icon color='action' style={{ marginRight: 10, verticalAlign: 'middle' }}>
                          school_icon
                        </Icon>
                        Qualified teacher
                      </Typography>
                    </Grid>
                  </>
                )}

              <Grid item container>
                <Typography
                  variant='body2'
                  color='textSecondary'
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  <Icon color='action' style={{ marginRight: 10, verticalAlign: 'middle' }}>
                    person_pin_icon
                  </Icon>
                  Type of tutoring: <strong>Online</strong>
                  {tutoring_method?.includes('In Person') ? (
                    <>
                      {' '}
                      and <strong>In Person</strong>
                    </>
                  ) : null}
                </Typography>
              </Grid>

              {tutor && (
                <>
                  <Grid item>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <CustomLink
                      component='div'
                      variant='body2'
                      style={{
                        cursor: 'pointer',
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        alignItems: 'center',
                        maxWidth: 'fit-content',
                      }}
                      onClick={() =>
                        document
                          .getElementById('reviews-section')
                          ?.scrollIntoView({ behavior: 'smooth' })
                      }
                    >
                      <Rating
                        value={Math.round(
                          tutor.reviews.map((r) => r.rating).reduce((acc, curr) => acc + curr, 0) /
                            tutor.reviews.length
                        )}
                        readOnly
                        size='large'
                      />
                      <span style={{ marginLeft: 10 }}>
                        ({tutor.reviews.length} review{tutor.reviews.length === 1 ? '' : 's'})
                      </span>
                    </CustomLink>
                  </Grid>
                </>
              )}

              {xs && (
                <>
                  <Grid item>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <Typography variant='h5' style={{ fontWeight: 500 }}>
                      {POUND_SYMBOL}
                      {(Number(hours_rate[0]) || 0).toFixed(2)}
                      <span className={classes.perHour}>/hr</span>
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item style={{ position: 'absolute', right: 0 }}>
              <Typography variant='h5' style={{ fontWeight: 500 }}>
                {!xs && (
                  <>
                    {POUND_SYMBOL}
                    {(Number(hours_rate[0]) || 0).toFixed(2)}

                    <span className={classes.perHour}>/hr</span>
                  </>
                )}
                {!tutor && (
                  <Tooltip title='Edit Basic Info' arrow>
                    <IconButton onClick={() => setOpen(true)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box pt={4}>
            <Typography variant='h5'>
              <strong>{profile_headline}</strong>
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Typography component='p' className={classes.profileBio}>
            {profile_bio}
          </Typography>
        </Grid>
      </Grid>

      {open && (
        <Dialog open={open} fullWidth>
          <DialogContent>
            <form onSubmit={handleSubmit} id='basic-info-edit'>
              <Grid container spacing={4}>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent='center'
                  alignItems='center'
                  style={{ position: 'relative' }}
                >
                  <input
                    accept='image/*'
                    id='profile-photo'
                    name='profilePhoto'
                    hidden
                    type='file'
                    onChange={handlePhotoUpload}
                  />
                  <label htmlFor='profile-photo' style={{ position: 'absolute' }}>
                    <Tooltip title='Change photo' arrow>
                      <IconButton component='span'>
                        <PhotoCamera style={{ color: theme.palette.grey[200] }} />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <img
                    src={imageFile || photo_url[0] || PHOTO_PLACEHOLDER}
                    alt='profile pic'
                    className={classNames(classes.profilePhoto, 'upload')}
                  />
                </Grid>

                {error.file && (
                  <Grid item container justifyContent='center'>
                    <Typography color='error'>{error.file}</Typography>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Box>
                    <Typography variant='h6'>Edit your headline</Typography>
                  </Box>
                  <Box py={2}>
                    <Typography color='textSecondary'>
                      A short summary to promote yourself e.g. "Enthusiastic English Tutor"
                    </Typography>
                  </Box>
                  <TextField
                    label='Profile Headline'
                    defaultValue={profile_headline}
                    fullWidth
                    variant='outlined'
                    required
                    name='profile_headline'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box pb={2}>
                    <Typography variant='h6'>Edit your bio</Typography>
                  </Box>
                  <Box pb={4}>
                    <Typography color='textSecondary' gutterBottom>
                      Your bio is where you get the opportunity to sell yourself to potential
                      students. Take your time on this and make sure you proof read it!
                    </Typography>
                    <Typography color='textSecondary' gutterBottom>
                      Prompt questions:
                    </Typography>
                    <Typography color='textSecondary' component='div'>
                      <ol style={{ listStylePosition: 'inside', paddingLeft: 40 }}>
                        <li>What teaching/tutoring experience do you have?</li>
                        <li>
                          If you’re choosing to also offer a mentoring service, what mentoring
                          experience do you have? Are you familiar with the UCAS process?
                        </li>
                        <li>
                          Do you have experience working with learners with special educational
                          needs?
                        </li>
                        <li>
                          Do you have experience working with Russell Group or Oxbridge candidates?
                        </li>
                        <li>
                          What is your understanding of the current curriculum and exam boards?
                        </li>
                        <li>What do you think makes a good lesson?</li>
                      </ol>
                    </Typography>
                  </Box>
                  <TextField
                    label='Bio'
                    defaultValue={profile_bio}
                    fullWidth
                    variant='outlined'
                    required
                    name='profile_bio'
                    InputProps={{
                      multiline: true,
                      minRows: 5,
                    }}
                  />
                  <Typography variant='caption'>*Minimum 750 characters</Typography>

                  {/* <input type='text' minLength={750} required /> */}
                </Grid>
              </Grid>
            </form>
            <br />
          </DialogContent>
          <Divider />
          {error.serverError && (
            <Box p={2} textAlign='center'>
              <Typography color='error'>{error.serverError}</Typography>
            </Box>
          )}
          <DialogActions>
            <Button
              variant='outlined'
              onClick={() => {
                setOpen(false);
                setImageFile(photo_url[0]);
                clearErrors();
              }}
              color='primary'
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              form='basic-info-edit'
              type='submit'
              variant='contained'
              color='primary'
              disabled={isSubmitting}
            >
              Save
              {isSubmitting && <CircularProgress size={24} style={{ position: 'absolute' }} />}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default React.memo(BasicInfo);
