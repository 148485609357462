import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  ratingContainer: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    '& > $reviewStar:hover ~ $reviewStar, $reviewStar.selected ~ $reviewStar': {
      color: theme.palette.warning.main,
    },
  },
  reviewStar: {
    transition: theme.transitions.create('color'),
    color: theme.palette.action.disabled,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.warning.main,
    },
    '&.selected': {
      color: theme.palette.warning.main,
    },
  },
}));
