import { useEffect } from "react";
import "./App.css";
import { routes } from "./routes";
import { useAuth } from "./providers/Auth";
import { useLocation } from "react-router";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { ROLES } from "enums";
import AppBar from "./components/AppBar";
import Login from "pages/Login";
import PrivateRoute from "components/PrivateRoute";
import Home from "pages/Home";
import Search from "pages/Search";
import Profile from "pages/Profile";
import Bookings from "pages/Bookings";
import Messages from "pages/Messages";
import Subjects from "pages/Admin/Subjects";
import PendingReviews from "pages/Admin/PendingReviews";
import Requests from "pages/Requests";
import RequestTutorMentor from "pages/RequestTutorMentor";
import Handbook from "pages/Handbook";
import Account from "pages/Account";
import ReferenceFill from "pages/ReferenceFill";
import Chats from "pages/Admin/Chats";
import Users from "pages/Admin/Users";
import BookingHistory from "pages/Admin/BookingHistory";
import Qualifications from "pages/Admin/Qualifications";
import TutorCertificates from "pages/Admin/TutorCertificates/TutorCertificates";
import ForgotPassword from "pages/ForgotPassword";
// import RegisterAsStudent from "pages/RegisterAsStudent";
import Consultations from "pages/Consultations";
import ConsultationHistory from "pages/Admin/ConsultationHistory";
// import TutorClasses from 'pages/TutorClasses';

const { ADMIN, TUTOR, STUDENT } = ROLES;

function App() {
  const { user } = useAuth();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {user && ![routes.home].includes(pathname) && <AppBar />}
      <main>
        <Switch>
          <Route path={routes.login} component={Login} />
          <Route path={routes.forgotPassword} component={ForgotPassword} />
          {/* <Route path={routes.registerAsStudent} component={RegisterAsStudent} /> */}
          <Route path={`${routes.references}/:id`} component={ReferenceFill} />
          <PrivateRoute exact path={routes.home} component={Home} />
          <PrivateRoute
            path={routes.profile}
            roles={[TUTOR]}
            render={() => <Profile isEditing />}
          />
          <PrivateRoute
            exact
            path={routes.tutors}
            roles={[STUDENT]}
            component={Search}
          />
          <PrivateRoute
            path={`${routes.tutors}/:id`}
            roles={[STUDENT]}
            component={Profile}
          />
          <PrivateRoute
            path={routes.bookings}
            roles={[STUDENT, TUTOR]}
            component={Bookings}
          />
          <PrivateRoute
            path={routes.consultations}
            roles={[STUDENT, TUTOR]}
            component={Consultations}
          />
          <PrivateRoute
            path={routes.messages}
            roles={[STUDENT, TUTOR]}
            component={Messages}
          />
          <PrivateRoute
            path={routes.requestTutorMentor}
            roles={[STUDENT]}
            component={RequestTutorMentor}
          />
          <PrivateRoute
            path={routes.account}
            roles={[STUDENT, TUTOR]}
            component={Account}
          />
          <PrivateRoute
            path={routes.requests}
            roles={[TUTOR]}
            component={Requests}
          />
          <PrivateRoute
            path={routes.handbook}
            roles={[TUTOR]}
            component={Handbook}
          />
          {/* <PrivateRoute path={routes.myGroupClasses} roles={[TUTOR]} component={TutorClasses} /> */}
          <PrivateRoute
            path={routes.subjects}
            roles={[ADMIN]}
            component={Subjects}
          />
          <PrivateRoute
            path={routes.pendingReviews}
            roles={[ADMIN]}
            component={PendingReviews}
          />
          <PrivateRoute path={routes.chats} roles={[ADMIN]} component={Chats} />
          <PrivateRoute path={routes.users} roles={[ADMIN]} component={Users} />
          <PrivateRoute
            path={routes.bookingsHistory}
            roles={[ADMIN]}
            component={BookingHistory}
          />
          <PrivateRoute
            path={routes.qualifications}
            roles={[ADMIN]}
            component={Qualifications}
          />
          <PrivateRoute
            path={routes.tutorCertificates}
            roles={[ADMIN]}
            component={TutorCertificates}
          />
          <PrivateRoute
            path={routes.consultationHistory}
            roles={[ADMIN]}
            component={ConsultationHistory}
          />
          <Redirect to={routes.home} />
        </Switch>
      </main>
    </>
  );
}

export default App;
