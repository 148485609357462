import React, { createContext, useCallback, useContext, useState } from 'react';
import { getAllTutorsSubjects } from 'services/firebase/tutorSubjects';
import { getWPUsers } from '../services/wp/users';

const TutorsContext = createContext();
export const useTutors = () => useContext(TutorsContext);

export function TutorsProvider({ children }) {
  const [tutors, setTutors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tutorsLoaded, setTutorsLoaded] = useState(false);

  const loadTutors = useCallback(() => {
    setLoading(true);
    getWPUsers(new URLSearchParams('context=edit&roles=tutor_instructor&per_page=100'))
      .then(async (tutors) => {
        const tutorSubjects = await getAllTutorsSubjects();
        const tutorsWithSubjects = tutors.map((t) => ({
          ...t,
          subjects: tutorSubjects.find((ts) => ts.tutorId === t.id)?.subjects || [],
        }));
        setTutors(tutorsWithSubjects);
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setTutorsLoaded(true);
        setLoading(false);
      });
  }, []);

  return (
    <TutorsContext.Provider
      value={{
        tutors,
        loading,
        loadTutors,
        tutorsLoaded,
        setTutors,
      }}
    >
      {children}
    </TutorsContext.Provider>
  );
}
