import {
  addDoc,
  collection,
  getDocs,
  limit,
  query,
  where,
  updateDoc,
  doc,
  deleteDoc,
} from '@firebase/firestore';
import { db } from 'config/firebaseConfig';

export const subjectsRef = collection(db, 'subjects');
export const categoriesRef = collection(db, 'categories');

export const addSubject = async (subjectDetails) => {
  const { category, name } = subjectDetails;
  const subjectDocs = await getDocs(
    query(subjectsRef, where('category', '==', category), where('name', '==', name)),
    limit(1)
  );

  if (subjectDocs.docs.length) {
    throw new Error('Subject already exists under this category');
  }

  return await addDoc(subjectsRef, subjectDetails);
};

export const updateSubject = (subjectId, newData) =>
  updateDoc(doc(subjectsRef, subjectId), newData);

export const deleteSubject = (subjectId) => deleteDoc(doc(subjectsRef, subjectId));
