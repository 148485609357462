import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { db } from 'config/firebaseConfig';
import { collection, query, where } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { DataGrid } from '@mui/x-data-grid';
import { getDateFromObject, prettifyDateTime } from 'utils/date';
import { useStyles } from './style';
import { theme } from 'themes';
import { useAuth } from 'providers';
import { deleteRequest } from 'services/firebase/requests';
import LoadingButton from 'components/LoadingButton';

const columns = [
  {
    field: 'createdAt',
    headerName: 'Created At',
    renderHeader: () => (
      <Grid container alignItems='center' wrap='nowrap'>
        <Icon>{'event_icon'}</Icon> &nbsp; Created At
      </Grid>
    ),
    flex: 0.2,
    minWidth: 170,
    type: 'date',
    valueGetter: ({ value }) => getDateFromObject(value),
    renderCell: ({ value }) => prettifyDateTime(value),
  },
  {
    field: 'searchFor',
    headerName: 'Searching For',
    renderHeader: () => (
      <Grid container alignItems='center' wrap='nowrap'>
        <Icon>{'person_search_icon'}</Icon> &nbsp; Searching For
      </Grid>
    ),
    flex: 0.2,
    minWidth: 170,
    renderCell: ({ value }) => <span style={{ textTransform: 'capitalize' }}>{value}</span>,
  },
  {
    field: 'subject',
    flex: 0.6,
    minWidth: 220,
    headerName: 'Subject / Level',
    renderHeader: () => (
      <Grid container alignItems='center' wrap='nowrap'>
        <Icon>{'subject_icon'}</Icon>&nbsp; Subject / Level
      </Grid>
    ),
    renderCell: ({ row }) => (
      <Grid container direction='column'>
        <span style={{ lineHeight: 1.3, fontWeight: 'bold' }}>{row.subject.name}</span>
        <Typography variant='caption' style={{ lineHeight: 1.3 }}>
          {row.level || '-'}
        </Typography>
      </Grid>
    ),
    valueGetter: ({ value }) => value?.name,
    sortComparator: (v1, v2) => String(v1.name).localeCompare(String(v2.name)),
  },
];

const initialSortModel = {
  field: 'createdAt',
  sort: 'asc',
};

function RequestsHistory() {
  const classes = useStyles();
  const { user } = useAuth();
  const [requests = [], loading] = useCollectionData(
    query(collection(db, 'requests'), where('user.id', '==', user.id)),
    {
      idField: 'id',
    }
  );
  const [pageSize, setPageSize] = useState(5);
  const [sortModel, setSortModel] = useState([initialSortModel]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  const handleRowClick = useCallback((params) => {
    setSelectedRow(params.row);
  }, []);

  const handleCancelRequest = useCallback(async () => {
    setIsDeleting(true);
    try {
      await deleteRequest(selectedRow.id);
    } catch (error) {}
    setIsDeleting(false);
    setSelectedRow(null);
  }, [selectedRow]);

  return (
    <Box width='100%' height='100%' minHeight={400}>
      <DataGrid
        onRowClick={handleRowClick}
        rows={requests}
        className={classes.dataGrid}
        columns={columns}
        loading={loading}
        //   disableSelectionOnClick
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        rowsPerPageOptions={[5, 10, 20]}
        autoHeight
        sortModel={sortModel}
        onSortModelChange={setSortModel}
      />
      <Dialog fullWidth open={Boolean(selectedRow)} onClose={() => setSelectedRow(null)}>
        <DialogTitle style={{ textAlign: 'center' }}>Request details</DialogTitle>
        <Divider />
        <DialogContent>
          <Box py={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h6'>Searching for</Typography>
                <Typography style={{ textTransform: 'capitalize' }}>
                  {selectedRow?.searchFor}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>Subject / Level</Typography>
                <Typography style={{ textTransform: 'capitalize' }}>
                  {selectedRow?.subject?.name} / {selectedRow?.level || '-'}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant='h6'>Description</Typography>
                <Typography style={{ whiteSpace: 'pre-wrap' }}>
                  {selectedRow?.description}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            fullWidth={xs}
            variant='outlined'
            color='primary'
            onClick={() => setSelectedRow(null)}
          >
            Close
          </Button>
          <LoadingButton
            loading={isDeleting}
            fullWidth={xs}
            variant='contained'
            color='primary'
            onClick={handleCancelRequest}
            endIcon={<Icon>cancel_icon</Icon>}
          >
            Cancel Request
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default RequestsHistory;
