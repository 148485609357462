import React, { useState } from 'react';
import {
  Grid,
  Icon,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { routes } from 'routes';
import { useStyles } from './style';
import history from 'services/history';

const StudentsCell = ({ students }) => {
  const classes = useStyles();
  const [selectedStudent, setSelectedStudent] = useState(students[0]);

  return (
    <Grid container alignItems='center'>
      <Grid item xs>
        <TextField
          className={classes.studentSelect}
          fullWidth
          variant='outlined'
          select
          value={selectedStudent.id}
          onChange={(e) => setSelectedStudent(students.find((s) => s.id === e.target.value))}
        >
          {students.map((s) => (
            <MenuItem
              key={s.id}
              value={s.id}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <span
                style={{
                  lineHeight: 1.3,
                  textTransform: 'capitalize',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: 200,
                }}
              >
                <strong>{s.name}</strong>
              </span>
              <Typography
                variant='caption'
                style={{
                  lineHeight: 1.3,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: 200,
                }}
              >
                {s.email}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item>
        <Tooltip
          arrow
          title={
            <>
              Send a message to{' '}
              <span style={{ textTransform: 'capitalize' }}>
                {selectedStudent.name?.split(' ')[0]}
              </span>
            </>
          }
        >
          <IconButton
            onClick={() =>
              history.push(routes.messages, {
                chatWith: selectedStudent.id,
              })
            }
          >
            <Icon>message</Icon>
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default React.memo(StudentsCell);
