import React, { useMemo, useState } from 'react';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { collection, limitToLast, orderBy, query, where } from 'firebase/firestore';
import { db } from 'config/firebaseConfig';
import { theme } from 'themes';
import { useEffect } from 'react';
import { getWPUserById } from 'services/wp/users';
import { getUserRole } from 'utils/user';
import { Skeleton } from '@material-ui/lab';
import { messagesRef } from 'services/firebase/messages';
import { ROLES, UUID4 } from 'enums';
import { debounce } from 'lodash';
import { getDateFromObject } from 'utils/date';

function Chats() {
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeChat, setActiveChat] = useState(null);
  const [limit, setLimit] = useState(25);
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);

  const [chats = [], loadingChats] = useCollectionDataOnce(collection(db, 'chats'), {
    idField: 'id',
  });

  const [messages = [], loadingMessages] = useCollectionDataOnce(
    query(
      messagesRef,
      where('chatId', '==', activeChat?.id || UUID4),
      orderBy('createdAt'),
      limitToLast(limit)
    ),
    {
      idField: 'id',
    }
  );

  useEffect(() => {
    const loadUsers = async (userIds) => {
      setLoadingUsers(true);
      try {
        const promises = userIds.map((id) => getWPUserById(id).catch((err) => err));
        const users = await Promise.all(promises);
        setUsers(users.filter((u) => !(u instanceof Error)));
      } catch (error) {
        // console.log(error.message);
      }
      setLoadingUsers(false);
    };

    if (chats.length) {
      loadUsers([...new Set(chats.map((c) => c.chatMembers).flat())]);
    }
  }, [chats]);

  useEffect(() => {
    setLimit(25);
  }, [activeChat]);

  useEffect(() => {
    const chatRoom = document.querySelector('#chat-room');
    const handleScrollTop = debounce((e) => {
      if (e.target.scrollTop === 0) {
        setLimit((prevLimit) => (prevLimit > messages.length ? prevLimit : prevLimit + 25));
      }
    }, 100);

    if (!loadingMessages && messages.length) {
      if (chatRoom?.scrollHeight > chatRoom?.clientHeight) {
        chatRoom.addEventListener('scroll', handleScrollTop);
        if (messages.length <= 25) {
          chatRoom?.scrollTo({ top: chatRoom?.scrollHeight });
        }
      }
    }

    return () => {
      chatRoom?.removeEventListener('scroll', handleScrollTop);
      handleScrollTop.cancel();
    };
  }, [loadingMessages, messages]);

  const chatsWithUsers = useMemo(
    () =>
      users.length
        ? chats
            .map((chat) => ({
              ...chat,
              chatMembers: chat.chatMembers
                .map((id) => users.find((user) => user.id === id))
                .sort((user1, user2) => getUserRole(user2).localeCompare(getUserRole(user1))),
            }))
            .sort((chat1, chat2) =>
              String(chat1.chatMembers[0]?.name || '').localeCompare(
                chat2.chatMembers[0]?.name || ''
              )
            )
        : chats,
    [chats, users]
  );

  return (
    <Box pt={18} pb={10}>
      <Container>
        <Box textAlign='center' pb={10}>
          <Typography variant='h2'>Chats</Typography>
        </Box>

        <Grid
          container
          style={{
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
          }}
        >
          <Grid item xs={12} sm={12} md={4} hidden={sm && activeChat}>
            <Box
              p={2}
              style={{
                borderRight: sm ? 0 : `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Typography variant='h6'>Chat List</Typography>
            </Box>

            <Box
              style={{
                height: 600,
                overflowX: 'hidden',
                overflowY: 'auto',
                borderRight: sm ? 0 : `1px solid ${theme.palette.divider}`,
              }}
            >
              {loadingUsers || loadingChats
                ? Array.from(Array(5).keys()).map((key) => (
                    <MenuItem
                      key={key}
                      style={{
                        width: '100%',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: theme.spacing(2),
                        borderBottom: `1px solid ${theme.palette.divider}`,
                      }}
                    >
                      <Skeleton width='100%' />
                      <Skeleton width='100%' />
                    </MenuItem>
                  ))
                : chatsWithUsers.map((chat, index) => (
                    <MenuItem
                      key={chat.id}
                      selected={activeChat?.id === chat.id}
                      style={{
                        width: '100%',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: theme.spacing(2),
                        borderBottom: `1px solid ${theme.palette.divider}`,
                      }}
                      onClick={() => setActiveChat(chat)}
                    >
                      {chat.chatMembers.map((member, index) => {
                        const userRole = getUserRole(member);
                        return (
                          <Typography
                            component='div'
                            key={`member_${index}`}
                            style={{
                              textTransform: 'capitalize',
                            }}
                          >
                            <strong>{member?.name || 'Unavailable'}</strong>{' '}
                            {typeof member === 'object' ? `(${userRole})` : ''}
                          </Typography>
                        );
                      })}
                    </MenuItem>
                  ))}
            </Box>
          </Grid>
          <Grid item xs hidden={sm && !activeChat}>
            <Box>
              {activeChat ? (
                <Box height='100%'>
                  <Box
                    display='flex'
                    style={{
                      borderBottom: `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    <Box
                      p={1}
                      style={{
                        borderRight: `1px solid ${theme.palette.divider}`,
                        width: 'fit-content',
                      }}
                    >
                      <Tooltip title='Close'>
                        <IconButton onClick={() => setActiveChat(null)}>
                          <Icon>keyboard_backspace</Icon>
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Grid container justifyContent='center' alignItems='center'>
                      <Typography variant='h6'>Chat Room</Typography>
                    </Grid>
                  </Box>
                  <ul
                    id='chat-room'
                    style={{
                      height: 600,
                      overflowY: 'auto',
                      overflowX: 'hidden',
                      padding: theme.spacing(1),
                    }}
                  >
                    {loadingMessages || loadingUsers ? (
                      <Box style={{ height: '100%', display: 'grid', placeItems: 'center' }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      messages.map((m, i) => {
                        const isTutor =
                          getUserRole(
                            activeChat?.chatMembers?.find((member) => member?.id === m.sentBy.id)
                          ) === ROLES.TUTOR;
                        const sentAt = getDateFromObject(m.createdAt);
                        const currentMessageDate = sentAt.toLocaleDateString();
                        const previousMessageDate =
                          i > 0
                            ? getDateFromObject(messages[i - 1].createdAt).toLocaleDateString()
                            : currentMessageDate;
                        const userFullName =
                          activeChat?.chatMembers?.find((user) => user.id === m.sentBy.id)?.name ||
                          'Unavailable';
                        return (
                          <li key={m.id}>
                            {i === 0 && limit > messages.length && (
                              <Box pb={4} pt={3} textAlign='center'>
                                <Typography color='textSecondary'>
                                  {sentAt.toLocaleDateString('en-GB')}
                                </Typography>
                              </Box>
                            )}
                            {previousMessageDate !== currentMessageDate && (
                              <Box pb={4} pt={3} textAlign='center'>
                                <Typography color='textSecondary'>
                                  {sentAt.toLocaleDateString('en-GB')}
                                </Typography>
                              </Box>
                            )}
                            <Box
                              style={{
                                marginBottom: theme.spacing(1),
                                padding: theme.spacing(1),
                                width: 'fit-content',
                                maxWidth: '90%',
                                whiteSpace: 'pre-wrap',
                                overflowWrap: 'break-word',
                                borderRadius: isTutor ? '10px 10px 0px 10px' : '0px 10px 10px 10px',
                                marginLeft: isTutor ? 'auto' : 0,
                                backgroundColor: isTutor
                                  ? theme.palette.primary.main
                                  : theme.palette.secondary.main,
                                color: isTutor ? theme.palette.primary.contrastText : 'unset',
                              }}
                            >
                              <Typography style={{ textTransform: 'capitalize' }}>
                                <strong>{userFullName}</strong>
                              </Typography>
                              <Typography variant='body2'>{m.text}</Typography>
                              <Typography
                                component='div'
                                variant='caption'
                                style={{ opacity: 0.4, width: '100%', textAlign: 'right' }}
                              >
                                {sentAt.toLocaleTimeString('en-GB', {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })}
                              </Typography>
                            </Box>
                          </li>
                        );
                      })
                    )}
                  </ul>
                </Box>
              ) : (
                <Box
                  style={{ display: 'grid', placeItems: 'center', height: '100%', minHeight: 600 }}
                >
                  <Typography>Please select a chat</Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Chats;
