import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from '@firebase/firestore';
import { db } from 'config/firebaseConfig';
import { getDateFromObject } from 'utils/date';

export const reviewsRef = collection(db, 'reviews');

export const addReview = async (reviewData) => {
  const { reviewer, reviewed } = reviewData;
  const reviewDocs = await getDocs(
    query(
      reviewsRef,
      where('reviewer.id', '==', reviewer.id),
      where('reviewed.id', '==', reviewed.id)
    ),
    limit(1)
  );

  if (reviewDocs.docs.length) {
    throw new Error('You have already reviewed this tutor.');
  }

  return await addDoc(reviewsRef, reviewData);
};

export const updateReview = (reviewId, reviewData) =>
  updateDoc(doc(reviewsRef, reviewId), reviewData);

export const deleteReview = (reviewId) => deleteDoc(doc(reviewsRef, reviewId));

export const getApprovedReviews = () =>
  getDocs(query(reviewsRef)).then((querySnaphot) =>
    querySnaphot.docs.map((d) => {
      const data = d.data();
      const id = d.id;
      const createdAt = getDateFromObject(data.createdAt);
      return { id, ...data, createdAt };
    })
  );
