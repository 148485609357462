import React, { useCallback, useState } from 'react';
import axios from 'axios';
import LoadingButton from 'components/LoadingButton';
// import References from './References';
import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useAuth } from 'providers';
import { updateWPUser } from 'services/wp/users';
import {
  APP_TITLE,
  ROLES,
  WP_PASSWORD_RESET_WITH_CODE,
  WP_SET_NEW_PASSWORD,
  WP_SITE_URL,
} from 'enums';
import { Alert, Autocomplete } from '@material-ui/lab';
import {
  createUserNotifications,
  updateUserNotifications,
  userNotificationsRef,
} from 'services/firebase/userNotifications';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import { useMemo } from 'react';
import { getTutorLocation, getUserRole } from 'utils/user';
import { useStripe } from '@stripe/react-stripe-js';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { getAutocompleteResults, getLocation } from 'services/hereMaps';
import HourlyRate from 'pages/Profile/BasicInfo/HourlyRate';
import CustomLink from 'components/CustomLink';
import PhoneInput from 'components/PhoneInput';
import Highlighter from 'react-highlight-words';

const getAddressOptionLabel = (option) => option.address?.label || option.title || '';
const getOptionSelected = (option, value) => option.id === value.id;
const filterOptions = (options) =>
  options.filter((option) => option.resultType !== 'administrativeArea'); // remove countries and counties
const renderLocationOption = (option, state) => (
  <Highlighter
    highlightStyle={{ fontWeight: 'bold' }}
    searchWords={state.inputValue.split(' ')}
    autoEscape
    textToHighlight={getAddressOptionLabel(option)}
  />
);

let timer = null;

function Account() {
  const { user, setUser } = useAuth();
  const { location = null, travelDistance = 0 } = useMemo(() => getTutorLocation(user), [user]);
  const [showPassword, setShowPassword] = useState(false);
  const [gender, setGender] = useState(user?.meta?.gender?.[0] || 'none');
  const [mentoring, setMentoring] = useState(user.meta?.mentoring?.[0] || 'no');
  const [tuitionTypes, setTuitionTypes] = useState(user.meta?.tuition_types || []);
  const [sendingCodeSuccess, setSendingCodeSuccess] = useState(null);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(null);
  const [inPerson, setInPerson] = useState(user.meta?.tutoring_method?.includes('In Person'));
  const [loadingButton, setLoadingButton] = useState('');
  const [error, setError] = useState({});
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(location);
  const [locationOptions, setLocationOptions] = useState(location ? [location] : []);
  const [userNotifications, loadingUserNotifications] = useDocumentDataOnce(
    doc(userNotificationsRef, String(user.id)),
    {
      idField: 'id',
    }
  );

  const stripe = useStripe();

  const handleSubmitBasicInfo = async (e) => {
    e.preventDefault();

    if (error['basicInfo']) {
      delete error['basicInfo'];
      setError(error);
    }

    const first_name = e.target.first_name.value?.trim();
    const last_name = e.target.last_name.value?.trim();
    const phone_number = e.target.phone_number.value?.trim();

    if (!isValidPhoneNumber(phone_number)) {
      return e.target.phone_number?.focus();
    }

    if (getUserRole(user) === ROLES.TUTOR && gender === 'none') {
      e.target.gender?.focus();
      return setError({ ...error, basicInfo: 'Please select your gender.' });
    }

    setLoadingButton('basicInfo');
    try {
      const res = await updateWPUser(user.id, {
        first_name,
        last_name,
        name: `${first_name} ${last_name}`,
        meta: {
          phone_number: [phone_number],
          gender,
        },
      });
      setUser({ ...user, ...res.data });
    } catch (error) {
      setError({ ...error, basicInfo: 'Please select your gender.' });
    }
    // save info
    setLoadingButton('');
  };

  const handleSendCode = async () => {
    if (sendingCodeSuccess) {
      setSendingCodeSuccess(null);
    }

    if (error['passwordResetCode']) {
      delete error['passwordResetCode'];
      setError(error);
    }

    setLoadingButton('passwordResetCode');
    try {
      const res = await axios.post(WP_PASSWORD_RESET_WITH_CODE, { email: user.email });
      setSendingCodeSuccess(res.data.message);
    } catch (error) {
      setError({ ...error, passwordResetCode: error.response?.data?.message });
    }
    setLoadingButton('');
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    const code = e.target.passwordResetCode.value;
    const password = e.target.password.value;
    const email = user.email;

    if (code.length < 4) {
      return setError({ ...error, passwordReset: 'Reset code must be 4 digits' });
    }

    setLoadingButton('passwordReset');
    try {
      const res = await axios.post(WP_SET_NEW_PASSWORD, {
        email,
        password,
        code,
      });
      setPasswordResetSuccess(res.data.message);
    } catch (error) {
      setError({ ...error, passwordReset: error.response?.data?.message });
    }
    setLoadingButton('');
  };

  const handleNotificationsSubmit = async (e) => {
    e.preventDefault();
    const reminders = e.target.reminders;
    const messages = e.target.messages;
    // const marketing = e.target.marketing;

    if (error['notifications']) {
      delete error['notifications'];
      setError(error);
    }

    const disabled = [reminders, messages]
      .filter(({ checked }) => !checked)
      .map(({ name }) => name);

    setLoadingButton('notifications');
    try {
      const handler = userNotifications ? updateUserNotifications : createUserNotifications;
      await handler(String(user.id), { disabled });
    } catch (error) {
      // couldn't update
      setError({ ...error, notifications: error.message });
    }
    setLoadingButton('');
  };

  const handleSubmitHourlyRate = async (e) => {
    e.preventDefault();
    const hours_rate = e.target.hours_rate.value;

    if (hours_rate > 999) {
      return setError({ ...error, hourlyRate: 'Hourly rate must be between 15 and 999' });
    }

    setLoadingButton('hourlyRate');
    try {
      const res = await updateWPUser(user.id, {
        meta: {
          hours_rate,
        },
      });
      setUser({ ...user, ...res.data });
    } catch (error) {
      setError({ ...error, hourlyRate: error.message });
    }
    // save info
    setLoadingButton('');
  };

  const handleSubmitPaymentDetails = async (e) => {
    e.preventDefault();
    const account_number = String(e.target.accountNumber?.value).trim();
    const sort_code = String(e.target.sortCode?.value).trim();

    if (!stripe) {
      // stripe not available yet
      return;
    }

    if (error['paymentDetails']) {
      delete error['paymentDetails'];
      setError('');
    }

    setLoadingButton('paymentDetails');

    try {
      const { error } = await stripe.createToken('bank_account', {
        account_number,
        country: 'GB',
        currency: 'GBP',
        routing_number: sort_code,
      });

      if (error) {
        throw error;
      }

      const updatedUser = (
        await updateWPUser(user.id, {
          meta: {
            account_number: [account_number],
            sort_code: [sort_code],
          },
        })
      ).data;

      setUser((prevUser) => ({ ...prevUser, ...updatedUser }));
    } catch (error) {
      setError({ ...error, paymentDetails: error.message });
    }

    setLoadingButton('');
  };

  const handleSubmitTuitionTypes = async (e) => {
    e.preventDefault();
    const tuition_types = ['one-to-one']; // default

    if (tuitionTypes.includes('group-class')) {
      tuition_types.push('group-class');
    }

    if (error['tuitionTypes']) {
      delete error['tuitionTypes'];
      setError(error);
    }

    setLoadingButton('tuitionTypes');
    try {
      const updatedUser = (
        await updateWPUser(user.id, {
          meta: {
            tuition_types,
          },
        })
      ).data;

      setUser((prevUser) => ({ ...prevUser, ...updatedUser }));
    } catch (error) {
      setError({ ...error, tuitionTypes: error.message });
    }
    setLoadingButton('');
  };

  const handleSubmitMentoring = async (e) => {
    e.preventDefault();

    if (error['mentoring']) {
      delete error['mentoring'];
      setError(error);
    }

    setLoadingButton('mentoring');
    try {
      const updatedUser = (
        await updateWPUser(user.id, {
          meta: {
            mentoring,
          },
        })
      ).data;
      setUser((prevUser) => ({ ...prevUser, ...updatedUser }));
    } catch (error) {
      setError({ ...error, mentoring: error.message });
    }
    setLoadingButton('');
  };

  const handleSubmitTutoringMethod = async (e) => {
    e.preventDefault();

    if (error['tutoringMethod']) {
      delete error['tutoringMethod'];
      setError(error);
    }

    if (inPerson && !selectedLocation) {
      return setError({
        ...error,
        tutoringMethod: 'Please provide a location if you want to tutor/mentor in person.',
      });
    }

    if (
      inPerson &&
      travelDistance === Number(e.target.travelDistance.value) &&
      location?.id === selectedLocation?.id
    ) {
      return;
    }

    setLoadingButton('tutoringMethod');
    try {
      let tutor_locations = [];
      if (inPerson && selectedLocation) {
        const travelDistance = Number(e.target.travelDistance.value);
        const tutorLocationsObject = { travelDistance, location };

        if (selectedLocation.id !== location?.id) {
          // get selected location position and mapView
          const newLocation = (await getLocation(selectedLocation.id)).data;
          tutorLocationsObject.location = newLocation;
        }

        tutor_locations = [JSON.stringify(tutorLocationsObject)];
      }

      const updatedUser = (
        await updateWPUser(user.id, {
          meta: {
            tutoring_method: inPerson ? ['Online', 'In Person'] : ['Online'],
            tutor_locations,
          },
        })
      ).data;

      setUser((prevUser) => ({ ...prevUser, ...updatedUser }));
    } catch (error) {
      setError({ ...error, tutoringMethod: error.message });
    }
    setLoadingButton('');
  };

  const handleLocationInputChange = useCallback((e) => {
    const value = e.target.value;
    setLoadingLocations(true);

    if (timer) {
      clearTimeout(timer);
    }

    if (!value) {
      setSelectedLocation(null);
      setLocationOptions([]);
      return setLoadingLocations(false);
    }

    timer = setTimeout(async () => {
      try {
        const apiResults = (await getAutocompleteResults(value, 5)).data;
        setLocationOptions(apiResults?.items || []);
      } catch (error) {
        console.log(error.message);
      }
      setLoadingLocations(false);
    }, 1000);
  }, []);

  const handleLocationChange = useCallback((e, value, reason) => {
    if (reason === 'create-option') {
      return;
    }

    if (reason === 'clear') {
      setLocationOptions([]);
    }

    setSelectedLocation(value);
  }, []);

  const isTutor = useMemo(() => getUserRole(user) === 'tutor', [user]);

  return (
    <Box pb={10} pt={18}>
      <Container>
        <Box pb={10} textAlign='center'>
          <Typography variant='h2'>Account Details</Typography>
        </Box>

        <Typography variant='h6'>Personal Details</Typography>
        <Divider />
        <Box py={2}>
          <form onSubmit={handleSubmitBasicInfo}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  label='Username'
                  disabled
                  fullWidth
                  variant='outlined'
                  value={user.username}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label='E-mail Address'
                  fullWidth
                  disabled
                  variant='outlined'
                  value={user.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label='First Name'
                  name='first_name'
                  fullWidth
                  variant='outlined'
                  defaultValue={user.first_name}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label='Last Name'
                  name='last_name'
                  fullWidth
                  variant='outlined'
                  defaultValue={user.last_name}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <PhoneInput
                  label='Phone'
                  required
                  defaultValue={user.meta.phone_number?.[0] || ''}
                />
              </Grid>
              {isTutor && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant='outlined'
                    label='Gender'
                    required
                    name='gender'
                    select
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start' style={{ marginRight: 16 }}>
                          <Tooltip title='We ask this for no other reason than some of our clients use this as part of their selection process.'>
                            <Icon color='action'>info_icon</Icon>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  >
                    {gender === 'none' && (
                      <MenuItem value='none'>
                        <em>Select Gender</em>
                      </MenuItem>
                    )}
                    <MenuItem value='M'>Male</MenuItem>
                    <MenuItem value='F'>Female</MenuItem>
                    <MenuItem value='X'>Prefer not to say</MenuItem>
                  </TextField>
                </Grid>
              )}

              {error['basicInfo'] && (
                <Grid item xs={12}>
                  <Typography color='error'>{error['basicInfo']}</Typography>
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <LoadingButton
                  loading={loadingButton === 'basicInfo'}
                  type='submit'
                  startIcon={<Icon>save</Icon>}
                >
                  Save Changes
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
        <Box pt={6}>
          <Typography variant='h6'>Change Password</Typography>
          <Divider />
          <Box pt={2}>
            <Grid container spacing={4}>
              {(sendingCodeSuccess || error['passwordResetCode']) && !passwordResetSuccess && (
                <Grid item xs={12}>
                  {sendingCodeSuccess && <Alert severity='success'>{sendingCodeSuccess}</Alert>}
                  {error['passwordResetCode'] && (
                    <Alert severity='error'>{error['passwordResetCode']}</Alert>
                  )}
                </Grid>
              )}

              {!sendingCodeSuccess && (
                <Grid item xs={12} sm={6}>
                  <LoadingButton
                    type='button'
                    loading={loadingButton === 'passwordResetCode'}
                    onClick={handleSendCode}
                  >
                    Send password reset code
                  </LoadingButton>
                </Grid>
              )}

              {sendingCodeSuccess && (
                <Grid item xs={12} sm={6}>
                  {passwordResetSuccess ? (
                    <Alert severity='success'>{passwordResetSuccess}</Alert>
                  ) : (
                    <form onSubmit={handleResetPasswordSubmit}>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <TextField
                            label='Password Reset Code'
                            autoFocus
                            fullWidth
                            inputProps={{ type: 'number', minLength: 4, maxLength: 4 }}
                            name='passwordResetCode'
                            onKeyDown={(e) =>
                              ((e.target.value.length > 3 && e.key !== 'Backspace') ||
                                ['e', 'E', '-', '+', '.'].includes(e.key)) &&
                              e.preventDefault()
                            }
                            required
                            variant='outlined'
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label='New Password'
                            required
                            fullWidth
                            variant='outlined'
                            type={showPassword ? 'text' : 'password'}
                            name='password'
                            inputProps={{ minLength: 5 }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton
                                    color='primary'
                                    size='small'
                                    onClick={() => setShowPassword((show) => !show)}
                                  >
                                    <Icon>{showPassword ? 'visibility_off' : 'visibility'}</Icon>
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        {error['passwordReset'] && (
                          <Grid item xs={12}>
                            <Alert severity='error'>{error['passwordReset']}</Alert>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <LoadingButton loading={loadingButton === 'passwordReset'} type='submit'>
                            Reset Password
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
        <Box pt={10}>
          <Typography variant='h6'>Notifications</Typography>
          <Divider />
          <Box pt={2}>
            <form onSubmit={handleNotificationsSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    You can decide which notifications you would like to receive.
                  </Typography>
                </Grid>
                <Grid item xs={12} container alignItems='center'>
                  <FormControlLabel
                    control={<Checkbox checked={true} disabled={true} />}
                    label='Mandatory notifications'
                  />
                  <Tooltip title='We will always email you about important things like lesson cancellations or payment problems.'>
                    <Icon color='action'>info</Icon>
                  </Tooltip>
                </Grid>
                {!loadingUserNotifications && (
                  <Grid item xs={12} container alignItems='center'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={!userNotifications?.disabled?.includes('reminders')}
                        />
                      }
                      label='Reminders'
                      name='reminders'
                    />
                    <Tooltip title='We will let you know about upcoming lessons.'>
                      <Icon color='action'>info</Icon>
                    </Tooltip>
                  </Grid>
                )}
                {!loadingUserNotifications && (
                  <Grid item xs={12} container alignItems='center'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={!userNotifications?.disabled?.includes('messages')}
                        />
                      }
                      label='Messages'
                      name='messages'
                    />
                    <Tooltip
                      title={`We will let you know if you receive any new messages from ${
                        isTutor ? 'students' : 'tutors'
                      }.`}
                    >
                      <Icon color='action'>info</Icon>
                    </Tooltip>
                  </Grid>
                )}
                {/* {!loadingUserNotifications && (
                  <Grid item xs={12} container alignItems='center'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={!userNotifications?.disabled?.includes('marketing')}
                        />
                      }
                      label='Marketing'
                      name='marketing'
                    />
                    <Tooltip
                      title={`Get the monthly ${
                        isTutor ? 'Tutor' : 'Student'
                      } Digest email and our latest news about the products and services you use.`}
                    >
                      <Icon color='action'>info</Icon>
                    </Tooltip>
                  </Grid>
                )} */}

                {error['notifications'] && (
                  <Grid item xs={12}>
                    <Typography color='error'>{error['notifications']}</Typography>
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <LoadingButton
                    loading={loadingButton === 'notifications'}
                    type='submit'
                    startIcon={<Icon>save</Icon>}
                  >
                    Save Changes
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>

        {/* {isTutor && (
          <Box pt={10}>
            <Typography variant='h6'>References</Typography>
            <Divider />
            <Box pt={2}>
              <References />
            </Box>
          </Box>
        )} */}

        {isTutor && (
          <Box pt={10}>
            <form onSubmit={handleSubmitTutoringMethod}>
              <Box pb={2}>
                <Typography variant='h6'>Tutoring / Mentoring Modes</Typography>
                <Divider />
              </Box>
              <Typography>What modes of tutoring / mentoring are you available for?</Typography>
              <FormControlLabel
                control={<Checkbox checked={true} disabled={true} name='online' />}
                label='Online'
              />
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={inPerson}
                    name='inPerson'
                    onChange={({ target: { checked } }) => {
                      setInPerson(checked);
                      if (error['tutoringMethod']) {
                        delete error['tutoringMethod'];
                        setError(error);
                      }
                    }}
                  />
                }
                label='In Person'
              />
              {inPerson && (
                <Box pt={2}>
                  <Typography>
                    Please tell us your location for the <strong>"In Person"</strong>{' '}
                    tutoring/mentoring option:
                  </Typography>
                  <Autocomplete
                    disablePortal
                    freeSolo
                    loading={loadingLocations}
                    filterOptions={filterOptions}
                    options={locationOptions}
                    value={selectedLocation}
                    renderOption={renderLocationOption}
                    getOptionLabel={getAddressOptionLabel}
                    getOptionSelected={getOptionSelected}
                    onChange={handleLocationChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        required
                        name='tutorLocation'
                        variant='outlined'
                        placeholder='Start typing your location'
                        onChange={handleLocationInputChange}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Icon color='action'>location_on</Icon>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <>
                              {loadingLocations && <CircularProgress size={24} />}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                  <Box pt={2}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography>
                          How far are you willing to travel from your location?
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          defaultValue={travelDistance}
                          fullWidth
                          inputProps={{ min: 0, max: 50, type: 'number' }}
                          InputProps={{
                            endAdornment: <InputAdornment position='end'>miles</InputAdornment>,
                          }}
                          name='travelDistance'
                          onKeyDown={(e) =>
                            ['e', 'E', '-', '+', '.'].includes(e.key) && e.preventDefault()
                          }
                          placeholder='Enter distance'
                          required
                          variant='outlined'
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}
              {error['tutoringMethod'] && (
                <Box pt={2}>
                  <Typography color='error'>{error['tutoringMethod']}</Typography>
                </Box>
              )}
              <Box pt={2}>
                <LoadingButton
                  loading={loadingButton === 'tutoringMethod'}
                  type='submit'
                  startIcon={<Icon>save</Icon>}
                >
                  Save Changes
                </LoadingButton>
              </Box>
            </form>
          </Box>
        )}

        {isTutor && (
          <Box pt={10}>
            <form onSubmit={handleSubmitMentoring}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h6'>Are you able to mentor?</Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component='fieldset'>
                    <RadioGroup
                      aria-label='mentoring'
                      value={mentoring}
                      onChange={(e) => setMentoring(e.target.value)}
                      name='mentoring'
                    >
                      <FormControlLabel value='no' control={<Radio />} label='No' />
                      <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item>
                  <LoadingButton
                    loading={loadingButton === 'mentoring'}
                    type='submit'
                    startIcon={<Icon>save</Icon>}
                  >
                    Save Changes
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        )}

        {isTutor && (
          <Box pt={10}>
            <form onSubmit={handleSubmitTuitionTypes}>
              <Box pb={2}>
                <Typography variant='h6'>Tuition Types</Typography>
                <Divider />
              </Box>
              <Typography>What types of tuition are you available for?</Typography>
              <Typography>
                <mark>*Group classes can be for up to 4 students.</mark>
              </Typography>
              <Typography>
                <mark>
                  *You will get a <strong>25%</strong> increase on your hourly price for each group
                  class taught.
                </mark>
              </Typography>
              <Typography></Typography>
              <FormControlLabel
                control={<Checkbox checked={true} name='one-to-one' disabled />}
                label='One-to-one'
              />
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    name='groupClass'
                    checked={tuitionTypes.includes('group-class') || false}
                    onChange={(e, checked) =>
                      setTuitionTypes((prevTuitionTypes) =>
                        checked
                          ? [...prevTuitionTypes, 'group-class']
                          : prevTuitionTypes.filter((type) => type !== 'group-class')
                      )
                    }
                  />
                }
                label='Group Class'
              />
              <Box pt={2}>
                {error['tuitionTypes'] && (
                  <Box pb={2}>
                    <Typography color='error'>{error['tuitionTypes']}</Typography>
                  </Box>
                )}
                <LoadingButton
                  loading={loadingButton === 'tuitionTypes'}
                  type='submit'
                  startIcon={<Icon>save</Icon>}
                >
                  Save Changes
                </LoadingButton>
              </Box>
            </form>
          </Box>
        )}

        {isTutor && (
          <Grid item xs={12}>
            <Box pt={10}>
              <form onSubmit={handleSubmitHourlyRate}>
                <Typography variant='h6'>Hourly Rate</Typography>
                <Divider />
                <Box pt={2}>
                  <Typography color='textSecondary' component='div' gutterBottom>
                    Your rate will depend on your experience and the subject taught, but the general
                    guidance is:
                    <ul style={{ listStyle: 'inside' }}>
                      <li>£25 - £50 per hour for qualified teachers</li>
                      <li>£15 - £25 per hour for tutors</li>
                      <li>£15 - £30 per hour for mentors (dependant on experience)</li>
                    </ul>
                  </Typography>
                  <Typography color='textSecondary'>
                    {APP_TITLE} tuition fee is deducted from your hourly rate. This fee is reduced
                    as you teach more hours with us.
                    <br />
                    Enter your hourly rate to see a breakdown of your earnings below. You can read
                    more about {APP_TITLE} tuition fee{' '}
                    <CustomLink
                      href={`${WP_SITE_URL}/terms-and-conditions/#:~:text=6. Responsibilities of Tutors and Mentors`}
                      target='_blank'
                    >
                      here
                    </CustomLink>
                    .
                  </Typography>
                </Box>
                <Box pt={2}>
                  <HourlyRate
                    defaultValue={Number(user?.meta?.hours_rate[0]) || 0}
                    fieldName='hours_rate'
                  />
                </Box>
                <Box pt={2}>
                  {error['hourlyRate'] && (
                    <Box pb={2}>
                      <Typography color='error'>{error['hourlyRate']}</Typography>
                    </Box>
                  )}
                  <LoadingButton
                    loading={loadingButton === 'hourlyRate'}
                    type='submit'
                    startIcon={<Icon>save</Icon>}
                  >
                    Save Changes
                  </LoadingButton>
                </Box>
              </form>
            </Box>
          </Grid>
        )}

        {isTutor && (
          <Box pt={10}>
            <Typography variant='h6'>Billing Details</Typography>
            <Divider />

            <Box pt={2}>
              <form onSubmit={handleSubmitPaymentDetails}>
                <Typography>This needs to be a valid UK bank account.</Typography>
                <br />
                <Typography gutterBottom>
                  <strong>Bank Account</strong>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      name='accountNumber'
                      variant='outlined'
                      label='Account Number'
                      defaultValue={user.meta.account_number[0] || ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      name='sortCode'
                      variant='outlined'
                      label='Sort Code'
                      defaultValue={user.meta.sort_code[0] || ''}
                    />
                  </Grid>
                  {error['paymentDetails'] && (
                    <Grid item xs={12}>
                      <Typography color='error'>{error['paymentDetails']}</Typography>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6}>
                    <LoadingButton
                      disabled={!stripe}
                      loading={loadingButton === 'paymentDetails'}
                      type='submit'
                      startIcon={<Icon>save</Icon>}
                    >
                      Save Changes
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        )}
      </Container>
    </Box>
  );
}

export default Account;
