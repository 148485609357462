import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  headline: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-wrap',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  bio: {
    height: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-wrap',
    display: '-webkit-box',
    WebkitLineClamp: 5,
    WebkitBoxOrient: 'vertical',
  },
  cardMedia: {
    height: 'auto',
    aspectRatio: '1/1',
    transformOrigin: 'bottom',
    transition: theme.transitions.create(['transform']),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 10,
    transition: theme.transitions.create(['background-color', 'box-shadow']),
    '&:hover': {
      boxShadow: theme.shadows[10],
      backgroundColor: theme.palette.grey[100],
      '& .MuiCardMedia-root': {
        transform: 'scale(1.02)',
      },
    },
  },
  noResults: {
    backgroundColor: theme.palette.warning.main,
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('xs')]: {
      '& .MuiTypography-root': {
        fontSize: 24,
      },
    },
  },
  perHour: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
  },
}));
