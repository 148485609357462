const { Box } = require('@material-ui/core');

function TabPanel({ children, value, index, ...other }) {
  return (
    <Box
      width='100%'
      height='100%'
      hidden={value !== index}
      role='tabpanel'
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

export default TabPanel;
