import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  chatList: {
    listStyleType: 'none',
    height: '100%',
    overflowY: 'auto',
  },
  chatListItem: {
    '& img': {
      width: 50,
      height: 50,
      borderRadius: '50%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
    '& .MuiButton-root': {
      padding: theme.spacing(2),
      textAlign: 'left',
      justifyContent: 'flex-start',
      borderRadius: theme.shape.borderRadius,
    },
  },
  chatListItemText: {
    textAlign: 'left',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));
