import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  TextField,
  Chip,
  FormControlLabel,
  DialogTitle,
  Checkbox,
  DialogActions,
  Icon,
} from '@material-ui/core';
import { useAuth } from '../../../providers/Auth';
import { updateWPUser } from '../../../services/wp/users';
import { useStyles } from './style';
import { theme } from 'themes';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { db } from 'config/firebaseConfig';
import { collection, query, where } from 'firebase/firestore';
import { deleteFile } from 'services/firebase/storage';
import { useQualifications } from 'providers';
import { uploadFile } from 'services/firebase/storage';
import { createFileToApproveDoc, deleteFileToApprove } from 'services/firebase/filesToApprove';
import { isFileImage, isPDF } from 'utils/imageFile';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import LoadingButton from 'components/LoadingButton';
import CustomLink from 'components/CustomLink';

function Qualifications({ tutor }) {
  const classes = useStyles();
  const { user, setUser } = useAuth();
  const { qualifications } = useQualifications();
  const { id, meta: { education = [], qualifications: tutorQualificationsIds = [] } = {} } =
    useMemo(() => tutor || user, [tutor, user]);

  const parsedEducation = useMemo(
    () => (education[0] ? JSON.parse(education[0]) : {}),
    [education]
  );

  const [tutorEducation, setTutorEducation] = useState(parsedEducation);
  const [selectedQualificationIds, setSelectedQualificationIds] = useState([]);
  const mappedQualifications = useMemo(
    () =>
      qualifications.filter((qualification) => selectedQualificationIds.includes(qualification.id)),
    [qualifications, selectedQualificationIds]
  );
  const [open, setOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState({});
  const [loadingFileId, setLoadingFileId] = useState(null);
  const [uploadedFiles = []] = useCollectionData(
    query(collection(db, 'filesToApprove'), where('uploadedBy.id', '==', id)),
    { idField: 'id' }
  );

  useEffect(() => {
    setSelectedQualificationIds(tutorQualificationsIds);
  }, [tutorQualificationsIds]);

  const clearErrors = () => Object.keys(error).length && setError({});

  const handleRemoveFile = useCallback(
    async (qualificationId) => {
      setLoadingFileId(qualificationId);

      try {
        const file = uploadedFiles.find((file) => file.tag === qualificationId);
        await deleteFileToApprove(file.id);
        await deleteFile(file.downloadUrl);
      } catch (error) {}
      setSelectedQualificationIds((prevIds) => prevIds.filter((id) => id !== qualificationId));
      setLoadingFileId(null);
    },
    [uploadedFiles]
  );

  const handleQualificationsCheck = useCallback(
    (e, checked) => {
      setSelectedQualificationIds((prevQualificationIds) =>
        checked
          ? [...prevQualificationIds, e.target.id]
          : prevQualificationIds.filter((qualificationId) => qualificationId !== e.target.id)
      );

      if (!checked && uploadedFiles.some((file) => file.tag === e.target.id)) {
        handleRemoveFile(e.target.id);
      }
    },
    [handleRemoveFile, uploadedFiles]
  );

  const handleFileChange = useCallback(
    async (e, qualificationId) => {
      const file = e.target.files[0];

      if (file) {
        if (!(isFileImage(file) || isPDF(file))) {
          return setError({
            [qualificationId]: 'Invalid file type! Only images and PDFs are supported',
          });
        }

        setLoadingFileId(qualificationId);
        try {
          // upload file
          const downloadUrl = await uploadFile(
            `uploads/${user.id}/${qualificationId}.${file.name.split('.').pop()}`,
            file
          );

          // create fileToApprove doc
          await createFileToApproveDoc(
            { name: user.name, email: user.email, id: user.id },
            qualificationId,
            downloadUrl
          );
        } catch (error) {
          setError({ [qualificationId]: 'Upload failed! Please try again.' });
        }
        setLoadingFileId(null);
      }
    },
    [user.email, user.id, user.name]
  );

  const handleEducationBlur = useCallback(
    ({ target: { name, value } }) =>
      setTutorEducation((prevEducation) => ({ ...prevEducation, [name]: value })),
    []
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearErrors();

    const qualificationNeedsFile = mappedQualifications.find(
      ({ id, fileUploadRequired }) =>
        fileUploadRequired && !uploadedFiles.find((file) => file.tag === id)
    );

    if (qualificationNeedsFile) {
      return setError({
        serverError: `Please upload the required file for ${qualificationNeedsFile.title}`,
      });
    }

    setSubmitting(true);

    try {
      // save education and qualifications
      const response = await updateWPUser(user.id, {
        meta: {
          education: [JSON.stringify(tutorEducation)],
          qualifications: selectedQualificationIds,
        },
      });

      const newUserData = response.data;
      if (newUserData) {
        setOpen(false);
        setUser((prevUser) => ({ ...prevUser, meta: { ...prevUser.meta, ...newUserData.meta } }));
      }
    } catch (error) {
      setError({ serverError: error.response?.data?.message });
    }

    setSubmitting(false);
  };

  return (
    <>
      <Grid container spacing={2} justifyContent='space-between' wrap='nowrap'>
        <Grid item>
          <Typography variant='h4' color='textSecondary'>
            Education and Qualifications
          </Typography>
        </Grid>
        {!tutor && (
          <Grid item>
            <Tooltip title='Edit Education & Qualifications' arrow>
              <IconButton onClick={() => setOpen(true)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <Box py={1}>
        <Divider />
      </Box>

      <Grid container spacing={2} direction='column'>
        <Grid item>
          <Typography variant='h6' gutterBottom>
            Education
          </Typography>
          <Grid container spacing={1}>
            {parsedEducation['higherEducation'] && (
              <Grid item xs={12}>
                <Grid container justifyContent='space-between' spacing={2}>
                  <Grid item xs={6}>
                    <Typography>
                      <strong>Higher Education</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ whiteSpace: 'pre-wrap' }}>
                      {parsedEducation['higherEducation']}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {parsedEducation['furtherEducation'] && (
              <Grid item xs={12}>
                <Grid container justifyContent='space-between' spacing={2}>
                  <Grid item xs={6}>
                    <Typography>
                      <strong>Further Education</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ whiteSpace: 'pre-wrap' }}>
                      {parsedEducation['furtherEducation']}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {parsedEducation['school'] && (
              <Grid item xs={12}>
                <Grid container justifyContent='space-between' spacing={2}>
                  <Grid item xs={6}>
                    <Typography>
                      <strong>School</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ whiteSpace: 'pre-wrap' }}>
                      {parsedEducation['school']}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {parsedEducation['other'] && (
              <Grid item xs={12}>
                <Grid container justifyContent='space-between' spacing={2}>
                  <Grid item xs={6}>
                    <Typography>
                      <strong>Other</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ whiteSpace: 'pre-wrap' }}>
                      {parsedEducation['other']}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!Object.keys(parsedEducation).some((key) => !!parsedEducation[key]) && (
              <Grid item xs={12}>
                <Typography color='textSecondary'>No results</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant='h6' gutterBottom>
            Qualifications
          </Typography>
          <Grid container spacing={1}>
            {mappedQualifications.length ? (
              mappedQualifications
                .filter(
                  (q) =>
                    !q.fileUploadRequired ||
                    (q.fileUploadRequired &&
                      uploadedFiles.find((file) => file.tag === q.id)?.status === 'approved')
                )
                .map((q) => (
                  <Grid item key={q.id}>
                    <Chip
                      label={q.title}
                      color='primary'
                      variant='outlined'
                      className={classes.qualificationChip}
                    />
                  </Grid>
                ))
            ) : (
              <Grid item>
                <Typography color='textSecondary'>No results</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {open && (
        <Dialog open={open} fullWidth maxWidth='md'>
          <DialogTitle
            style={{ textAlign: 'center', borderBottom: `1px solid ${theme.palette.divider}` }}
          >
            Edit Education and Qualifications
          </DialogTitle>
          <DialogContent style={{ padding: theme.spacing(4, 2) }}>
            <form onSubmit={handleSubmit} id='edit-qualifications'>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box pb={2}>
                    <Typography variant='h6'>Education</Typography>
                  </Box>
                  <Grid container direction='column' spacing={2}>
                    <Grid item>
                      <Typography>Higher education</Typography>
                      <TextField
                        defaultValue={tutorEducation['higherEducation'] || ''}
                        onBlur={handleEducationBlur}
                        fullWidth
                        variant='outlined'
                        name='higherEducation'
                        multiline
                        minRows={3}
                        placeholder={
                          'e.g. BA (Hons) English Language and Literature 2:1 from the University of Birmingham'
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Typography>Further education</Typography>
                      <TextField
                        defaultValue={tutorEducation['furtherEducation'] || ''}
                        onBlur={handleEducationBlur}
                        fullWidth
                        variant='outlined'
                        name='furtherEducation'
                        multiline
                        minRows={3}
                        placeholder={
                          'e.g. A Levels in English (A), Drama and Theatre Studies (A), Business Studies (B) from Cardiff Sixth Form College'
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Typography>School</Typography>
                      <TextField
                        defaultValue={tutorEducation['school'] || ''}
                        onBlur={handleEducationBlur}
                        fullWidth
                        variant='outlined'
                        name='school'
                        multiline
                        minRows={3}
                        placeholder={'e.g. 9 GCSEs A*-C from Sheffield High School'}
                      />
                    </Grid>
                    <Grid item>
                      <Typography>Other</Typography>
                      <TextField
                        defaultValue={tutorEducation['other'] || ''}
                        onBlur={handleEducationBlur}
                        fullWidth
                        variant='outlined'
                        name='other'
                        multiline
                        minRows={3}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box pt={4} pb={2}>
                    <Typography variant='h6'>Qualifications</Typography>
                  </Box>
                  <Grid container direction='column' spacing={2}>
                    {qualifications.map((q) => (
                      <Grid item key={q.id}>
                        <FormControlLabel
                          label={
                            <>
                              {q.title}{' '}
                              {q.fileUploadRequired ? (
                                <Tooltip title='This qualification requires a file to be uploaded'>
                                  <Icon color='error' style={{ verticalAlign: 'text-bottom' }}>
                                    upload_file_icon
                                  </Icon>
                                </Tooltip>
                              ) : (
                                ''
                              )}
                            </>
                          }
                          control={
                            <Checkbox
                              id={q.id}
                              name={q.title}
                              checked={selectedQualificationIds.some(
                                (qualificationId) => qualificationId === q.id
                              )}
                              onChange={handleQualificationsCheck}
                            />
                          }
                        />
                        {q.fileUploadRequired &&
                          selectedQualificationIds.some(
                            (qualificationId) => qualificationId === q.id
                          ) && (
                            <Box
                              p={2}
                              style={{
                                border: `1px solid ${theme.palette.divider}`,
                                borderRadius: theme.shape.borderRadius,
                                backgroundColor: theme.palette.grey[100],
                              }}
                            >
                              <Box>
                                {() => {
                                  const uploadedFile = uploadedFiles.find(
                                    (file) => file.tag === q.id
                                  );
                                  return uploadedFile ? (
                                    <Box>
                                      <Box pb={2}>
                                        <Typography>
                                          <strong>Status:</strong>{' '}
                                          <span style={{ textTransform: 'capitalize' }}>
                                            {String(uploadedFile.status).split('_').join(' ')}
                                          </span>
                                        </Typography>
                                      </Box>
                                      <LoadingButton
                                        loading={loadingFileId === q.id}
                                        startIcon={<Icon color='error'>delete</Icon>}
                                        color='primary'
                                        variant='outlined'
                                        onClick={() => handleRemoveFile(q.id)}
                                      >
                                        Remove File
                                      </LoadingButton>
                                      <Box m={2} display='inline'>
                                        <CustomLink
                                          href={uploadedFile.downloadUrl}
                                          variant='caption'
                                          target='_blank'
                                        >
                                          (Click here to see the file)
                                        </CustomLink>
                                      </Box>
                                      <Box pt={2}>
                                        <Typography variant='caption'>
                                          <em>
                                            *This qualification will become public on your profile
                                            only after it is approved by an admin.
                                          </em>
                                        </Typography>
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Box>
                                      <Box pb={2}>
                                        <Typography>
                                          *Please upload a copy of your <strong>{q.title}</strong>{' '}
                                          certificate
                                        </Typography>
                                      </Box>
                                      <label htmlFor={q.title}>
                                        <input
                                          hidden
                                          accept='image/*,.pdf'
                                          id={q.title}
                                          type='file'
                                          onChange={(e) => handleFileChange(e, q.id)}
                                        />
                                        <LoadingButton
                                          loading={loadingFileId === q.id}
                                          variant='contained'
                                          color='primary'
                                          startIcon={<Icon>upload</Icon>}
                                          component='span'
                                        >
                                          Upload
                                        </LoadingButton>
                                      </label>
                                    </Box>
                                  );
                                }}
                              </Box>
                            </Box>
                          )}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <Divider />
          {error.serverError && (
            <Box p={2} textAlign='center'>
              <Typography color='error'>{error.serverError}</Typography>
            </Box>
          )}
          <DialogActions>
            <Button
              variant='outlined'
              onClick={() => {
                setOpen(false);
                clearErrors();
                setTutorEducation(parsedEducation);
                setSelectedQualificationIds(tutorQualificationsIds);
              }}
              color='primary'
              disabled={isSubmitting}
            >
              Cancel
            </Button>

            <Button
              form='edit-qualifications'
              type='submit'
              variant='contained'
              color='primary'
              disabled={isSubmitting}
            >
              Save
              {isSubmitting && <CircularProgress size={24} style={{ position: 'absolute' }} />}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default React.memo(Qualifications);
