import Login from 'pages/Login';
import { APP_TITLE, WP_SITE_URL, ROLES } from 'enums';
import { routes } from 'routes';

const { ADMIN, TUTOR, STUDENT, VISITOR } = ROLES;

export const menuConfig = {
  [VISITOR]: [{ title: 'Login', to: routes.login, component: Login, icon: 'login_icon' }],
  [TUTOR]: [
    {
      icon: 'person_outline_icon',
      title: 'Profile',
      to: routes.profile,
    },
    // {
    //   icon: 'groups_icon',
    //   title: 'Group Classes',
    //   to: routes.myGroupClasses,
    // },
    {
      icon: 'event_note_icon',
      title: 'Bookings',
      to: routes.bookings,
    },
    {
      icon: 'notes_icon',
      title: 'Consultations',
      to: routes.consultations,
    },
    {
      icon: 'forum_icon',
      title: 'Messages',
      to: routes.messages,
    },
    {
      icon: 'history_edu_icon',
      title: 'Requests',
      to: routes.requests,
    },

    {
      icon: 'manage_accounts_icon',
      title: 'Account',
      to: routes.account,
    },
    {
      icon: 'menu_book_icon',
      title: 'Handbook',
      to: routes.handbook,
    },
    {
      icon: 'class_icon',
      title: 'Classroom',
      href: 'https://my.bramble.io/sign-in',
    },
    {
      icon: 'exit_to_app_icon',
      title: APP_TITLE,
      href: WP_SITE_URL,
    },
  ],
  [STUDENT]: [
    {
      icon: 'person_search_icon',
      title: 'Find a Tutor/Mentor',
      to: routes.tutors,
    },
    {
      icon: 'event_note_icon',
      title: 'Bookings',
      to: routes.bookings,
    },
    {
      icon: 'notes_icon',
      title: 'Consultations',
      to: routes.consultations,
    },
    {
      icon: 'forum_icon',
      title: 'Messages',
      to: routes.messages,
    },
    {
      icon: 'manage_search_icon',
      title: 'Request a Tutor/Mentor',
      to: routes.requestTutorMentor,
    },

    {
      icon: 'manage_accounts_icon',
      title: 'Account',
      to: routes.account,
    },
    {
      icon: 'exit_to_app_icon',
      title: APP_TITLE,
      href: WP_SITE_URL,
    },
  ],
  [ADMIN]: [
    {
      icon: 'groups_icon',
      title: 'Users',
      to: routes.users,
    },
    {
      icon: 'event_note_icon',
      title: 'Subjects & Levels',
      to: routes.subjects,
    },
    {
      icon: 'forum_icon',
      title: 'Chats',
      to: routes.chats,
    },
    {
      icon: 'rate_review_icon',
      title: 'Pending Reviews',
      to: routes.pendingReviews,
    },
    {
      icon: 'school_icon',
      title: 'Qualifications',
      to: routes.qualifications,
    },
    {
      icon: 'badge_icon',
      title: 'Tutor Certificates',
      to: routes.tutorCertificates,
    },
    {
      icon: 'history_icon',
      title: 'Booking History',
      to: routes.bookingsHistory,
    },
    {
      icon: 'notes_icon',
      title: 'Consultation History',
      to: routes.consultationHistory,
    },
    {
      icon: 'exit_to_app_icon',
      title: APP_TITLE,
      href: WP_SITE_URL,
    },
  ],
};
