import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { useStyles } from './style';
import { useParams } from 'react-router';
import { useAuth, useReviews, useTutors } from 'providers';
import { getWPUserById, updateWPUser } from 'services/wp/users';
import { routes } from 'routes';
import { Skeleton } from '@material-ui/lab';
import { theme } from 'themes';
import { requestTypes, ROLES } from 'enums';
import { collection, query, where, getDoc, doc } from '@firebase/firestore';
import { db } from 'config/firebaseConfig';
import { tutorSubjectsRef } from 'services/firebase/tutorSubjects';
import { getUserRole } from 'utils/user';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { getDateFromObject } from 'utils/date';
import BasicInfo from './BasicInfo';
import Subjects from './Subjects';
import Availability from './Availability';
import Qualifications from './Qualifications';
import history from 'services/history';
import BookingDialog from 'components/BookingDialog';
import Reviews from './Reviews';
import BackgroundCheck from './BackgroundCheck';
import PageLayout from 'components/PageLayout';
import LoadingButton from 'components/LoadingButton';

const isProfileReadyForApproval = (tutor) => {
  const {
    name,
    subjects = [],
    meta: { profile_bio, profile_headline, hours_rate, profile_status },
  } = tutor;

  return (
    !['approved', 'awaiting_approval'].includes(profile_status?.[0]) &&
    name &&
    subjects?.length > 0 &&
    profile_bio?.[0] &&
    profile_headline?.[0] &&
    hours_rate?.[0]
  );
};

/**
 * @TODO - improve logic
 *
 */

function Profile({ tutorId = null, isEditing = false }) {
  const classes = useStyles();
  const { id = tutorId } = useParams();
  const { user, setUser } = useAuth();
  const { reviews, reviewsLoaded, loading: loadingReviews, loadReviews } = useReviews();
  const { tutors } = useTutors();
  const [tutor, setTutor] = useState(null);
  const [loading, setLoading] = useState(Boolean(id));
  const [submittingForApproval, setSubmittingForApproval] = useState(false);
  const [error, setError] = useState({});
  const [requestType, setRequestType] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

  const [bookings = [], loadingBookings] = useCollectionData(
    query(
      collection(db, 'bookings'),
      where('tutor.id', '==', id && !isEditing ? Number(id) : user.id)
    ),
    { idField: 'id' }
  );

  const mappedBookings = useMemo(
    () =>
      bookings.length
        ? bookings.map((booking) => ({
            ...booking,
            selectedDate: getDateFromObject(booking.selectedDate),
          }))
        : [],
    [bookings]
  );

  useEffect(() => {
    const loadTutor = async () => {
      try {
        const tutor = tutors.find((t) => String(t.id) === String(id)) || (await getWPUserById(id));
        if (getUserRole(tutor) !== ROLES.TUTOR) {
          throw new Error('Invalid user profile');
        }

        if (!tutor.subjects) {
          const { subjects = [] } = (await getDoc(doc(tutorSubjectsRef, id))).data();
          tutor.subjects = subjects;
        }
        setTutor(tutor);
        setLoading(false);
      } catch (error) {
        // tutor doesn't exist
        return history.push(routes.tutors);
      }
    };
    if (id && !isEditing) {
      loadTutor();
    }
  }, [id, isEditing, tutors]);

  useEffect(() => {
    if (!reviewsLoaded && !loadingReviews && !isEditing) {
      loadReviews();
    }
  }, [isEditing, loadReviews, loadingReviews, reviewsLoaded]);

  const handleDialogClose = useCallback(() => {
    setRequestType(null);
  }, []);

  const handleTimeSlotSelect = useCallback(
    (date, hour) => {
      if (isEditing) {
        return;
      }
      setRequestType(requestTypes.lesson);
      setSelectedTimeSlot({ date, hour });
    },
    [isEditing]
  );

  const handleSubmitForApproval = async () => {
    setSubmittingForApproval(true);
    try {
      const newUserData = (
        await updateWPUser(user.id, {
          meta: { profile_status: 'awaiting_approval' },
        })
      ).data;
      setUser((prevUserData) => ({ ...prevUserData, ...newUserData }));
    } catch (error) {
      setError({ submittingForApprovalError: error.message });
    }
    setSubmittingForApproval(false);
  };

  const tutorReviews = useMemo(
    () => (tutor && reviews ? reviews.filter((r) => r.reviewed.id === tutor.id) : []),
    [reviews, tutor]
  );

  const tutorWithReviews = useMemo(
    () => (tutor ? { ...tutor, reviews: tutorReviews } : tutor),
    [tutor, tutorReviews]
  );

  const hoursTaught = useMemo(() => {
    if (!mappedBookings.length) {
      return 0;
    }
    const now = new Date();
    const olderBookings = mappedBookings.filter((b) => b.selectedDate < now);
    return olderBookings.map((b) => b.duration).reduce((prev, curr) => prev + curr, 0);
  }, [mappedBookings]);
  const isAdmin = useMemo(() => getUserRole(user) === ROLES.ADMIN, [user]);
  const isTutor = useMemo(() => getUserRole(user) === ROLES.TUTOR, [user]);
  const isStudent = useMemo(() => getUserRole(user) === ROLES.STUDENT, [user]);

  return (
    <>
      <PageLayout
        bgcolor={theme.palette.grey[200]}
        style={tutorId ? { paddingTop: theme.spacing(10) } : undefined}
      >
        {isEditing && (
          <Box pb={2} textAlign='right'>
            <Typography style={{ textTransform: 'capitalize' }}>
              <strong>Profile Status:</strong>{' '}
              {user.meta.profile_status?.[0]?.split('_').join(' ') ||
                (isProfileReadyForApproval(user) ? 'Ready for submission' : 'Incomplete')}
            </Typography>
          </Box>
        )}
        <section className={classes.profileSection}>
          {loading ? (
            <Box>
              <Grid container justifyContent='space-between'>
                <Grid item xs>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item>
                      <Skeleton variant='circle' width={250} height={250} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Skeleton variant='text' width={'100%'} height={40} />
                      <Skeleton variant='text' width={'70%'} height={20} />
                      <Skeleton variant='text' width={'80%'} height={40} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Skeleton variant='text' width={100} height={40} />
                </Grid>
              </Grid>
              <Box pt={2}>
                <Skeleton variant='rect' width='70%' />
              </Box>
              <Box pt={2}>
                <Skeleton variant='rect' height={200} />
              </Box>
            </Box>
          ) : (
            <BasicInfo tutor={tutorWithReviews} isAdmin={isAdmin} hoursTaught={hoursTaught} />
          )}
        </section>

        <section className={classes.profileSection}>
          {!loading && (
            <Availability
              tutor={tutor}
              tutorBookings={mappedBookings}
              loadingBookings={loadingBookings}
              onTimeSlotSelect={handleTimeSlotSelect}
            />
          )}
        </section>

        <section className={classes.profileSection}>
          {loading ? (
            <Box>
              <Skeleton variant='rect' height={50} width={150} />
              <Box pt={2}>
                <Skeleton variant='rect' height={200} />
              </Box>
            </Box>
          ) : (
            <Subjects tutor={tutor} />
          )}
        </section>

        <section className={classes.profileSection}>
          {!loading && <Qualifications tutor={tutor} />}
        </section>
        {isTutor && (
          <section className={classes.profileSection}>
            <BackgroundCheck />
          </section>
        )}
        {!isTutor && (
          <section className={classes.profileSection} id='reviews-section'>
            {!loading && <Reviews reviews={tutorWithReviews.reviews} />}
          </section>
        )}
      </PageLayout>
      {isStudent && (
        <Box className={classes.bookingBar}>
          <Container>
            <Grid container spacing={2} justifyContent='flex-end'>
              <Grid item xs md={3}>
                <Button
                  fullWidth
                  variant='outlined'
                  color='primary'
                  onClick={() => setRequestType(requestTypes.consultation)}
                >
                  Request consultation
                </Button>
              </Grid>
              <Grid item xs md={3}>
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    setSelectedTimeSlot(null);
                    setRequestType(requestTypes.lesson);
                  }}
                >
                  Request lesson
                </Button>
              </Grid>
            </Grid>
          </Container>
          <BookingDialog
            requestType={requestType}
            onClose={handleDialogClose}
            tutor={tutor}
            bookings={mappedBookings}
            selectedTimeSlot={selectedTimeSlot}
            hoursTaught={hoursTaught}
          />
        </Box>
      )}
      {isTutor && isEditing && isProfileReadyForApproval(user) && (
        <Box className={classes.bookingBar}>
          <Container>
            {error['submittingForApprovalError'] && (
              <Box pb={2}>
                <Typography>{error['submittingForApprovalError']}</Typography>
              </Box>
            )}
            <Grid container justifyContent='flex-end'>
              <Grid item xs={12} sm={4} md={3}>
                <LoadingButton
                  loading={submittingForApproval}
                  fullWidth
                  variant='contained'
                  color='primary'
                  onClick={handleSubmitForApproval}
                >
                  Submit for approval
                </LoadingButton>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
}

export default React.memo(Profile);
