import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useAuth } from 'providers';
import { deleteFile, uploadFile } from 'services/firebase/storage';
import { isFileImage, isPDF } from 'utils/imageFile';
import { theme } from 'themes';
import { collection, doc, query, updateDoc, where } from 'firebase/firestore';
import { db } from 'config/firebaseConfig';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Skeleton } from '@material-ui/lab';
import { createFileToApproveDoc, deleteFileToApprove } from 'services/firebase/filesToApprove';
import LoadingButton from 'components/LoadingButton';
import CustomLink from 'components/CustomLink';

function BackgroundCheck() {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const [newDBSFile, setNewDBSFile] = useState(null);
  const [newQTSFile, setNewQTSFile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadedFiles = [], loadingFiles] = useCollectionData(
    query(collection(db, 'filesToApprove'), where('uploadedBy.id', '==', user.id)),
    { idField: 'id' }
  );

  const oldDBSFile = useMemo(
    () => uploadedFiles.find((file) => file.tag === 'DBS'),
    [uploadedFiles]
  );
  const oldQTSFile = useMemo(
    () => uploadedFiles.find((file) => file.tag === 'QTS'),
    [uploadedFiles]
  );

  useEffect(() => {
    setNewDBSFile(oldDBSFile);
  }, [oldDBSFile]);

  useEffect(() => {
    setNewQTSFile(oldQTSFile);
  }, [oldQTSFile]);

  const handleDBSChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setNewDBSFile(file);
  };

  const handleQTSChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setNewQTSFile(file);
  };

  const handleFileChange = async (tag, newFile, oldFile) => {
    // upload new file
    const downloadUrl = await uploadFile(
      `uploads/${user.id}/${tag}.${newFile.name.split('.').pop()}`,
      newFile
    );

    // update doc
    if (oldFile) {
      await deleteFile(oldFile.downloadUrl).catch((err) => {});
      await updateDoc(doc(db, 'filesToApprove', oldFile.id), {
        downloadUrl,
        status: 'pending_approval',
        createdAt: new Date(),
      });
    } else {
      // create doc
      await createFileToApproveDoc(
        { name: user.name, email: user.email, id: user.id },
        tag,
        downloadUrl
      );
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (newDBSFile && !newDBSFile.downloadUrl) {
        if (!(isFileImage(newDBSFile) || isPDF(newDBSFile))) {
          setLoading(false);
          return setError('DBS: Invalid file type! Only Images and PDFs allowed.');
        }

        await handleFileChange('DBS', newDBSFile, oldDBSFile);
      }

      if (newQTSFile && !newQTSFile.downloadUrl) {
        if (!(isFileImage(newQTSFile) || isPDF(newQTSFile))) {
          setLoading(false);
          return setError('QTS: Invalid file type! Only Images and PDFs allowed.');
        }

        await handleFileChange('QTS', newQTSFile, oldQTSFile);
      }

      if (oldDBSFile && !newDBSFile) {
        await deleteFile(oldDBSFile.downloadUrl).catch((err) => {});
        await deleteFileToApprove(oldDBSFile.id);
      }

      if (oldQTSFile && !newQTSFile) {
        await deleteFile(oldQTSFile.downloadUrl).catch((err) => {});
        await deleteFileToApprove(oldQTSFile.id);
      }
      setLoading(false);
      setOpen(false);
    } catch (error) {
      setLoading(false);
      return setError(error.message);
    }
  };

  return (
    <Box>
      <Grid container justifyContent='space-between'>
        <Grid item xs>
          <Typography variant='h4' color='textSecondary'>
            DBS/QTS (Optional)
            <Tooltip title='This section will not be visible on your profile.'>
              <Icon
                style={{ verticalAlign: 'middle', marginLeft: theme.spacing(2) }}
                color='action'
              >
                visibility_off
              </Icon>
            </Tooltip>
          </Typography>
        </Grid>

        <Grid item>
          <Tooltip title='Edit DBS/QTS'>
            <IconButton onClick={() => setOpen(true)}>
              <Icon>edit</Icon>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Box py={1}>
            <Divider />
          </Box>
        </Grid>
      </Grid>

      <Box py={2}>
        <Typography color='textSecondary' style={{ fontStyle: 'italic' }}>
          *This section will not be visible on your profile.
        </Typography>
      </Box>

      <Box>
        <Typography variant='h6' gutterBottom>
          Background check
        </Typography>
        {loadingFiles && <Skeleton width='40%' height={25} />}
        {!loadingFiles && !oldDBSFile && (
          <Typography color='textSecondary'>No files uploaded</Typography>
        )}
        {oldDBSFile && (
          <Box>
            <Typography>
              <strong>File: </strong>
              <CustomLink href={oldDBSFile.downloadUrl} target='_blank'>
                (Click here to see the file)
              </CustomLink>
            </Typography>
            <Typography style={{ textTransform: 'capitalize' }}>
              <strong>Status:</strong> {String(oldDBSFile.status).replace('_', ' ')}
            </Typography>
          </Box>
        )}
      </Box>

      <Box pt={4}>
        <Typography variant='h6' gutterBottom>
          Qualified teacher status
        </Typography>
        {loadingFiles && <Skeleton width='40%' height={25} />}
        {!loadingFiles && !oldQTSFile && (
          <Typography color='textSecondary'>No files uploaded</Typography>
        )}
        {oldQTSFile && (
          <Box>
            <Typography>
              <strong>File: </strong>
              <CustomLink href={oldQTSFile.downloadUrl} target='_blank'>
                (Click here to see the file)
              </CustomLink>
            </Typography>
            <Typography style={{ textTransform: 'capitalize' }}>
              <strong>Status:</strong> {String(oldQTSFile.status).replace('_', ' ')}
            </Typography>
          </Box>
        )}
      </Box>
      {open && (
        <Dialog open={true} fullWidth maxWidth='md'>
          <DialogTitle style={{ textAlign: 'center' }}>Edit QTS/DBS</DialogTitle>
          <Divider />
          <DialogContent>
            <Box pt={2}>
              <Typography variant='h6' gutterBottom>
                Background check
              </Typography>
              <Typography color='textSecondary'>
                To get your "
                <Icon style={{ verticalAlign: 'text-bottom' }}>verified_user_icon</Icon>
                <strong>DBS checked</strong>" badge, please upload a copy of your DBS check
                certificate.
              </Typography>
              <br />
              <Typography color='textSecondary'>
                Your DBS certificate and personal details will not be visible to other users.
              </Typography>
              <br />
              <Typography color='error'>
                *This must be a colour copy of your enhanced DBS certificate issued within the last
                2 years.
              </Typography>
              <br />
              <Box
                p={2}
                style={{
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: theme.shape.borderRadius,
                  backgroundColor: theme.palette.grey[100],
                }}
              >
                {newDBSFile ? (
                  <Typography style={{ textTransform: 'capitalize' }}>
                    <strong>Status:</strong>{' '}
                    {String(newDBSFile.status || 'pending_approval').replace('_', ' ')}
                  </Typography>
                ) : (
                  <Typography>*Please upload a copy of your DBS certificate.</Typography>
                )}
                <Box pt={2}>
                  {newDBSFile ? (
                    <Box>
                      <Button
                        startIcon={<Icon color='error'>delete</Icon>}
                        color='primary'
                        variant='outlined'
                        onClick={() => setNewDBSFile(null)}
                      >
                        Remove File
                      </Button>
                      <Box ml={2} display='inline'>
                        {newDBSFile.downloadUrl ? (
                          <CustomLink
                            variant='caption'
                            href={newDBSFile.downloadUrl}
                            target='_blank'
                          >
                            (Click here to see the file)
                          </CustomLink>
                        ) : (
                          <Typography variant='caption'>{newDBSFile.name}</Typography>
                        )}
                      </Box>
                    </Box>
                  ) : (
                    <label htmlFor='dbs-check-file'>
                      <input
                        hidden
                        accept='image/*,.pdf'
                        id='dbs-check-file'
                        type='file'
                        onChange={handleDBSChange}
                      />
                      <Button
                        variant='contained'
                        color='primary'
                        startIcon={<Icon>upload</Icon>}
                        component='span'
                      >
                        Upload
                      </Button>
                    </label>
                  )}
                </Box>
              </Box>
            </Box>

            <Box pt={10} pb={4}>
              <Typography variant='h6' gutterBottom>
                Qualified teacher status
              </Typography>
              <Typography color='textSecondary'>
                If you have <strong>Qualified Teacher Status</strong>, please upload a copy of your
                QTS certificate.
                <br />
              </Typography>
              <br />
              <Box
                style={{
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: theme.shape.borderRadius,
                  backgroundColor: theme.palette.grey[100],
                }}
                p={2}
              >
                {newQTSFile ? (
                  <Typography style={{ textTransform: 'capitalize' }}>
                    <strong>Status:</strong>{' '}
                    {String(newQTSFile.status || 'pending_approval').replace('_', ' ')}
                  </Typography>
                ) : (
                  <Typography>*Please upload a copy of your QTS certificate.</Typography>
                )}
                <Box pt={2}>
                  {newQTSFile ? (
                    <Box
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    >
                      <Button
                        startIcon={<Icon color='error'>delete</Icon>}
                        color='primary'
                        variant='outlined'
                        onClick={() => setNewQTSFile(null)}
                      >
                        Remove File
                      </Button>
                      <Box ml={2} display='inline'>
                        {newQTSFile.downloadUrl ? (
                          <CustomLink
                            variant='caption'
                            href={newQTSFile.downloadUrl}
                            target='_blank'
                          >
                            (Click here to see the file)
                          </CustomLink>
                        ) : (
                          <Typography variant='caption'>{newQTSFile.name}</Typography>
                        )}
                      </Box>
                    </Box>
                  ) : (
                    <label htmlFor='qts-check-file'>
                      <input
                        hidden
                        accept='image/*,.pdf'
                        id='qts-check-file'
                        type='file'
                        onChange={handleQTSChange}
                      />
                      <Button
                        variant='contained'
                        color='primary'
                        startIcon={<Icon>upload</Icon>}
                        component='span'
                      >
                        Upload
                      </Button>
                    </label>
                  )}
                </Box>
              </Box>
            </Box>
          </DialogContent>
          <Divider />
          <Box pt={2} textAlign='center'>
            {error && <Typography color='error'>{error}</Typography>}
          </Box>
          <DialogActions>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => {
                setNewDBSFile(oldDBSFile || null);
                setNewQTSFile(oldQTSFile || null);
                setError(null);
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton onClick={handleSave} loading={loading}>
              Save
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}

export default React.memo(BackgroundCheck);
