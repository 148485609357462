import React, { useMemo, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Grid, Icon, IconButton, Tooltip, Typography } from '@material-ui/core';
import { prettifyDateTime } from 'utils/date';
import { routes } from 'routes';
import history from 'services/history';
import StudentsCell from 'components/StudentsCell';

const getColumns = (userRole) => [
  {
    field: 'dateTime',
    headerName: 'Date & Time',
    renderHeader: () => (
      <Grid container alignItems='center'>
        <Icon>{'event_icon'}</Icon> &nbsp; Date & Time
      </Grid>
    ),
    width: 190,
    type: 'date',
    renderCell: ({ value }) =>
      value?.toLocaleString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }),
  },
  {
    field: 'duration',
    headerName: 'Duration',
    renderHeader: () => (
      <Grid container alignItems='center'>
        <Icon>{'timelapse_icon'}</Icon>&nbsp; Duration
      </Grid>
    ),
    width: 165,
    renderCell: ({ value }) => `${value} hour${value > 1 ? 's' : ''}`,
  },
  {
    field: 'subject',
    headerName: 'Subject',
    renderHeader: () => (
      <Grid container alignItems='center'>
        <Icon>{'subject_icon'}</Icon>&nbsp; Subject
      </Grid>
    ),
    width: 220,
    renderCell: ({ row }) => (
      <Grid container direction='column'>
        <span style={{ lineHeight: 1.3, fontWeight: 'bold' }}>{row.subject}</span>
        <Typography variant='caption' style={{ lineHeight: 1.3 }}>
          {row.level || '-'}
        </Typography>
      </Grid>
    ),
  },
  {
    field: 'inPerson',
    headerName: 'In Person',
    renderHeader: () => (
      <Grid container alignItems='center'>
        <Icon>{'directions_walk_icon'}</Icon>&nbsp; In Person
      </Grid>
    ),
    width: 170,
    renderCell: ({ value }) => (value ? 'Yes' : 'No'),
  },
  {
    field: 'groupClass',
    headerName: 'Lesson Type',
    renderHeader: () => (
      <Grid container alignItems='center' wrap='nowrap'>
        <Icon>{'groups_icon'}</Icon>&nbsp;
        <span style={{ textTransform: 'capitalize' }}>&nbsp; Lesson Type</span>
      </Grid>
    ),
    renderCell: ({ value }) => (
      <Tooltip title={value ? 'Group Class' : 'One to One'}>
        <Icon color='action'>{value ? 'groups_icon' : 'person_icon'}</Icon>
      </Tooltip>
    ),
    width: 160,
  },
  {
    field: userRole === 'student' ? 'tutor' : 'student',
    headerName: userRole === 'student' ? 'Tutor' : 'Student(s)',
    renderHeader: () => (
      <Grid container alignItems='center' wrap='nowrap'>
        <Icon>{'person_icon'}</Icon>&nbsp;
        <span>{userRole === 'student' ? 'Tutor' : 'Student(s)'}</span>
      </Grid>
    ),
    width: 320,
    valueGetter: ({ value }) => value?.name,
    renderCell: ({ row, value }) =>
      row.groupClass && userRole === 'tutor' ? (
        <StudentsCell students={[row.student, ...row.invitedUsers]} />
      ) : (
        <Grid container style={{ paddingLeft: 14 }}>
          <Grid item xs container direction='column' justifyContent='center'>
            <span
              style={{
                lineHeight: 1.3,
                textTransform: 'capitalize',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 220,
              }}
            >
              <strong>{value}</strong>
            </span>
            <Typography
              variant='caption'
              style={{
                lineHeight: 1.3,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 220,
              }}
            >
              {row[userRole === 'student' ? 'tutor' : 'student']?.email}
            </Typography>
          </Grid>

          <Grid item>
            <Tooltip
              arrow
              title={
                <>
                  Send a message to{' '}
                  <span style={{ textTransform: 'capitalize' }}>
                    {row[userRole === 'student' ? 'tutor' : 'student']?.name?.split(' ')[0]}
                  </span>
                </>
              }
            >
              <IconButton
                onClick={() =>
                  history.push(routes.messages, {
                    chatWith: row[userRole === 'student' ? 'tutor' : 'student']?.id,
                  })
                }
              >
                <Icon>message</Icon>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ),
  },
  {
    field: 'cancelledBy',
    headerName: 'Cancelled By',
    width: 150,
    renderCell: ({ value }) => value,
  },
  {
    field: 'cancelDate',
    headerName: 'Cancel Date',
    width: 150,
    renderCell: ({ value }) => prettifyDateTime(value) || '-',
    type: 'date',
  },
  {
    field: 'refund',
    headerName: 'Refund Status',
    width: 200,
    valueGetter: ({ row }) => row.refund?.status,
    renderCell: ({ value }) => <span style={{ textTransform: 'capitalize' }}>{value || '-'}</span>,
  },
];

const initialSortModel = {
  field: 'cancelDate',
  sort: 'desc',
};

function Cancelled({ rows, loading, className, userRole, onRowClick }) {
  const [pageSize, setPageSize] = useState(5);
  const [sortModel, setSortModel] = useState([initialSortModel]);
  const columns = useMemo(() => getColumns(userRole), [userRole]);

  return (
    <DataGrid
      rows={rows}
      className={className}
      columns={columns}
      loading={loading}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      rowsPerPageOptions={[5, 10, 20]}
      autoHeight
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      onRowClick={onRowClick}
    />
  );
}

export default Cancelled;
