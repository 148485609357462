import React, { createContext, useCallback, useContext, useState } from 'react';
import { getApprovedReviews } from 'services/firebase/reviews';

const ReviewsContext = createContext();
export const useReviews = () => useContext(ReviewsContext);

export function ReviewsProvider({ children }) {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reviewsLoaded, setReviewsLoaded] = useState(false);

  const loadReviews = useCallback(() => {
    setLoading(true);
    getApprovedReviews()
      .then((reviews) => {
        setReviews(reviews);
      })
      .catch((err) => {
        //
      })
      .finally(() => {
        setReviewsLoaded(true);
        setLoading(false);
      });
  }, []);

  return (
    <ReviewsContext.Provider value={{ reviews, loading, loadReviews, reviewsLoaded }}>
      {children}
    </ReviewsContext.Provider>
  );
}
