import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  Divider,
  Grid,
  Icon,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { addSubject } from 'services/firebase/subjects';
import { theme } from 'themes';
import { useCategories } from 'providers';
import SubjectsEditor from 'components/SubjectsEditor';

const DialogContent = React.memo(({ onCancel, categories }) => {
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const [levels, setLevels] = useState([]);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const handleAddSubject = async (e) => {
    e.preventDefault();
    setError(null);
    setSubmitting(true);
    const category = e.target.category.value;
    const name = e.target.subjectName.value;

    try {
      await addSubject({ category, name, levels, createdAt: new Date() });
      onCancel();
    } catch (error) {
      setError(error?.message);
    }

    setSubmitting(false);
  };

  const handleLevelsChange = useCallback((e, value) => setLevels(value), []);

  return (
    <Box p={2}>
      <form onSubmit={handleAddSubject}>
        <Box textAlign='center'>
          <Typography gutterBottom variant='h6'>
            Add New Subject
          </Typography>
        </Box>
        <Box pb={2}>
          <Divider />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              name='category'
              label='Category'
              variant='outlined'
              select
              defaultValue=''
            >
              <MenuItem value={''} key={'none'}>
                <em>None</em>
              </MenuItem>
              {categories.map(({ id }) => (
                <MenuItem value={id} key={id}>
                  {id}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              variant='outlined'
              label='Subject Name'
              name='subjectName'
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              onChange={handleLevelsChange}
              value={levels}
              fullWidth
              multiple
              id='levels'
              options={[]}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    color='primary'
                    variant='outlined'
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  placeholder='Type and press Enter'
                  label='Subject Levels'
                  name='levels'
                />
              )}
            />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Box textAlign='center'>
                <Typography color='error'>{error}</Typography>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container justifyContent='flex-end' spacing={2}>
              <Grid item xs={xs}>
                <Button
                  fullWidth
                  variant='outlined'
                  color='primary'
                  disabled={submitting}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={xs}>
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={submitting}
                >
                  Save
                  {submitting && <CircularProgress size={24} style={{ position: 'absolute' }} />}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
});

function Subjects() {
  const { categories, loading } = useCategories();

  const [isAdding, setIsAdding] = useState(false);

  const handleCancel = useCallback(() => setIsAdding(false), []);

  return (
    <Box pt={18} pb={10}>
      <Container>
        <Box textAlign='center' pb={10}>
          <Typography variant='h2'>Subjects & Levels</Typography>
        </Box>
        {loading ? (
          <Box style={{ display: 'grid', placeItems: 'center', height: 700 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Grid container justifyContent='flex-end'>
              <Box pb={4}>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<Icon>add</Icon>}
                  onClick={() => setIsAdding(true)}
                >
                  Add Subject
                </Button>
              </Box>
            </Grid>
            <SubjectsEditor categories={categories} />
            <Dialog open={isAdding} maxWidth='md' fullWidth>
              <DialogContent onCancel={handleCancel} categories={categories} />
            </Dialog>
          </>
        )}
      </Container>
    </Box>
  );
}

export default React.memo(Subjects);
