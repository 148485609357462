import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';

function LoadingButton({ loading, spinnerSize = 24, children, disabled, ...props }) {
  return (
    <Button disabled={disabled || loading} color='primary' variant='contained' {...props}>
      {loading && (
        <CircularProgress
          size={spinnerSize}
          style={{ position: 'absolute', transform: 'translateX(-50)' }}
        />
      )}
      {children}
    </Button>
  );
}

export default LoadingButton;
