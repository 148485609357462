import React, { useCallback, useMemo, useState } from 'react';
import Profile from 'pages/Profile';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { collection, query, where, Timestamp, orderBy } from 'firebase/firestore';
import { db } from 'config/firebaseConfig';
import { getDateFromObject } from 'utils/date';
import { COLORS, theme } from 'themes';
import { APP_TITLE, POUND_SYMBOL } from 'enums';
import { useStyles } from './style';
import { computeEarning } from 'utils/earnings';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Icon,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@material-ui/core';
import TabPanel from 'components/TabPanel';

const tabProps = (index) => ({
  id: `tutor-details-tab-${index}`,
  'aria-controls': `tutor-details-tabpanel-${index}`,
});

const currentMonth = new Date().toLocaleDateString('en-GB', {
  month: 'long',
  year: 'numeric',
});

const computeSubtotal = (duration, rate, nrOfStudents) => duration * rate * nrOfStudents;
const computeProfit = (amount, hoursTaught) => (amount / 100) * (hoursTaught < 250 ? 25 : 20);
const computeTotal = (bookings) =>
  bookings.reduce(
    (prev, curr) =>
      prev +
      (curr.groupClass
        ? curr.tutor.rate * curr.duration
        : computeEarning(curr.paymentDetails.amount / 100, curr.tutor.hoursTaught < 250 ? 25 : 20)),
    0
  );

function TutorDetails({ tutor }) {
  const classes = useStyles();
  const [tutorDetailsTab, setTutorDetailsTab] = useState(0);
  const tutorBookingsQuery = useMemo(
    () =>
      query(
        collection(db, 'bookings'),
        where('tutor.id', '==', tutor.id),
        where('selectedDate', '<=', Timestamp.fromDate(new Date())),
        orderBy('selectedDate', 'desc')
      ),
    [tutor.id]
  );
  const [tutorBookings = [], loadingTutorBookings] = useCollectionDataOnce(tutorBookingsQuery, {
    idField: 'id',
  });

  const filteredBookings = useMemo(
    () =>
      tutorBookings
        .filter((tb) => !tb.cancelled && !tb.cancelledBy)
        .map((tb) => ({
          ...tb,
          createdAt: getDateFromObject(tb.createdAt),
          selectedDate: getDateFromObject(tb.selectedDate),
        })),
    [tutorBookings]
  );

  const monthlyEarnings = useMemo(
    () =>
      [
        ...new Set(
          filteredBookings.map((b) =>
            b.selectedDate.toLocaleDateString('en-GB', { month: 'long', year: 'numeric' })
          )
        ),
      ].map((month) => ({
        month,
        bookings: filteredBookings.filter(
          (b) =>
            b.selectedDate.toLocaleDateString('en-GB', { month: 'long', year: 'numeric' }) === month
        ),
      })),

    [filteredBookings]
  );

  const handleTutorDetailsTabChange = useCallback((e, value) => {
    setTutorDetailsTab(value);
  }, []);

  return (
    <Box>
      <Container>
        <Box textAlign='center' pb={10}>
          <Typography variant='h2'>{tutor.name}</Typography>
        </Box>

        <Tabs
          value={tutorDetailsTab}
          onChange={handleTutorDetailsTabChange}
          aria-label='User info tabs'
          variant='fullWidth'
          scrollButtons='auto'
        >
          <Tab label='Profile' {...tabProps(0)} />
          <Tab label='Earnings' {...tabProps(1)} />
        </Tabs>

        <Divider />
      </Container>

      <Box>
        <TabPanel value={tutorDetailsTab} index={0}>
          <Profile tutorId={tutor.id} />
        </TabPanel>

        <TabPanel value={tutorDetailsTab} index={1}>
          <Box py={10} bgcolor={COLORS.lightGrey}>
            {monthlyEarnings.length > 0 && (
              <Box textAlign='center' pb={10}>
                <Typography variant='h6'>
                  Total earnings: {POUND_SYMBOL}
                  {monthlyEarnings
                    .reduce((prev, curr) => prev + computeTotal(curr.bookings), 0)
                    .toFixed(2)}
                </Typography>
              </Box>
            )}
            <Container>
              {loadingTutorBookings && (
                <Box style={{ display: 'grid', placeItems: 'center' }}>
                  <CircularProgress />
                </Box>
              )}
              {!loadingTutorBookings && !monthlyEarnings.length && (
                <Box
                  py={2}
                  style={{
                    display: 'grid',
                    placeItems: 'center',
                    backgroundColor: theme.palette.background.paper,
                  }}
                >
                  <Typography color='textSecondary'>No Results</Typography>
                </Box>
              )}

              {monthlyEarnings.map(({ month, bookings }, index) => (
                <Accordion key={month} TransitionProps={{ unmountOnExit: true }}>
                  <AccordionSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    aria-controls={`panel-${index}-control`}
                    id={`panel-${index}-header`}
                  >
                    <Grid container alignItems='center' justifyContent='space-between'>
                      <Grid item>
                        <Typography>
                          {month}
                          {index === 0 && month === currentMonth ? ' (so far)' : ''}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: 80,
                          }}
                        >
                          <strong>
                            {POUND_SYMBOL}
                            {computeTotal(bookings).toFixed(2)}
                          </strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box style={{ overflowX: 'auto', flex: 1 }}>
                      <Typography variant='h6' gutterBottom style={{ position: 'sticky', left: 0 }}>
                        Bookings
                      </Typography>
                      <table className={classes.earningsTable}>
                        <thead>
                          <tr>
                            <th>
                              <Typography variant='body2'>Date</Typography>
                            </th>
                            <th>
                              <Typography variant='body2'>Time</Typography>
                            </th>
                            <th>
                              <Typography variant='body2'>Duration</Typography>
                            </th>
                            <th>
                              <Typography variant='body2'>Lesson Type</Typography>
                            </th>
                            <th>
                              <Typography variant='body2'>Students</Typography>
                            </th>
                            <th>
                              <Typography variant='body2'>Tutor Rate/Hour</Typography>
                            </th>
                            <th>
                              <Typography variant='body2'>Subtotal</Typography>
                            </th>
                            <th>
                              <Typography variant='body2'>Discount %</Typography>
                            </th>
                            <th>
                              <Typography variant='body2'>Total Paid</Typography>
                            </th>
                            <th>
                              <Typography variant='body2'>{APP_TITLE} profit</Typography>
                            </th>
                            <th>
                              <Typography variant='body2'>Tutor earnings</Typography>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {bookings.map((b) => (
                            <tr key={b.id}>
                              <td data-item='date'>
                                <Typography variant='body2'>
                                  {b.selectedDate.toLocaleDateString('en-GB')}
                                </Typography>
                              </td>
                              <td data-item='time'>
                                <Typography variant='body2'>{b.startTime}:00</Typography>
                              </td>
                              <td data-item='duration'>
                                <Typography variant='body2'>{`${b.duration} hour${
                                  b.duration > 1 ? 's' : ''
                                }`}</Typography>
                              </td>
                              <td data-item='lessonType'>
                                <Typography variant='body2'>
                                  {b.groupClass ? 'Group Class' : 'One-to-one'}
                                </Typography>
                              </td>
                              <td data-item='students'>
                                <Typography variant='body2'>
                                  {b.groupClass ? b.invitedUsers.length + 1 : 1}
                                </Typography>
                              </td>
                              <td data-item='ratePerHour'>
                                <Typography variant='body2'>
                                  {POUND_SYMBOL}
                                  {(b.tutor.rate ? Number(b.tutor.rate) : 0).toFixed(2)}
                                </Typography>
                              </td>
                              <td data-item='subtotal'>
                                <Typography variant='body2'>
                                  {POUND_SYMBOL}
                                  {computeSubtotal(
                                    b.duration,
                                    b.tutor.rate,
                                    1 + b.invitedUsers.length
                                  ).toFixed(2)}
                                </Typography>
                              </td>
                              <td data-item='discount'>
                                <Typography variant='body2'>
                                  {b.groupClass ? <>25%</> : '-'}
                                </Typography>
                              </td>
                              <td data-item='paidAmount'>
                                <Typography variant='body2'>
                                  {POUND_SYMBOL}
                                  {(b.paymentDetails.amount / 100).toFixed(2)}
                                </Typography>
                              </td>
                              <td data-item='profit' style={{ position: 'relative' }}>
                                <Typography variant='body2'>
                                  {POUND_SYMBOL}
                                  {(b.groupClass
                                    ? b.paymentDetails.amount / 100 - b.tutor.rate * b.duration
                                    : computeProfit(
                                        b.paymentDetails.amount / 100,
                                        b.tutor.hoursTaught
                                      )
                                  ).toFixed(2)}
                                  {b.groupClass ? (
                                    ''
                                  ) : (
                                    <Tooltip
                                      title={
                                        b.tutor.hoursTaught < 250
                                          ? '25% for 1-249 hours taught'
                                          : '20% for 250+ hours taught'
                                      }
                                    >
                                      <Icon
                                        color='action'
                                        style={{
                                          position: 'absolute',
                                          top: 5,
                                          right: 8,
                                        }}
                                      >
                                        info
                                      </Icon>
                                    </Tooltip>
                                  )}
                                </Typography>
                              </td>
                              <td data-item='earnings'>
                                <Typography variant='body2'>
                                  {POUND_SYMBOL}
                                  {(b.groupClass
                                    ? b.tutor.rate * b.duration
                                    : computeEarning(
                                        b.paymentDetails.amount / 100,
                                        b.tutor.hoursTaught < 250 ? 25 : 20
                                      )
                                  ).toFixed(2)}
                                </Typography>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={12} style={{ textAlign: 'right' }}>
                              <Typography>
                                <strong>
                                  Total:&nbsp;
                                  {POUND_SYMBOL}
                                  {computeTotal(bookings).toFixed(2)}
                                </strong>
                              </Typography>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Container>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
}

export default React.memo(TutorDetails);
