import haversine from 'haversine-distance';
import { getTomorrow } from './date';
import { getMiles } from './distance';

export const filterTutors = (tutors = [], filters) => {
  const {
    searchFor,
    subject,
    level,
    inPerson,
    address,
    travelDistance = 1,
    sortBy,
    priceRange,
    availability,
    additionalFilters,
    gender,
  } = filters;
  let filteredResults = tutors.length ? JSON.parse(JSON.stringify(tutors)) : [];

  if (filteredResults.length) {
    // only show approved tutors with subjects
    filteredResults = filteredResults.filter(
      (tutor) => tutor.meta.profile_status?.includes('approved') && tutor.subjects?.length > 0
    );
  }

  if (searchFor) {
    switch (true) {
      case searchFor === 'mentor': {
        filteredResults = filteredResults.filter((t) => t.meta?.mentoring?.includes('yes'));
        break;
      }
      default: {
        // do nothing
      }
    }
  }

  if (subject) {
    filteredResults = filteredResults.filter((t) =>
      t.subjects.some((s) => String(s.name).includes(subject.name))
    );
  }

  if (level) {
    filteredResults = filteredResults.filter((t) =>
      t.subjects.some((s) => s.id === subject.id && s.levels.includes(level))
    );
  }

  if (inPerson) {
    filteredResults = filteredResults.filter((tutor) =>
      tutor.meta.tutoring_method.includes('In Person')
    );

    if (address) {
      filteredResults = filteredResults.filter((tutor) => {
        let tutorLocation = null;

        try {
          tutorLocation = JSON.parse(tutor.meta.tutor_locations[0]);
        } catch (error) {}

        return (
          tutorLocation &&
          getMiles(haversine(tutorLocation.location.position, address.position)) <= travelDistance
        );
      });
    }
  }

  if (sortBy) {
    switch (sortBy) {
      case 'price-asc': {
        filteredResults = filteredResults.sort(
          (t1, t2) => Number(t1.meta?.hours_rate[0] || 0) - Number(t2.meta?.hours_rate[0] || 0)
        );
        break;
      }

      case 'price-desc': {
        filteredResults = filteredResults.sort(
          (t1, t2) => Number(t2.meta?.hours_rate[0] || 0) - Number(t1.meta?.hours_rate[0] || 0)
        );
        break;
      }

      case 'rating': {
        filteredResults = filteredResults.sort(
          (t1, t2) =>
            (Math.round(
              t2.reviews.map((r) => r.rating).reduce((acc, curr) => acc + curr, 0) /
                t2.reviews.length
            ) || 0) -
            (Math.round(
              t1.reviews.map((r) => r.rating).reduce((acc, curr) => acc + curr, 0) /
                t1.reviews.length
            ) || 0)
        );
        break;
      }

      default: {
        // do nothing
      }
    }
  }

  if (priceRange) {
    filteredResults = filteredResults.filter(
      (t) =>
        Number(t.meta?.hours_rate[0] || 0) >= priceRange[0] &&
        Number(t.meta?.hours_rate[0] || 0) <= (priceRange[1] === 80 ? 999 : priceRange[1])
    );
  }

  if (availability && availability.length) {
    const morning = [8, 9, 10, 11];
    const afternoon = [12, 13, 14, 15, 16, 17];
    const evening = [18, 19, 20, 21, 22];

    filteredResults = filteredResults.map((t) => ({
      ...t,
      meta: {
        ...t.meta,
        tutor_availability: (t.meta.tutor_availability?.[0]
          ? JSON.parse(t.meta.tutor_availability[0])
          : []
        ).filter((a) => new Date(a.date).getTime() >= getTomorrow().getTime()),
      },
    }));

    filteredResults = filteredResults.filter(
      (t) =>
        (availability.includes('morning') &&
          t.meta.tutor_availability
            .map((a) => a.hours)
            .flat()
            .some((hour) => morning.includes(hour))) ||
        (availability.includes('afternoon') &&
          t.meta.tutor_availability
            .map((a) => a.hours)
            .flat()
            .some((hour) => afternoon.includes(hour))) ||
        (availability.includes('evening') &&
          t.meta.tutor_availability
            .map((a) => a.hours)
            .flat()
            .some((hour) => evening.includes(hour)))
    );
  }

  if (gender) {
    filteredResults = filteredResults.filter((t) =>
      ['M', 'F'].includes(gender) ? t.meta?.gender?.includes(gender) : true
    );
  }

  if (additionalFilters && additionalFilters.length) {
    if (additionalFilters.includes('QTS')) {
      filteredResults = filteredResults.filter((t) =>
        t.tutorFiles.some((file) => file.tag === 'QTS' && file.status === 'approved')
      );
    }

    if (additionalFilters.includes('DBS')) {
      filteredResults = filteredResults.filter((t) =>
        t.tutorFiles.some((file) => file.tag === 'DBS' && file.status === 'approved')
      );
    }

    if (additionalFilters.includes('SEND')) {
      filteredResults = filteredResults.filter((t) =>
        t.subjects?.some((s) => s.category === 'Special Educational Needs')
      );
    }
  }

  return filteredResults;
};
