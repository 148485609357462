import { storage } from '../../config/firebaseConfig';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

export const uploadFile = (path, file) =>
  uploadBytes(ref(storage, path), file).then(({ ref }) => getDownloadURL(ref));

export const deleteFile = (url) => deleteObject(ref(storage, url));

export const getFileName = (url) => ref(storage, url).name;

export const getPathFromUrl = (url) => ref(storage, url).fullPath;
