import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  hourlyRate: {
    '& .Mui-focused .MuiInputAdornment-positionStart': {
      color: theme.palette.primary.main,
    },
  },
  earningsTable: {
    width: '50%',
    border: `1px solid ${theme.palette.divider}`,
    borderCollapse: 'collapse',
    '& th,td': {
      border: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    '& td': {
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
