import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  slider: {
    '& .MuiSlider-rail, .MuiSlider-track': {
      height: theme.spacing(0.5),
    },
    '& .MuiSlider-thumb': {
      marginTop: theme.spacing(-0.5),
    },
  },
}));
