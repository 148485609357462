import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dataGrid: {
    '& .MuiDataGrid-columnsContainer': {
      borderRadius: theme.spacing(0.5, 0.5, 0, 0),
      backgroundColor: ({ tab }) => {
        const backgrounds = {
          0: theme.palette.primary.main,
          1: theme.palette.success.main,
          2: theme.palette.action.active,
        };

        return backgrounds[tab];
      },
      color: theme.palette.primary.contrastText,
      '& button': {
        color: theme.palette.primary.contrastText,
      },
      '& .MuiDataGrid-columnHeader': {
        '&.hidden': {
          display: 'none',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
          padding: 0,
        },
      },
    },
  },
  '& .MuiDataGrid-cell': {
    maxHeight: '100%',
  },
}));
