import { orderBy, query, where } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { chatsRef } from 'services/firebase/messages';
import { useAuth } from './Auth';

const { createContext, useContext } = require('react');

const CurrentUserChatsContext = createContext();
export const useCurrentUserChats = () => useContext(CurrentUserChatsContext);

export const CurrentUserChatsProvider = ({ children }) => {
  const { user } = useAuth();
  const [chats = [], loadingChats] = useCollectionData(
    user
      ? query(
          chatsRef,
          where('chatMembers', 'array-contains', user.id),
          orderBy('lastMessage.createdAt', 'desc')
        )
      : undefined,
    {
      idField: 'id',
    }
  );

  return (
    <CurrentUserChatsContext.Provider value={{ chats, loadingChats }}>
      {children}
    </CurrentUserChatsContext.Provider>
  );
};
