import { makeStyles } from '@material-ui/core';
import { theme } from 'themes';

export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: theme.palette.error.main,
    },
  },
};

export const useStyles = makeStyles((theme) => ({
  cardElement: {
    padding: theme.spacing(2, 0, 2, 1),
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      borderColor: theme.palette.grey[700],
    },
    '&.StripeElement--focus': {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
      margin: -1,
    },
    '&.StripeElement--invalid': {
      borderColor: theme.palette.error.main,
    },
  },
  successIcon: {
    color: theme.palette.success.main,
    fontSize: 100,
  },
  securePaymentsWrapper: {
    // backgroundColor: theme.palette.secondary.main,
  },
  securePayments: {
    width: '100%',
    maxWidth: 600,
  },
  datePickerWrapper: {
    lineHeight: 'unset',
    '& #container': {
      scrollBehavior: 'smooth',
      '& .disabled': {
        color: theme.palette.action.disabled,
      },
    },
    '& button': {
      marginBottom: theme.spacing(1.1),
      boxShadow: `0px 0px 0px 4px ${theme.palette.grey[200]}`,
      '&:hover': {
        boxShadow: `0px 0px 0px 4px ${theme.palette.grey[300]}`,
      },
      '&:active': {
        transform: 'scale(0.9)',
      },
    },
  },
  timeSlotContainer: {
    listStyle: 'none',
    textAlign: 'center',
    height: 200,
    overflowY: 'auto',
    marginBottom: theme.spacing(4),
  },
  timeSlot: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    display: 'grid',
    placeItems: 'center',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
      cursor: 'pointer',
    },
    '&.selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  bookedTimeSlot: {
    height: 56,
    marginBottom: theme.spacing(2),
    display: 'grid',
    placeItems: 'center',
    color: theme.palette.text.disabled,
  },
  notAvailable: {
    height: 56,
    display: 'grid',
    placeItems: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.h6.fontSize,
    },
  },
  content: {
    padding: theme.spacing(2),
    scrollBehavior: 'smooth',
  },
  invitedUser: {
    padding: theme.spacing(1, 0),
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));
