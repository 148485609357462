import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    // padding: theme.spacing(2),
  },
  categoriesContainer: {
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      overflowX: 'auto',
      overflowY: 'hidden',
      alignItems: 'flex-start',
    },
  },
  categoryItem: {
    textAlign: 'left',
    justifyContent: 'flex-start',
    borderRadius: 0,
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-of-type': {
      borderBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      height: 56,
      minWidth: 'max-content',
      borderRight: `1px solid ${theme.palette.divider}`,
      '&:last-of-type': {
        borderRight: 0,
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.spacing(0, 0.5, 0, 0),
      },
      '&:first-of-type': {
        borderRadius: theme.spacing(0.5, 0, 0, 0),
      },
    },
  },
  subjectsContainer: {
    padding: theme.spacing(2),
    overflowY: 'auto',
    maxHeight: 683,
  },
  levelItem: {
    margin: theme.spacing(0.5),
    '&:last-of-type': {
      marginRight: 0,
    },
  },
}));
