import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Icon,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { limitToLast, orderBy, query, where } from '@firebase/firestore';
import { createChat, createMessage, messagesRef, updateChat } from 'services/firebase/messages';
import { useAuth } from 'providers';
import { useStyles } from './style';
import { PHOTO_PLACEHOLDER, TOKEN } from 'enums';
import { debounce } from 'lodash';
import classNames from 'classnames';
import { getDateFromObject } from 'utils/date';

const defaultLimit = 25;

function ChatRoom({ chat, onClose }) {
  const classes = useStyles();
  const { user } = useAuth();
  const messageContainerRef = useRef(null);
  const [limit, setLimit] = useState(defaultLimit);
  const [messages = [], loading] = useCollectionData(
    chat?.id
      ? query(messagesRef, where('chatId', '==', chat.id), orderBy('createdAt'), limitToLast(limit))
      : undefined,
    {
      idField: 'id',
    }
  );

  useEffect(() => {
    if (chat?.id) {
      setLimit(defaultLimit);
      document.querySelector('[name=newMessage]')?.focus();
    }
  }, [chat?.id]);

  useEffect(() => {
    const handleScroll = debounce((e) => {
      if (e?.target?.scrollTop === 0) {
        setLimit((prevLimit) =>
          prevLimit > messages.length ? prevLimit : prevLimit + defaultLimit
        );
      }
    }, 300);

    let ul;

    if (!loading && messages.length) {
      ul = messageContainerRef.current;
      if (ul) {
        ul.addEventListener('scroll', handleScroll);
      }
    }

    return () => {
      ul?.removeEventListener('scroll', handleScroll);
    };
  }, [limit, loading, messages]);

  useEffect(() => {
    const updateSeenBy = async () => {
      const lastMessage = chat?.lastMessage;
      if (lastMessage && !lastMessage.seenBy.includes(user.id)) {
        await updateChat(chat.id, {
          lastMessage: { ...lastMessage, seenBy: [...lastMessage.seenBy, user.id] },
        });
      }
    };

    updateSeenBy();
  }, [chat, user.id]);

  const scrollBottom = useCallback(() => {
    messageContainerRef.current?.scrollTo({
      top: messageContainerRef.current?.scrollHeight,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    // initial render scroll bottom
    if (!loading && messages.length && limit === defaultLimit) {
      scrollBottom();
    }
  }, [scrollBottom, limit, loading, messages]);

  const handleMessageSubmit = async (e) => {
    e.preventDefault();
    const newMessageTextField = e.target.newMessage;
    newMessageTextField.focus();
    const chatId =
      messages[0]?.chatId ||
      (
        await createChat({
          chatMembers: chat.chatMembers.sort(),
          createdAt: new Date(),
          createdBy: user.id,
        })
      ).id; // create new chat

    const messageDetails = {
      chatId,
      text: newMessageTextField.value?.trim(),
      createdAt: new Date(),
      sentBy: {
        id: user.id,
        name: user.name,
        email: user.email,
        photoURL: user.meta.photo_url?.[0] || user.avatar_urls[48],
      },
    };

    newMessageTextField.value = '';
    const newMessage = await createMessage(messageDetails);

    if (limit > defaultLimit) {
      scrollBottom();
    }

    await updateChat(chatId, {
      lastMessage: {
        ...messageDetails,
        seenBy: [user.id],
        id: newMessage.id,
        accessToken: localStorage.getItem(TOKEN),
      },
    });
  };

  return (
    <Box p={2}>
      <Grid container direction='column' spacing={2}>
        <Grid item container alignItems='center'>
          <IconButton color='primary' onClick={onClose}>
            <Icon>keyboard_backspace</Icon>
          </IconButton>
          <img
            src={chat?.chatWith?.meta?.photo_url[0] || PHOTO_PLACEHOLDER}
            alt='Tutor'
            className={classes.chatHeaderPhoto}
          />
          <Box textAlign='center' ml={2}>
            <Typography variant='h6' style={{ textTransform: 'capitalize' }}>
              {chat?.chatWith?.name || 'Unavailable'}
            </Typography>
          </Box>
        </Grid>
        <Grid item style={{ height: '60vh', minHeight: 400 }}>
          <Divider />
          {loading ? (
            <Box style={{ display: 'grid', placeItems: 'center', height: '70vh' }}>
              <CircularProgress />
            </Box>
          ) : messages.length ? (
            <ul className={classes.messagesList} ref={messageContainerRef}>
              {messages.map((m, i) => {
                const sentAt = getDateFromObject(m.createdAt);
                const className = m.sentBy.id === user.id ? 'sent' : 'received';
                return (
                  <li key={m.id}>
                    {i === 0 && limit > messages.length && (
                      <Box pb={4} pt={3} textAlign='center'>
                        <Typography color='textSecondary'>
                          {sentAt.toLocaleDateString('en-GB')}
                        </Typography>
                      </Box>
                    )}
                    {i > 0 &&
                      sentAt.toLocaleDateString() !==
                        getDateFromObject(messages[i - 1].createdAt).toLocaleDateString() && (
                        <Box pb={4} pt={3} textAlign='center'>
                          <Typography color='textSecondary'>
                            {sentAt.toLocaleDateString('en-GB')}
                          </Typography>
                        </Box>
                      )}
                    <Box className={classNames(classes.message, className)}>
                      <Typography variant='body2' style={{ textTransform: 'capitalize' }}>
                        <strong>{className === 'received' ? chat?.chatWith?.name : 'You'}</strong>
                      </Typography>
                      <Typography variant='body2'>{m.text}</Typography>
                      <Typography
                        component='div'
                        variant='caption'
                        style={{ opacity: 0.4, width: '100%', textAlign: 'right' }}
                      >
                        {sentAt.toLocaleTimeString('en-GB', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </Typography>
                    </Box>
                  </li>
                );
              })}
            </ul>
          ) : (
            <Box
              style={{
                display: 'grid',
                placeItems: 'center',
                height: '100%',
              }}
            >
              <Typography color='textSecondary' variant='h6'>
                This is your first conversation with{' '}
                <span style={{ textTransform: 'capitalize' }}>{chat?.chatWith?.name || '...'}</span>
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item>
          <form
            onSubmit={
              chat?.chatWith
                ? handleMessageSubmit
                : (e) => {
                    e.preventDefault();
                  }
            }
          >
            <Grid container alignItems='center'>
              <Grid item xs>
                <TextField
                  autoFocus
                  placeholder='Enter your message...'
                  required
                  variant='outlined'
                  fullWidth
                  name='newMessage'
                  multiline
                  maxRows={3}
                  disabled={!chat?.chatWith}
                />
              </Grid>
              <Grid item>
                <Tooltip title='Send message' arrow>
                  <IconButton
                    className={classes.sendButton}
                    type='submit'
                    id='submit-message'
                    disabled={!chat?.chatWith}
                  >
                    <Icon>send</Icon>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
}

export default React.memo(ChatRoom);
