import axios from 'axios';
import { TOKEN, WP_API_URL, WP_SITE_URL } from '../../enums';

/**
 *
 * @TODO - check per_page limit and totalPages
 */
export const getWPUsers = (queryParams = '') =>
  axios
    .get(WP_SITE_URL + '/wp-json/wp/v2/users?' + queryParams, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
      },
    })
    .then((res) => res.data);

export const getWPUserById = (id) =>
  axios
    .get(`${WP_SITE_URL}/wp-json/wp/v2/users/${id}?context=edit`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
      },
    })
    .then((res) => res.data);

export const getWPUserByEmail = (email) =>
  axios
    .get(`${WP_SITE_URL}/wp-json/wp/v2/users?search=${email}&context=edit`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
      },
    })
    .then((res) => res.data);

export const getWPCurrentUser = () =>
  axios
    .get(`${WP_SITE_URL}/wp-json/wp/v2/users/me?context=edit`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
      },
    })
    .then((res) => res.data);

export const wpLogin = (username, password) =>
  axios
    .post(`${WP_SITE_URL}/wp-json/jwt-auth/v1/token`, { username, password })
    .then((res) => {
      const token = res.data.token;
      localStorage.setItem(TOKEN, token);
    })
    .then(() => getWPCurrentUser());

export const updateWPUser = (userId, userData) =>
  axios.post(
    `${WP_API_URL}/users/${userId}`,
    { ...userData },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
      },
    }
  );

export const wpRegisterAsStudent = (username, email, first_name, last_name, password) =>
  axios
    .post(`${WP_SITE_URL}/wp-json/wp/v2/users/register`, {
      username,
      email,
      first_name,
      last_name,
      password,
    })
    .then((res) => res.data);

export const deleteWPUser = (userId) =>
  axios.delete(`${WP_API_URL}/users/${userId}?reassign=false&force=true`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
    },
  });
