import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    top: 0,
    width: '100%',
    padding: theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    zIndex: 2,
  },
  content: {
    '& > a': {
      lineHeight: 0,
    },
    '& > a > img': {
      maxWidth: 130,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 100,
      },
    },
  },
  menuIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.light,
  },
}));
