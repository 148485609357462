import { isDate } from 'lodash';

export const getDateFromObject = (dateObj) => dateObj?.toDate();
export const prettifyDateTime = (date) =>
  isDate(date)
    ? date.toLocaleString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })
    : '-';
