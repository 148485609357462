import { Icon, IconButton, Tooltip } from '@material-ui/core';
import { prettifyDateTime } from 'utils/date';

export const getStudentColumns = (loadingRowId, handleDeleteUser) => [
  {
    field: 'id',
    headerName: 'UID',
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: true,
    minWidth: 200,
  },
  {
    field: 'email',
    headerName: 'Email',
    renderCell: ({ value }) => <a href={`mailto:${value}`}>{value}</a>,
    flex: true,
    minWidth: 300,
  },
  {
    field: 'registered_date',
    headerName: 'Registered Date',
    type: 'dateTime',
    valueGetter: ({ value }) => new Date(value),
    renderCell: ({ value }) => prettifyDateTime(new Date(value)),
    flex: true,
    minWidth: 200,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    flex: true,
    minWidth: 150,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <Tooltip title={`Delete ${row.name}`}>
        <IconButton
          disabled={row.id === loadingRowId}
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteUser(row);
          }}
        >
          <Icon>delete_icon</Icon>
        </IconButton>
      </Tooltip>
    ),
  },
];
