import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import GridLoader from 'react-spinners/GridLoader';
import { getWPCurrentUser } from '../services/wp/users';
import { TOKEN, UUID4 } from 'enums';
import { doc } from '@firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { tutorSubjectsRef } from 'services/firebase/tutorSubjects';
import { getUserRole } from 'utils/user';

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const userRole = useMemo(() => getUserRole(user), [user]);
  const [{ subjects = [] } = {}] = useDocumentData(
    doc(tutorSubjectsRef, user && userRole === 'tutor' ? String(user.id) : UUID4)
  );

  useEffect(() => {
    if (!localStorage.getItem(TOKEN)) {
      return setLoading(false);
    }

    getWPCurrentUser()
      .then((user) => {
        setUser(user);
      })
      .catch((err) => {
        // console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem(TOKEN);
    setUser(null);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: user ? { ...user, subjects } : user,
        loading,
        setUser,
        logout,
      }}
    >
      {loading ? (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            display: 'grid',
            alignItems: 'center',
            justifyItems: 'center',
          }}
        >
          <GridLoader color='#0015bf' size={50} />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}
