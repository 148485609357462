import React, { useCallback, useMemo, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  Button,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useAuth, useConfirmation } from 'providers';
import { updateBooking } from 'services/firebase/bookings';
import { prettifyDateTime } from 'utils/date';
import { routes } from 'routes';
import history from 'services/history';
import StudentsCell from 'components/StudentsCell';

const getColumns = (userRole, onBookingCancel, cancelBookingId) => [
  {
    field: 'dateTime',
    headerName: 'Date & Time',
    renderHeader: () => (
      <Grid container alignItems='center' wrap='nowrap'>
        <Icon>{'event_icon'}</Icon> &nbsp; Date & Time
      </Grid>
    ),

    width: 190,
    type: 'date',
    renderCell: ({ value }) => prettifyDateTime(value),
  },
  {
    field: 'duration',
    headerName: 'Duration',
    renderHeader: () => (
      <Grid container alignItems='center' wrap='nowrap'>
        <Icon>{'timelapse_icon'}</Icon>&nbsp; Duration
      </Grid>
    ),
    width: 165,
    valueGetter: ({ value }) => `${value} hour${value > 1 ? 's' : ''}`,
  },
  {
    field: 'subject',
    headerName: 'Subject',
    renderHeader: () => (
      <Grid container alignItems='center' wrap='nowrap'>
        <Icon>{'subject_icon'}</Icon>&nbsp; Subject
      </Grid>
    ),
    width: 220,
    renderCell: ({ row }) => (
      <Grid container direction='column'>
        <span style={{ lineHeight: 1.3, fontWeight: 'bold' }}>{row.subject}</span>
        <Typography variant='caption' style={{ lineHeight: 1.3 }}>
          {row.level || '-'}
        </Typography>
      </Grid>
    ),
  },
  {
    field: 'inPerson',
    headerName: 'In Person',
    renderHeader: () => (
      <Grid container alignItems='center' wrap='nowrap'>
        <Icon>{'directions_walk_icon'}</Icon>&nbsp; In Person
      </Grid>
    ),
    width: 170,
    renderCell: ({ value }) => (value ? 'Yes' : 'No'),
  },
  {
    field: 'groupClass',
    headerName: 'Lesson Type',
    renderHeader: () => (
      <Grid container alignItems='center' wrap='nowrap'>
        <Icon>{'groups_icon'}</Icon>&nbsp;
        <span style={{ textTransform: 'capitalize' }}>&nbsp; Lesson Type</span>
      </Grid>
    ),
    renderCell: ({ value }) => (
      <Tooltip title={value ? 'Group Class' : 'One to One'}>
        <Icon color='action'>{value ? 'groups_icon' : 'person_icon'}</Icon>
      </Tooltip>
    ),
    width: 160,
  },
  {
    field: userRole === 'student' ? 'tutor' : 'student',
    headerName: userRole === 'student' ? 'Tutor' : 'Student(s)',
    renderHeader: () => (
      <Grid container alignItems='center' wrap='nowrap'>
        <Icon>{'person_icon'}</Icon>&nbsp;
        <span>{userRole === 'student' ? 'Tutor' : 'Student(s)'}</span>
      </Grid>
    ),
    width: 320,
    valueGetter: ({ value }) => value?.name,
    renderCell: ({ row, value }) =>
      row.groupClass && userRole === 'tutor' ? (
        <StudentsCell students={[row.student, ...row.invitedUsers]} />
      ) : (
        <Grid container style={{ paddingLeft: 14 }}>
          <Grid item xs container direction='column' justifyContent='center'>
            <span
              style={{
                lineHeight: 1.3,
                textTransform: 'capitalize',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 220,
              }}
            >
              <strong>{value}</strong>
            </span>
            <Typography
              variant='caption'
              style={{
                lineHeight: 1.3,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 220,
              }}
            >
              {row[userRole === 'student' ? 'tutor' : 'student']?.email}
            </Typography>
          </Grid>

          <Grid item>
            <Tooltip
              arrow
              title={
                <>
                  Send a message to{' '}
                  <span style={{ textTransform: 'capitalize' }}>
                    {row[userRole === 'student' ? 'tutor' : 'student']?.name?.split(' ')[0]}
                  </span>
                </>
              }
            >
              <IconButton
                disabled={cancelBookingId === row.id}
                onClick={() =>
                  history.push(routes.messages, {
                    chatWith: row[userRole === 'student' ? 'tutor' : 'student']?.id,
                  })
                }
              >
                <Icon>message</Icon>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ),
  },
  {
    field: 'cancel',
    headerName: ' ',
    headerClassName: 'hidden',
    sortable: false,
    disableColumnMenu: true,
    width: 150,
    renderCell: ({ row }) => {
      const button = (
        <Button
          variant='contained'
          disabled={cancelBookingId === row.id || row.notAllowedToCancel}
          onClick={(e) => {
            onBookingCancel(row);
          }}
          startIcon={<Icon color='error'>close</Icon>}
        >
          Cancel
          {cancelBookingId === row.id && (
            <CircularProgress size={24} style={{ position: 'absolute' }} />
          )}
        </Button>
      );

      return row.notAllowedToCancel ? (
        <Tooltip title='You are not allowed to cancel this booking'>
          <div style={{ lineHeight: 0 }}>{button}</div>
        </Tooltip>
      ) : (
        button
      );
    },
  },
];

const initialSortModel = {
  field: 'dateTime',
  sort: 'asc',
};

function Upcoming({ rows, loading, className, userRole, onChange, onRowClick }) {
  const { user } = useAuth();
  const [pageSize, setPageSize] = useState(5);
  const [sortModel, setSortModel] = useState([initialSortModel]);
  const [cancelBookingId, setCancelBookingId] = useState(null);
  const confirm = useConfirmation();
  const mappedRows = useMemo(
    () =>
      rows.map((r) => ({
        ...r,
        notAllowedToCancel: ![r.student.id, r.tutor.id].includes(user.id),
      })),
    [rows, user.id]
  );

  const handleBookingCancellation = useCallback(
    async (booking) => {
      if (booking.notAllowedToCancel) {
        return;
      }

      const confirmed = await confirm('This booking will be cancelled.');
      if (confirmed) {
        setCancelBookingId(booking.id);

        try {
          const cancelData = { cancelled: true, cancelledBy: userRole, cancelDate: new Date() };
          await updateBooking(booking.id, cancelData);
          onChange((bookings) =>
            bookings.map((b) => (b.id === booking.id ? { ...b, ...cancelData } : b))
          );
        } catch (error) {
          console.error('Cannot cancel event: ', error.message);
        }

        setCancelBookingId(null);
      }
    },
    [confirm, onChange, userRole]
  );

  const columns = useMemo(
    () => getColumns(userRole, handleBookingCancellation, cancelBookingId),
    [cancelBookingId, handleBookingCancellation, userRole]
  );

  return (
    <DataGrid
      className={className}
      rows={mappedRows}
      columns={columns}
      loading={loading}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      rowsPerPageOptions={[5, 10, 20]}
      autoHeight
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      onRowClick={onRowClick}
    />
  );
}

export default Upcoming;
