import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import history from './services/history';
import { theme } from './themes';
import { Router } from 'react-router';
import {
  AuthProvider,
  ConfirmationProvider,
  TutorsProvider,
  ReviewsProvider,
  CategoriesProvider,
  StudentsProvider,
  QualificationsProvider,
  FiltersProvider,
} from './providers';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { responsiveFontSizes } from '@material-ui/core';
import { SubjectsProvider } from 'providers/Subjects';
import { CurrentUserChatsProvider } from 'providers/useCurrentUserChats';

const stripePromise = loadStripe(
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_STRIPE_PK_TEST
    : process.env.REACT_APP_STRIPE_PK
).catch((error) => console.log(error));

const themeWithResponsiveFontSize = responsiveFontSizes(theme);

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <TutorsProvider>
        <StudentsProvider>
          <CategoriesProvider>
            <SubjectsProvider>
              <QualificationsProvider>
                <ReviewsProvider>
                  <AuthProvider>
                    <ThemeProvider theme={themeWithResponsiveFontSize}>
                      <Elements stripe={stripePromise}>
                        <ConfirmationProvider>
                          <FiltersProvider>
                            <CurrentUserChatsProvider>
                              <App />
                            </CurrentUserChatsProvider>
                          </FiltersProvider>
                        </ConfirmationProvider>
                      </Elements>
                    </ThemeProvider>
                  </AuthProvider>
                </ReviewsProvider>
              </QualificationsProvider>
            </SubjectsProvider>
          </CategoriesProvider>
        </StudentsProvider>
      </TutorsProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
