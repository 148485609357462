import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { theme } from 'themes';
import { useStyles } from './style';
import { useAuth } from 'providers/Auth';
import { useCurrentUserChats } from 'providers/useCurrentUserChats';
import { getWPUserById } from 'services/wp/users';
import { useLocation } from 'react-router';

import ChatRoom from './ChatRoom';
import ChatList from './ChatList';

function Messages() {
  const classes = useStyles();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const { state: { chatWith } = {} } = useLocation();

  const { user } = useAuth();
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);

  const { chats, loadingChats } = useCurrentUserChats();
  const [activeChat, setActiveChat] = useState(null);
  const [activeChatLoading, setActiveChatLoading] = useState(false);
  const [activeChatLoaded, setActiveChatLoaded] = useState(false);
  const chatsWithUsers = useMemo(
    () => chats.map((c) => ({ ...c, chatWith: users.find((u) => c.chatMembers.includes(u.id)) })),
    [chats, users]
  );

  useEffect(() => {
    if (activeChat?.lastMessage && chats.length) {
      const updatedChat = chats.find((c) => activeChat.id === c.id);
      if (updatedChat && activeChat?.lastMessage.id !== updatedChat.lastMessage.id) {
        setActiveChat((prevState) => ({ ...prevState, lastMessage: updatedChat.lastMessage }));
      }
    }
  }, [activeChat, chats]);

  useEffect(() => {
    const loadUsers = async () => {
      const promises = chats
        .map((c) => c.chatMembers)
        .flat()
        .filter((id) => id !== user.id)
        .map((id) => getWPUserById(id).catch((err) => err));

      const newUsers = await Promise.all(promises);
      setUsers(newUsers.filter((u) => !(u instanceof Error)));
      setLoadingUsers(false);
    };

    if (chats?.length && chats.length !== users.length) {
      loadUsers();
    } else {
      if (!loadingChats) {
        setLoadingUsers(false);
      }
    }
  }, [chats, loadingChats, user?.id, users.length]);

  useEffect(() => {
    const loadChatWith = async () => {
      const chat = chatsWithUsers.find((c) => c.chatMembers.includes(chatWith)) || {
        chatMembers: [user.id, chatWith],
      };

      if (!chat.chatWith) {
        setActiveChatLoading(true);
        const user = await getWPUserById(chatWith);
        chat.chatWith = user;
        setUsers((prevUsers) => [...prevUsers, user]);
      }

      setActiveChat(chat);
      setActiveChatLoading(false);
      setActiveChatLoaded(true);
    };

    if (chatWith && !loadingChats && !loadingUsers && !activeChatLoaded) {
      loadChatWith();
    }
  }, [activeChatLoaded, chatWith, chatsWithUsers, loadingUsers, user?.id, loadingChats]);

  useEffect(() => {
    if (chatWith && activeChat && !activeChat.id && chats.length) {
      const chat = chats.find((c) => c.chatMembers.includes(chatWith));
      if (chat) {
        setActiveChat((prevChat) => ({ ...prevChat, ...chat }));
      }
    }
  }, [activeChat, chatWith, chats]);

  const handleCloseChatRoom = useCallback(() => setActiveChat(null), []);

  return (
    <Box pb={10} pt={18} bgcolor={theme.palette.grey[200]} minHeight={'100vh'}>
      <Container>
        <Box className={classes.chatWrapper}>
          {loadingChats || loadingUsers || activeChatLoading ? (
            <Box style={{ height: '70vh', display: 'grid', placeItems: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Grid container>
              {(!xs || (xs && !activeChat)) && (
                <Grid item xs={12} sm={6} md={4}>
                  <Box p={2} height='70vh' minHeight={500}>
                    {chatsWithUsers.length ? (
                      <ChatList
                        chats={chatsWithUsers}
                        onChatSelect={setActiveChat}
                        activeChatId={activeChat?.id}
                      />
                    ) : (
                      <Box style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
                        <Typography>You have no conversations...</Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
              )}
              {!xs && (
                <Grid item>
                  <Box height='100%' py={2}>
                    <Divider orientation='vertical' />
                  </Box>
                </Grid>
              )}
              {(!xs || (xs && activeChat)) && (
                <Grid item xs>
                  {activeChat ? (
                    <ChatRoom chat={activeChat} onClose={handleCloseChatRoom} />
                  ) : (
                    <Box minHeight='70vh' style={{ display: 'grid', placeItems: 'center' }}>
                      <Typography>Please select a conversation</Typography>
                    </Box>
                  )}
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      </Container>
    </Box>
  );
}

export default Messages;
